import React from 'react';
import { useSelector } from 'react-redux';
import { errorScenarioSelector } from '../../../preapproval/redux/simulator/special-scenario/selectors';
import { NumbersSummary } from '../../components/simulator/numbers-summary';
import { inputsSelector } from '../../redux/simulator/inputs/selectors';
import { monthlyPaymentValueSelector } from '../../redux/simulator/monthly-payment/selectors';

export const ConnectedNumbersSummary = () => {
  const monthlyPayment = useSelector(monthlyPaymentValueSelector);
  const { homePrice } = useSelector(inputsSelector);
  const error = useSelector(errorScenarioSelector);

  return <NumbersSummary homePrice={homePrice} monthlyPayment={monthlyPayment} error={error} />;
};
