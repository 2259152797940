import React, { Fragment } from 'react';
import { AddressForm, AddressFormProps } from './address-form';
import { Header } from './header';

const offerAddressBody =
  'Including the property address in your preapproval letter will make it more likely for your offer to be accepted.';
const offerAddressHeader = 'What is the property address?';

export const OfferIntentForm = ({
  address,
  stateOptions,
  countyOptions,
  addressChangeDispatcher,
  addressFuzzyMatchCounty,
  stateError
}: AddressFormProps) => (
  <Fragment>
    <Header header={offerAddressHeader} body={offerAddressBody} includeTopPadding={false} />
    <AddressForm
      {...{
        address,
        stateOptions,
        countyOptions,
        addressChangeDispatcher,
        addressFuzzyMatchCounty,
        stateError
      }}
    />
  </Fragment>
);
