import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpThemeWrapper
} from '@rategravity/own-up-component-library-legacy';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { googleMapsApiKey } from '../modules/api-keys';
import { colors } from '../modules/colors';
import { PreapprovalAppWrapper } from './app';
import { store } from './redux';
import { history } from './redux/reducer';

// apply background color to app
const AppWrapper = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    width: '100vw',
    backgroundColor: colors.FLORAL_WHITE
  })
);

if (window.document && window.document.createElement) {
  const script = window.document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`;
  script.type = 'text/javascript';
  window.document.head.appendChild(script);
}

render(
  <div>
    <Provider store={store}>
      <OwnUpThemeWrapper className="content">
        <ConnectedRouter history={history}>
          <AppWrapper>
            <PreapprovalAppWrapper />
          </AppWrapper>
        </ConnectedRouter>
      </OwnUpThemeWrapper>
    </Provider>
  </div>,
  document.getElementById('root')
);
