import cookies from 'js-cookie';

export const getId = (key: string) => () => cookies.get(key);
export const getUser = (key: string) => () => {
  const cookie = cookies.get(key);
  return cookie ? JSON.parse(cookie) : undefined;
};
export const setId = (key: string, local?: boolean) => (value: string) =>
  local
    ? cookies.set(key, value)
    : cookies.set(key, value, { secure: true, expires: 365, domain: '.ownup.com' });
export const setUser = (key: string) => (accountId: string, properties: Record<string, unknown>) =>
  cookies.set(key, JSON.stringify({ accountId, properties }));
export const removeCookie = (key: string, local?: boolean) => () =>
  local ? cookies.remove(key) : cookies.remove(key, { domain: '.ownup.com' });
