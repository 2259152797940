import * as emailValidator from '@rategravity/core-lib/validate-email';
import {
  OwnUpBody,
  OwnUpFillButtonSecondary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpSmallSubheadlineMedium,
  OwnUpTextInput,
  OwnUpThemeProvider,
  ownUpWhiteTheme
} from '@rategravity/own-up-component-library';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { statusSelector } from '../../redux/selectors';
import { share } from '../../redux/share';
import { ErrorNotice, LoadingNotice, SuccessNotice } from './share-status';

const PaddedWrapper = styled('div')`
  margin-top: 64px;
  border-radius: 8px;
  background-color: white;
  padding: 32px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 80px;
    padding: 40px;
  }
`;

const StyledBody = styled(OwnUpBody)`
  margin-top: 16px;
  margin-bottom: 24px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-bottom: 32px;
  }
`;

const ComponentWrapper = styled.div`
  margin-top: 32px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`;

export const SharePreapproval = () => {
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showNameError, setShowNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const nameError = useMemo(() => !name, [name]);
  const emailError = useMemo(() => !emailValidator.validate(email.trim()), [email]);

  const handleOnClick = useCallback(() => {
    const onShare = (n: string, e: string) => dispatch(share({ name: n, email: e }));
    setShowNameError(true);
    setShowEmailError(true);
    if (!nameError && !emailError) {
      onShare(name, email);
    }
  }, [name, nameError, emailError, email, setShowNameError, setShowEmailError, dispatch]);

  const handleBlur = useCallback(
    (field: 'name' | 'email') => {
      if (field === 'name') {
        setShowNameError(true);
      }
      if (field === 'email') {
        setShowEmailError(true);
      }
    },
    [setShowNameError, setShowEmailError]
  );

  const setNotification = (s: string) => {
    if (s === 'loading') {
      return <LoadingNotice />;
    } else if (s === 'shared') {
      return <SuccessNotice />;
    } else {
      return <ErrorNotice />;
    }
  };

  return (
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <PaddedWrapper>
        <OwnUpGridContainer variant="slim">
          <OwnUpGridItem xs={12}>
            <OwnUpSmallSubheadlineMedium variant="h1">
              Share with your realtor
            </OwnUpSmallSubheadlineMedium>
          </OwnUpGridItem>
          <OwnUpGridItem xs={12} sm={10}>
            <StyledBody variant="body1">
              We’ll email your preapproval letter to your realtor, tell them a bit about Own Up, and
              answer any questions they may have.
            </StyledBody>
          </OwnUpGridItem>
          <OwnUpGridItem lg={6} md={10} xs={12}>
            <OwnUpTextInput
              label="Realtor name"
              labelPosition="inner"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
              onBlur={() => handleBlur('name')}
              error={showNameError && nameError}
              helperText={showNameError && nameError ? 'Please enter a valid name' : ''}
            />
          </OwnUpGridItem>
          <OwnUpGridOffset lg={6} />
          <OwnUpGridItem lg={6} md={10} xs={12}>
            <OwnUpTextInput
              label="Realtor email address"
              labelPosition="inner"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              onBlur={() => handleBlur('email')}
              error={showEmailError && emailError}
              helperText={showEmailError && emailError ? 'Please enter a valid email address' : ''}
              style={{ marginTop: '12px' }}
            />
          </OwnUpGridItem>
          <OwnUpGridOffset lg={6} />
          <OwnUpGridItem xs={12}>
            <ComponentWrapper>
              <OwnUpFillButtonSecondary
                onClick={handleOnClick}
                disabled={status === 'loading'}
                disabledReason="Sharing preapproval, please wait."
              >
                Share
              </OwnUpFillButtonSecondary>
            </ComponentWrapper>
          </OwnUpGridItem>
          {(status === 'shared' || status === 'error' || status === 'loading') && (
            <OwnUpGridItem xs={12}>
              <ComponentWrapper>{setNotification(status)}</ComponentWrapper>
            </OwnUpGridItem>
          )}
        </OwnUpGridContainer>
      </PaddedWrapper>
    </OwnUpThemeProvider>
  );
};
