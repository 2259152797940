import * as Sentry from '@sentry/react';

/**
 * Send an error directly to sentry.
 *
 * @param error - Error payload to send
 */
export const sendToSentry = (error: unknown) => {
  let errorObj: Error;
  if (error instanceof Error) {
    errorObj = error;
  } else if (typeof error === 'string') {
    errorObj = new Error(error);
  } else {
    // Try to parse it as json
    try {
      errorObj = new Error(JSON.stringify(error));
    } catch (err) {
      errorObj = new Error(error as string);
    }
  }
  if (process.env.SENTRY_DSN) {
    Sentry.captureException(errorObj);
  } else {
    console.log('Sentry not configured');
  }
};
