import { formatCurrency } from '@rategravity/core-lib/rates';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { colors } from '../../../../modules/colors';
import { MonthlyPayment } from '../types';
import { Legend } from './legend';
import {
  CrosshatchPattern,
  DotPattern,
  HorizontalStripePattern,
  VerticalStripePattern
} from './patterns';
import { CellDetails, CellDetailsIndex } from './types';

export const PieChartContainer = createOwnUpComponent(
  'section',
  ({ isMobile }: { isMobile?: boolean }) =>
    createOwnUpStyle({
      height: '140px',
      width: '300px',
      margin: '40px 0',
      ...(isMobile
        ? {
            height: '100px'
          }
        : {})
    })
);

export const PreapprovalPieChart = (data: MonthlyPayment) => {
  const isMobile = useIsMobile(1024);

  data = { ...data, monthlyPAndI: data.monthlyPAndI || 0 };

  const cellDetails: CellDetailsIndex = {
    monthlyPAndI: {
      name: 'Principal & interest',
      fill: colors.CHART_GREEN,
      tooltip:
        'Together principal & interest, or P&I, are the majority of your monthly payment. The principal is the amount you borrowed and have to pay back, and interest is what the lender charges for lending you the money.'
    },
    monthlyHomeownersInsurance: {
      name: 'Monthly homeowners insurance',
      fill: 'url("#horizontal-stripe")',
      tooltip:
        'This is the premium for insurance to cover damage to your property and the things you keep in it. The cost depends on the location of your home and the value of the home and the things in it. Usually, an escrow account is used to help set this money aside on a monthly basis.'
    },
    monthlyPropertyTax: {
      name: 'Property taxes',
      fill: 'url("#dots")',
      tooltip:
        "This is the tax you are required to pay by your city or municipality, based on a percentage of the home's value. Usually, an escrow account is used to help set this money aside on a monthly basis."
    },
    monthlyFee: {
      name: 'Monthly HOA/condo fees',
      fill: 'url("#vertical-stripe")',
      tooltip:
        "These are dues paid to a group like a homeowner's association or condo board. Not applicable to all borrowers."
    },
    monthlyPMI: {
      name: 'PMI',
      fill: 'url("#crosshatch")',
      tooltip:
        'Private mortgage insurance (PMI) is usually required by the lender when you make a down payment of less than 20 percent of the home’s purchase price. This cost may no longer apply once you’ve accumulated over 20% equity in your home.'
    }
  };

  // Ensure that pie chart pieces appear in the order they are
  // listed in the object above
  const sortByCellDetailsIndex = (
    a: [keyof MonthlyPayment, number | undefined],
    b: [keyof MonthlyPayment, number | undefined]
  ) => {
    const keys = Object.keys(cellDetails);
    return keys.indexOf(a[0]) <= keys.indexOf(b[0]) ? 0 : 1;
  };

  // Sum the given values for a total cost to present to the user, and define a pie chart
  // section for each by combining the value with corresponding preapprovalPieChartSections
  // properties.
  const [totalCost, chartData] = (
    Object.entries(data) as [keyof MonthlyPayment, number | undefined][]
  )
    .sort(sortByCellDetailsIndex)
    .reduce(
      ([cost, d]: [number, CellDetails[]], [k, v]) => [
        cost + (v || 0),
        [
          ...d,
          {
            ...cellDetails[k as keyof MonthlyPayment],
            value: v
          }
        ]
      ],
      [0, []]
    );

  const smallText = {
    fontSize: '14px',
    fontFamily: 'Graphik-Regular',
    ...(isMobile ? { x: 120, y: 38 } : { x: 166, y: 56 })
  };
  const largeText = {
    fontFamily: 'Graphik-Medium',
    ...(isMobile ? { x: 120, y: 70 } : { x: 166, y: 96 }),
    ...(isMobile ? { fontSize: '24px' } : { fontSize: '36px' })
  };
  const chartRadius = isMobile ? 44 : 65;

  return (
    <React.Fragment>
      <PieChartContainer
        isMobile={isMobile}
        aria-label="Pie chart showing breakdown of monthly payment for this scenario"
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <defs>
              <CrosshatchPattern />,
              <DotPattern />,
              <HorizontalStripePattern />,
              <VerticalStripePattern />
            </defs>
            <text {...smallText}>Monthly payment</text>
            <text {...largeText}>{totalCost ? formatCurrency(totalCost) : '-'}</text>

            {/* The <circle> below provides a default "empty" pie chart, since recharts renders
              nothing when the chart data values are all 0. When values are present, the 
              actual pie chart will render on top of the circle.

              For some reason, the circle and pie chart render 5px offset from one another, 
              even with the same cx/cy values. Mentioning here in case it's a bug in recharts
              that gets fixed and our offset becomes broken.         */}
            <circle cx={chartRadius + 5} cy={chartRadius + 5} r={chartRadius} fill={colors.GREY} />
            <Pie
              cx={chartRadius}
              cy={chartRadius}
              data={chartData}
              outerRadius={chartRadius}
              dataKey="value"
            />
          </PieChart>
        </ResponsiveContainer>
      </PieChartContainer>
      <Legend cellDetails={cellDetails} data={data} />
    </React.Fragment>
  );
};
