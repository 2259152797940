import { event } from '@rategravity/frontend/modules/user-actions';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActionPipe } from '../../../hooks/use-action-pipe';
import { AffordabilityRange } from '../../../preapproval/components/simulator/drawer/components';
import { MediumGreenText } from '../../../preapproval/components/simulator/typography';
import { AffordabilityDetails } from '../../components/simulator/affordability-detail';
import { getAffordabilityDetails } from '../../modules/simulator/get-affordability-details';
import { inputsSelector } from '../../redux/simulator/inputs/selectors';
import { monthlyPaymentValueSelector } from '../../redux/simulator/monthly-payment/selectors';
import { sliderConstraintsSelector } from '../../redux/simulator/slider-constraints/selectors';
import { specialScenarioSelector } from '../../redux/simulator/special-scenario/selectors';

export const ConnectedAffordabilityDetails = ({
  isDrawerCollapsed
}: {
  isDrawerCollapsed: boolean;
}) => {
  const {
    sliderMin,
    sliderValue,
    sliderGradient: { maxAffordable, maxStretch }
  } = useSelector(sliderConstraintsSelector);
  const inputs = useSelector(inputsSelector);
  const { homePrice, monthlyDebt, annualIncome, downPayment } = inputs;
  const specialScenario = useSelector(specialScenarioSelector);

  const monthlyPaymentValue = useSelector(monthlyPaymentValueSelector);

  const dti = Number(
    (((monthlyPaymentValue + monthlyDebt) / (annualIncome / 12)) * 100).toPrecision(2)
  );

  const downPaymentPercent = Math.round((downPayment / homePrice) * 100);

  const { scenario, color, details } = getAffordabilityDetails({
    specialScenario,
    sliderMin,
    sliderValue,
    maxAffordable,
    maxStretch,
    dti,
    downPaymentPercent
  });

  const dispatchAction = useActionPipe();
  const dispatchMixpanelEvent = (error: string) => {
    dispatchAction(
      event('resultsError-PAS', {
        error,
        inputs
      })
    );
  };

  useEffect(() => {
    let error = '';
    if (specialScenario === 0) {
      error = 'resultsError-lowCredit-PAS';
    } else if (specialScenario === 1) {
      error = 'resultsError-smallLoanSize-PAS';
    } else if (specialScenario === 2) {
      error = 'resultsError-highDTI-PAS';
    } else if (specialScenario === 3) {
      error = 'resultsError-proofOfIncome-PAS';
    } else if (specialScenario === 4) {
      error = 'resultsError-lowDownPayment-PAS';
    }

    if (error) {
      dispatchMixpanelEvent(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialScenario]);

  if (isDrawerCollapsed && scenario != 'Error') {
    return (
      <AffordabilityRange color={color}>
        <MediumGreenText>{scenario}</MediumGreenText>
      </AffordabilityRange>
    );
  }

  return <AffordabilityDetails details={details} scenario={scenario} color={color} />;
};
