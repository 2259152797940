export * from './create-tracking-instance';
export * from './use-1pt';

// Expose the underlying utility functions so they can
//   be used to create tracking instances that are
//   partially customized.
import * as cookieUtils from './utils/cookies';
import * as fetchUtils from './utils/fetch';
import * as pageDataUtils from './utils/get-page-data';

export const utils = { cookies: cookieUtils, fetch: fetchUtils, page: pageDataUtils };
