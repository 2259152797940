import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpIcon,
  PRIMARY
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { Link } from 'react-router-dom';
import { useIsMobile } from '../../hooks/is-mobile';
import { MediumGreenText } from '../../preapproval/components/simulator/typography';

const PointerIcon = createOwnUpComponent(
  OwnUpIcon,
  ({ includeBackText }: { includeBackText: boolean }) =>
    createOwnUpStyle({
      ...(includeBackText ? {} : { height: '20px' }),
      width: '20px',
      cursor: 'pointer'
    })
);

const AccountHomeLinkWrapper = createOwnUpComponent(
  'div',
  ({ isMobile, includeBackText }: { isMobile: boolean; includeBackText: boolean }) =>
    createOwnUpStyle({
      position: 'relative',
      right: '40px',
      top: '25px',
      ...(isMobile ? { position: 'unset' } : {}),
      ...(includeBackText ? { paddingBottom: '16px' } : {})
    })
);

export const AccountHomeLink = ({
  accountId,
  isMobile: explicitIsMobile,
  color = PRIMARY,
  backText = true
}: {
  accountId: string;
  isMobile?: boolean | undefined;
  color?: string;
  backText?: boolean;
}) => {
  const defaultIsMobile = useIsMobile();
  const isMobile = explicitIsMobile !== undefined ? explicitIsMobile : defaultIsMobile;
  const includeBackText = isMobile && backText;
  return (
    <AccountHomeLinkWrapper {...{ isMobile, includeBackText }}>
      <Link to={`/account/${accountId}`} style={{ textDecoration: 'none' }}>
        <PointerIcon strokeWidth="1px" icon="ChevronLeft" color={color} {...{ includeBackText }} />
        {includeBackText ? <MediumGreenText>Back to account</MediumGreenText> : null}
      </Link>
    </AccountHomeLinkWrapper>
  );
};
