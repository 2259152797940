import { createSelector } from 'reselect';
import { ApplicationState } from '../state';
import { QuestionnaireStateState } from './state';

export const questionnaireStateSelector = createSelector(
  (appState: ApplicationState): QuestionnaireStateState | undefined => appState.questionnaireState,
  (questionnaireState) => (questionnaireState ? questionnaireState.data : undefined)
);

export const questionnaireStateLoadingSelector = createSelector(
  (appState: ApplicationState): QuestionnaireStateState | undefined => appState.questionnaireState,
  (questionnaireState) => !questionnaireState || !questionnaireState.loading
);
