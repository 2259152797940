import { USStateMapping } from '@rategravity/core-lib/enums';
import { currency, rate } from '@rategravity/frontend/modules/numbers';
import { composeReducers, ofType, withDefault } from 'redux-compose';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  generate as generateApi,
  PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
  PreapprovalsApiResponseAction
} from './api';
import { getUiAmounts } from './local-state';
import { Address, Generate, GenerateError, GenerateState, PreapprovalIdProperties } from './state';

const isNumber = (numberish: unknown): boolean =>
  !isNaN(numberish as number) &&
  numberish !== null &&
  numberish !== '' &&
  (typeof numberish === 'number' || typeof numberish === 'string') &&
  Number(numberish) >= 0;

const precisionRound = (num: number, precision: number) => {
  const factor = Math.pow(10, precision);
  return Math.round(num * factor) / factor;
};

// Actions
const GENERATE_UPDATE_PREAPPROVAL_AMOUNT_ACTION_TYPE =
  'PREAPPROVALS__GENERATE_UPDATE_PREAPPROVAL_AMOUNT';
export const updatePreapprovalAmount = (preapprovalAmount: number) => ({
  type: GENERATE_UPDATE_PREAPPROVAL_AMOUNT_ACTION_TYPE,
  payload: preapprovalAmount
});
export type UpdatePreapprovalAmountAction = ReturnType<typeof updatePreapprovalAmount>;

const GENERATE_UPDATE_DOWNPAYMENT_ACTION_TYPE = 'PREAPPROVALS__GENERATE_UPDATE_DOWNPAYMENT';
export const updateDownPayment = (
  payload: { downPaymentAmount: number } | { downPaymentPercent: number }
) => ({
  type: GENERATE_UPDATE_DOWNPAYMENT_ACTION_TYPE,
  payload
});
export type UpdateDownPaymentAction = ReturnType<typeof updateDownPayment>;

const GENERATE_SUBMIT_ACTION_TYPE = 'PREAPPROVALS__GENERATE_SUBMIT';
const submit = () => ({
  type: GENERATE_SUBMIT_ACTION_TYPE
});

const VALIDATE_DOWNPAYMENT_ACTION_TYPE = 'PREAPPROVALS__GENERATE_VALIDATE_DOWNPAYMENT';
export const validateDownpayment = () => ({
  type: VALIDATE_DOWNPAYMENT_ACTION_TYPE
});

const GENERATE_ACTION_TYPE = 'PREAPPROVALS__GENERATE';
export const generate = () => ({
  type: GENERATE_ACTION_TYPE
});
export type GenerateAction = ReturnType<typeof generate>;

const GENERATING_ACTION_TYPE = 'PREAPPROVALS__GENERATE_GENERATING';
const generating = (gen: boolean) => ({
  type: GENERATING_ACTION_TYPE,
  generating: gen
});
export type GeneratingAction = ReturnType<typeof generating>;

const SELECT_INTENT_ACTION_TYPE = 'PREAPPROVALS__SELECT_INTENT';
export const selectIntent = (intent: string) => ({
  type: SELECT_INTENT_ACTION_TYPE,
  payload: intent
});
export type SelectIntentAction = ReturnType<typeof selectIntent>;

const ADDRESS_CHANGE_ACTION_TYPE = 'PREAPPROVALS__ADDRESS_CHANGE';
export const addressChange = (address: Address, isValidating = false) => ({
  type: ADDRESS_CHANGE_ACTION_TYPE,
  payload: { address, isValidating }
});
export type AddressChangeAction = ReturnType<typeof addressChange>;

export const getDownPayment = (
  partialState: Pick<
    GenerateState,
    'preapprovalAmount' | 'downPaymentPercent' | 'downPaymentAmount'
  >
): { downPaymentPercent: number; downPaymentAmount: number } => {
  if (partialState.downPaymentPercent !== null) {
    return {
      downPaymentPercent: partialState.downPaymentPercent,
      downPaymentAmount: Math.max(
        0,
        Math.min(
          partialState.preapprovalAmount,
          Math.round(partialState.preapprovalAmount * (partialState.downPaymentPercent / 100))
        )
      )
    };
  } else if (partialState.downPaymentAmount !== null) {
    return {
      downPaymentAmount: partialState.downPaymentAmount,
      downPaymentPercent: Math.max(
        0,
        Math.min(
          100,
          precisionRound((partialState.downPaymentAmount / partialState.preapprovalAmount) * 100, 1)
        )
      )
    };
  } else {
    return {
      downPaymentAmount: 0,
      downPaymentPercent: 0
    };
  }
};

// Saga
function* handleGenerate() {
  const {
    preapprovals: {
      data: { constraintsId },
      generate: {
        preapprovalAmount,
        downPaymentAmount,
        downPaymentPercent,
        address,
        preapprovalAmountError,
        downPaymentError,
        loanValueError,
        stateError,
        intent
      }
    }
  } = yield select();
  // runs a validation function, which will throw any applicable errors into redux
  yield put(submit());

  if (!preapprovalAmountError && !downPaymentError && !loanValueError && !stateError) {
    yield put(generating(true));
    yield put(
      generateApi({
        constraintsId,
        preapprovalAmount,
        downPaymentAmount: getDownPayment({
          preapprovalAmount,
          downPaymentAmount,
          downPaymentPercent
        }).downPaymentAmount,
        state: address.state,
        address: {
          ...address,
          street: address.address
        },
        intent
      })
    );
  }
}

export function* generateSaga() {
  yield all([takeEvery(GENERATE_ACTION_TYPE, handleGenerate)]);
}

// Reducers
const apiReducer = ofType(
  PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
  (
    state: GenerateState,
    {
      requestType,
      data = {} as Generate & PreapprovalIdProperties,
      error = {} as GenerateError
    }: PreapprovalsApiResponseAction<Generate, GenerateError>
  ): GenerateState => {
    const addressState =
      Object.keys(data.constraintsByState || state.constraintsByState).length === 1
        ? Object.keys(data.constraintsByState || state.constraintsByState)[0]
        : state.address.state;
    return {
      ...state,
      maxLoanValue: data.maxLoanValue || state.maxLoanValue,
      maxPreapprovalAmount: data.maxPreapprovalAmount || state.maxPreapprovalAmount,
      constraintsByState: data.constraintsByState || state.constraintsByState,
      preapprovalAmountError: (error || {}).preapprovalAmountError || state.preapprovalAmountError,
      downPaymentError: (error || {}).downPaymentError || state.downPaymentError,
      loanValueError: (error || {}).loanValueError || state.loanValueError,
      generating: requestType !== 'generate' && state.generating,
      address: {
        ...state.address,
        state: addressState
      },
      ...getUiAmounts(data, state)
    };
  }
);

const calculatedFields = (state: GenerateState) => {
  const { downPaymentAmount } = getDownPayment(state);
  const loanValue = Math.max(0, Math.round(state.preapprovalAmount - downPaymentAmount));
  if (state.loanValue !== loanValue) {
    return {
      ...state,
      loanValue
    };
  }
  return state;
};

const validate = (
  validatedDownpayment: boolean,
  validateLoanValue: boolean,
  validateState: boolean,
  state: GenerateState
) => {
  const minDown = (
    state.constraintsByState[state.address.state] ||
    Object.values(state.constraintsByState)[0] || { constraints: [] }
  ).constraints
    .filter(({ loanValue: lv }) => lv <= (state.loanValue || 0))
    .reduce(
      (
        l: { loanValue: number; percentDown: number },
        r: { loanValue: number; percentDown: number }
      ) => (l.loanValue > r.loanValue ? l : r),
      { loanValue: -1, percentDown: 0 }
    ).percentDown;

  let preapprovalAmountError: string | null = null;
  if (state.preapprovalAmount <= 0 || Number.isNaN(state.preapprovalAmount)) {
    preapprovalAmountError = 'Please choose a purchase price greater than zero.';
  } else if (state.preapprovalAmount > state.maxPreapprovalAmount) {
    preapprovalAmountError = `Please choose a purchase price less than ${currency(
      state.maxPreapprovalAmount,
      0
    )}.`;
  }

  let loanValueError: string | null = null;
  if ((state.loanValue || 0) > state.maxLoanValue) {
    loanValueError = `Adjust your purchase price or down payment to equal a loan amount of ${currency(
      state.maxLoanValue,
      0
    )} or less.`;
  } else if (validateLoanValue && (state.loanValue || 0) <= 0) {
    loanValueError = `Please choose a loan value greater than zero.`;
  }

  let downPaymentError: string | null = null;
  const { downPaymentPercent } = getDownPayment(state);
  if (!isNumber(state.downPaymentAmount) && !isNumber(state.downPaymentPercent)) {
    downPaymentError = 'Please select a down payment.';
  } else if (
    (downPaymentPercent || 0) < minDown &&
    (state.downPaymentError || validatedDownpayment)
  ) {
    downPaymentError = `For this loan amount please choose a down payment greater than ${rate(
      minDown,
      2
    )}.`;
  }

  let stateError: string | null = null;
  const interestedStates = Object.keys(state.constraintsByState);

  if (validateState) {
    if (!state.address.state) {
      stateError = 'Please select a state';
    } else if (!interestedStates.includes(state.address.state)) {
      const fullStateName = USStateMapping.find(
        ({ abbreviation }) => abbreviation === state.address.state
      )?.name;
      stateError = `${
        fullStateName || 'The selected state'
      } is not one of your states of interest. Please contact your Home Advisor.`;
    }
  }

  let intentError: string | null = null;
  if (!state.intent) {
    intentError = 'Please make a selection';
  }

  if (
    preapprovalAmountError !== state.preapprovalAmountError ||
    loanValueError !== state.loanValueError ||
    downPaymentError !== state.downPaymentError ||
    stateError !== state.stateError ||
    intentError !== state.intentError
  ) {
    return {
      ...state,
      preapprovalAmountError,
      loanValueError,
      downPaymentError,
      stateError,
      intentError
    };
  }
  return state;
};

const updatePreapprovalAmountReducer = ofType(
  GENERATE_UPDATE_PREAPPROVAL_AMOUNT_ACTION_TYPE,
  (state: GenerateState, { payload }: UpdatePreapprovalAmountAction) => {
    const { downPaymentAmount } = getDownPayment(state);
    return validate(false, false, false, {
      ...state,
      preapprovalAmount: payload,
      downPaymentAmount,
      downPaymentPercent: null
    });
  }
);

const updateDownPaymentReducer = ofType(
  GENERATE_UPDATE_DOWNPAYMENT_ACTION_TYPE,
  (state: GenerateState, { payload }: UpdateDownPaymentAction) =>
    validate(false, false, false, {
      ...state,
      downPaymentAmount:
        'downPaymentAmount' in payload ? Math.max(0, payload.downPaymentAmount) : null,
      downPaymentPercent:
        'downPaymentPercent' in payload
          ? Math.max(0, Math.min(100, payload.downPaymentPercent))
          : null
    })
);

const submitReducer = ofType(GENERATE_SUBMIT_ACTION_TYPE, (state: GenerateState) =>
  validate(false, false, true, state)
);

const validateDownpaymentReducer = ofType(
  VALIDATE_DOWNPAYMENT_ACTION_TYPE,
  validate.bind(null, true, false, false)
);

const validateOnGenerateReducer = ofType(
  GENERATE_ACTION_TYPE,
  validate.bind(null, true, true, true)
);

const generatingReducer = ofType(
  GENERATING_ACTION_TYPE,
  (state: GenerateState, { generating: gen }: GeneratingAction) => ({
    ...state,
    generating: gen
  })
);

const intentReducer = ofType(
  SELECT_INTENT_ACTION_TYPE,
  (state: GenerateState, { payload }: SelectIntentAction) => ({
    ...state,
    downPaymentAmount: (payload === 'looking'
      ? state.maxPreapprovalAmount - state.maxLoanValue
      : state.defaultDownPayment) as number | null,
    preapprovalAmount:
      payload === 'looking' ? state.maxPreapprovalAmount : state.defaultPreapprovalAmount,
    intent: payload as string | null,
    intentError: null as string | null
  })
);

const addressReducer = ofType(
  ADDRESS_CHANGE_ACTION_TYPE,
  (state: GenerateState, { payload: { address, isValidating } }: AddressChangeAction) =>
    isValidating
      ? validate(true, false, true, {
          ...state,
          stateError: null as string | null,
          address
        })
      : {
          ...state,
          address
        }
);

export const reducer = withDefault(
  {
    defaultDownPayment: 0,
    defaultPreapprovalAmount: 0,
    maxLoanValue: 0,
    address: {
      state: ''
    },
    stateError: null,
    maxPreapprovalAmount: 0,
    constraintsByState: {},
    preapprovalAmount: 0,
    preapprovalAmountError: null,
    downPaymentAmount: 0,
    downPaymentPercent: null,
    downPaymentError: null,
    loanValue: null,
    loanValueError: null,
    generating: false,
    intent: null,
    intentError: null
  },
  composeReducers(
    apiReducer,
    updatePreapprovalAmountReducer,
    updateDownPaymentReducer,
    submitReducer,
    calculatedFields,
    validateDownpaymentReducer,
    validateOnGenerateReducer,
    generatingReducer,
    intentReducer,
    addressReducer
  )
);
