import { OwnUpNumberInput } from '@rategravity/own-up-component-library';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import { PercentIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/percent';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { updateDownPayment, validateDownpayment } from '../../redux/generate';
import { downPaymentSelector } from '../../redux/selectors';

const InputWrapper = styled('div')`
  margin-bottom: 12px;
`;

export const DownPaymentInputs = ({ required = false }: { required?: boolean }) => {
  const { downPaymentAmount, downPaymentPercent, error } = useSelector(downPaymentSelector);
  const dispatch = useDispatch();
  const validateDownPayment = () => dispatch(validateDownpayment());

  const updatePercent = (newDownPaymentPercent: number) => {
    if (newDownPaymentPercent !== downPaymentPercent) {
      dispatch(updateDownPayment({ downPaymentPercent: newDownPaymentPercent }));
    }
  };

  const updateAmount = (newDownPaymentAmount: number) => {
    if (newDownPaymentAmount !== downPaymentAmount) {
      dispatch(updateDownPayment({ downPaymentAmount: newDownPaymentAmount }));
    }
  };

  return (
    <>
      <InputWrapper>
        <OwnUpNumberInput
          name="Down payment amount"
          value={downPaymentAmount}
          decimalScale={0}
          allowNegative={false}
          label="Down payment amount"
          $leadingIcon={<DollarIcon />}
          onBlur={validateDownPayment}
          onValueChange={(value) => updateAmount(value.floatValue || 0)}
          error={!!error}
          required={required}
        />
      </InputWrapper>
      <InputWrapper>
        <OwnUpNumberInput
          name="Down payment percent"
          value={downPaymentPercent}
          decimalScale={2}
          allowNegative={false}
          label="Down payment percent"
          $leadingIcon={<PercentIcon />}
          onBlur={validateDownPayment}
          onValueChange={(value) => updatePercent(value.floatValue || 0)}
          error={!!error}
          helperText={error}
          required={required}
        />
      </InputWrapper>
    </>
  );
};
