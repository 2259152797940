import { useCallback, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { AddressModel } from '../../../../components/address/address-helpers';
import { AddressFuzzyMatchCountyType } from '../../../../components/address/fuzzy-match-service';
import { googleMapsApiKey } from '../../../../modules/api-keys';

interface FormattedPlaces {
  [key: string]: string;
}

const formattedPlace = (place: google.maps.places.PlaceResult) => {
  const formatted: FormattedPlaces = {};
  if (place.address_components) {
    place?.address_components.forEach((component: google.maps.GeocoderAddressComponent) => {
      component.types.forEach((type) => {
        formatted[type] = component.short_name;
      });
    });
  }
  return {
    zip: formatted.postal_code,
    city: formatted.locality,
    state: formatted.administrative_area_level_1,
    address: place.name || `${formatted.street_number || ''} ${formatted.route || ''}`.trim(),
    county: formatted.administrative_area_level_2
  };
};

export const useAddressAutocomplete = ({
  onAutocompleteChange,
  addressFuzzyMatchCounty
}: {
  onAutocompleteChange: (newValue: AddressModel) => void;
  addressFuzzyMatchCounty: AddressFuzzyMatchCountyType;
}) => {
  const handlePropertyFullAddressChange = useCallback(
    (newValue: AddressModel) => {
      onAutocompleteChange(newValue);
    },
    [onAutocompleteChange]
  );

  const onAutocompleteRef = useRef((_: AddressModel) => {
    return;
  });

  const onAutocomplete = useCallback(
    (address: AddressModel) => {
      void (async () => {
        const county = address.county || address.city;
        const countyMatch =
          address.state &&
          county &&
          (await addressFuzzyMatchCounty({ state: address.state, county }));
        handlePropertyFullAddressChange({
          ...address,
          county: countyMatch && countyMatch[1] ? countyMatch[1].name : ''
        });
      })();
    },
    [addressFuzzyMatchCounty, handlePropertyFullAddressChange]
  );

  onAutocompleteRef.current = onAutocomplete;

  const { ref: materialRef } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => {
      if (place.address_components) {
        const address = formattedPlace(place);
        onAutocompleteRef.current(address);
      }
    },
    inputAutocompleteValue: 'country',
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' }
    }
  });

  return { materialRef };
};
