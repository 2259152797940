import { currency } from '@rategravity/frontend/modules/numbers';
import { createSelector } from 'reselect';
import { camelCaseToSentence, spacesToCamelCase } from '../../../modules/format-words';
import { Attribute, Offer } from '../state';
import { rateQuoteSelector } from './rate-quote';

export interface Permutation {
  name: string;
  loanType?: string;
  attributes: { displayField: string; displayValue: string }[];
  offers: Offer[];
}

const loansToString = (loans: number[]) => {
  const loanType = ['HELOC', '2nd mortgage', 'Other mortgage'];
  return loans
    .map((loan, index) => (loan > 0 ? currency(loan, 0) + ' ' + loanType[index] : ''))
    .filter((v) => v)
    .join(', ');
};

const translateDisplayField = (displayField: string) => {
  switch (displayField) {
    case 'DOWN':
      return 'Down payment';
    case 'IRRRL':
      return displayField;
    default:
      return camelCaseToSentence(spacesToCamelCase(displayField));
  }
};

const toField = ({ name, value, format }: Attribute): Permutation['attributes'][number] => {
  let displayValue;
  if (typeof value === 'object') {
    const loanString = loansToString(value);
    displayValue = loanString ? loanString : 'None';
  } else if (format === 'currency' && typeof value === 'number') {
    displayValue = currency(value, 0);
  } else if (typeof value === 'number') {
    displayValue = value.toString();
  } else {
    displayValue = value;
  }
  return {
    displayField: translateDisplayField(name),
    displayValue
  };
};

export const displayPermutationsSelector = createSelector(
  rateQuoteSelector,
  (rate): Permutation[] =>
    rate
      .map(({ offerPermutations }) =>
        offerPermutations.map((permutation) => ({
          name: permutation.name,
          // break out loan type from attributes
          ...permutation.attributes.reduce(
            (acc, cur) =>
              cur.name === 'LOAN TYPE'
                ? {
                    ...acc,
                    loanType: cur.value as string
                  }
                : {
                    ...acc,
                    attributes: [...acc.attributes, toField(cur)]
                  },
            { attributes: [], loanType: undefined } as Pick<Permutation, 'attributes' | 'loanType'>
          ),
          offers: permutation.offers
        }))
      )
      .orElse(() => [])
);
