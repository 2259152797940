import { ApplicationState } from '../../../../redux/state';

export const inputsSelector = ({
  preapprovals: {
    simulator: {
      inputs: {
        homePrice,
        annualIncome,
        downPayment,
        monthlyDebt,
        approximateCreditScore,
        annualInterestRate,
        annualPropertyTaxRate,
        monthlyFee,
        monthlyHomeownersInsurance,
        jumboLoanLimit
      }
    }
  }
}: ApplicationState) => ({
  homePrice: homePrice || 0,
  annualIncome: annualIncome || 0,
  downPayment: downPayment || 0,
  monthlyDebt: monthlyDebt || 0,
  approximateCreditScore: approximateCreditScore || 0,
  annualInterestRate: annualInterestRate || 0,
  annualPropertyTaxRate: annualPropertyTaxRate || 0,
  monthlyFee: monthlyFee || 0,
  monthlyHomeownersInsurance: monthlyHomeownersInsurance || 0,
  jumboLoanLimit
});
