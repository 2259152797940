import { useSelector } from 'react-redux';
import { suggestedHomePageSelector } from '../redux/selector';

const NON_SIDEBAR_PAGES = ['lender-direct', 'anywhere-referral', 'ineligible'];
export const useNewGrid = () => {
  // Pages that do NOT show the new sidebar/navbar:
  // rates, preapproval, lender-direct, anywhere-referral, ineligible
  const isToolPage =
    window.location.href.includes('rates') || window.location.href.includes('preapproval');

  const suggestedHomePage = useSelector(suggestedHomePageSelector);
  return !isToolPage && !NON_SIDEBAR_PAGES.includes(suggestedHomePage);
};
