import { useEffect, useRef, useState } from 'react';

// From:
// https://github.com/gdkraus/accessible-modal-dialog/blob/master/modal-window.js#L38
const focusableElementsCSS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

// Adapted from:
// https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
export const useFocusTrap = <TElement extends HTMLElement = HTMLElement>(
  active: boolean = true
) => {
  const ref = useRef<TElement | null>(null);

  const [dummy, updateDummy] = useState(true);

  useEffect(() => {
    const { current } = ref;
    const refresh = () => updateDummy(!dummy);

    if (current && active) {
      const focusableElements = current.querySelectorAll(focusableElementsCSS);
      if (!focusableElements.length) {
        return;
      }

      const firstElement = focusableElements[0] as HTMLElement;
      const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

      const firstElementListener = (e: KeyboardEvent) => {
        if (e.key === 'Tab' && e.shiftKey) {
          e.preventDefault();
          lastElement.focus();
        }
      };
      const lastElementListener = (e: KeyboardEvent) => {
        if (e.key === 'Tab' && !e.shiftKey) {
          e.preventDefault();
          firstElement.focus();
        }
      };

      firstElement.addEventListener('keydown', firstElementListener);
      lastElement.addEventListener('keydown', lastElementListener);

      const observer = new MutationObserver(refresh);
      observer.observe(current, { attributes: true, childList: true, subtree: true });

      return () => {
        firstElement.removeEventListener('keydown', firstElementListener);
        lastElement.removeEventListener('keydown', lastElementListener);
        observer.disconnect();
      };
    }
  }, [ref, active, dummy]);

  return ref;
};
