import React from 'react';
import realtorLogo from '../../assets/realtor-logo.svg';
import { RDCCobranding } from './rdc';

interface Cobranding {
  logo: JSX.Element;
  cobranding: JSX.Element;
}

export const cobrandingMap: Record<string, Cobranding> = {
  rdc: {
    logo: <img src={realtorLogo} alt="Realtor.com Logo" />,
    cobranding: <RDCCobranding />
  }
};

export const Cobranding = ({ source }: { source: string }) =>
  !!cobrandingMap[source] ? cobrandingMap[source].cobranding : null;

export const CobrandingLogo = ({ source }: { source: string }) =>
  !!cobrandingMap[source] ? cobrandingMap[source].logo : null;
