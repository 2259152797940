import { composeReducers, ofType, withDefault } from 'redux-compose';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import {
  PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
  PreapprovalsApiResponseAction,
  share as shareApi
} from './api';
import { ShareState } from './state';

// ACTIONS

const SHARE_ACTION_TYPE = 'PREAPPROVALS__SHARE';
export const share = (payload: { name: string; email: string }) => ({
  type: SHARE_ACTION_TYPE,
  payload
});
type ShareAction = ReturnType<typeof share>;

// SAGAS

function* handleShare() {
  const {
    preapprovals: {
      share: { name, email },
      data: { constraintsId, preapprovalId }
    }
  } = yield select();
  yield put(shareLoadingAction());

  yield put(shareApi({ constraintsId, preapprovalId, name, email }));
}

export function* shareSaga() {
  yield all([takeEvery(SHARE_ACTION_TYPE, handleShare)]);
}

// REDUCERS

const shareReducer = ofType(SHARE_ACTION_TYPE, (state: ShareState, { payload }: ShareAction) => ({
  ...state,
  ...payload,
  status: 'unshared' as 'loading' | 'unshared' | 'shared' | 'error'
}));

const apiReducer = ofType(
  PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
  (
    state: ShareState,
    {
      requestType,
      status
    }: PreapprovalsApiResponseAction<object, Pick<ShareState, 'nameError' | 'emailError'>>
  ) => {
    if (requestType !== 'share') {
      return state;
    } else if (status === 204 || status === 200) {
      return {
        ...state,
        status: 'shared' as const
      };
    } else {
      return {
        ...state,
        status: 'error' as const
      };
    }
  }
);

const SHARE_LOADING_ACTION_TYPE = 'PREAPPROVALS__SHARE_LOADING';
export const shareLoadingAction = () => ({
  type: SHARE_LOADING_ACTION_TYPE
});

const loadingReducer = ofType(SHARE_LOADING_ACTION_TYPE, (state: ShareState) => ({
  ...state,
  status: 'loading' as 'loading' | 'unshared' | 'shared' | 'error'
}));

export const reducer = withDefault(
  {
    name: '',
    nameError: null,
    email: '',
    emailError: null,
    status: 'unshared'
  },
  composeReducers(shareReducer, apiReducer, loadingReducer)
);
