import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useIsMobile } from '../../../../hooks/is-mobile';
import {
  UpdateHomePriceAction,
  UpdateHomePricePayloadType,
  UpdateInputsAction
} from '../../../redux/simulator/inputs/actions';
import { InputsState } from '../../../redux/simulator/inputs/state';
import { DownPaymentInput } from './inputs/down-payment-input';
import { HomePriceInput } from './inputs/home-price-input';

const ComboWrapperStyle = createOwnUpStyle({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
});

const ComboWrapper = createOwnUpComponent(OwnUpBox, ComboWrapperStyle);

const FormFieldWrapperStyle = ({ isMobile }: { isMobile: boolean }) =>
  createOwnUpStyle({
    padding: '4px 0',
    ...(isMobile ? { width: '100%' } : { marginRight: '20px' })
  });

const FormFieldWrapper = createOwnUpComponent(OwnUpBox, FormFieldWrapperStyle);

export const HomePriceDownPaymentCombo = ({
  homePriceLabel,
  downPaymentLabel,
  homePrice,
  downPayment,
  updateHomePrice,
  updateDownPayment,
  reverse = false
}: {
  homePriceLabel: JSX.Element;
  downPaymentLabel: JSX.Element;
  homePrice: number;
  downPayment: number;
  updateHomePrice: (payload: UpdateHomePricePayloadType) => UpdateHomePriceAction;
  updateDownPayment: (payload: Partial<InputsState>) => UpdateInputsAction;
  reverse?: boolean;
}) => {
  const isMobile = useIsMobile(1024);

  const HomePriceField = (
    <FormFieldWrapper isMobile={isMobile}>
      {homePriceLabel}
      <HomePriceInput id="homePrice" value={homePrice} updateHomePrice={updateHomePrice} />
    </FormFieldWrapper>
  );

  return (
    <ComboWrapper>
      {reverse ? null : HomePriceField}
      <FormFieldWrapper isMobile={isMobile}>
        {downPaymentLabel}
        <DownPaymentInput
          id="downPayment"
          anchor={homePrice}
          value={downPayment}
          percent={Math.round((downPayment / homePrice) * 100)}
          updateDownPayment={updateDownPayment}
        />
      </FormFieldWrapper>
      {reverse ? HomePriceField : null}
    </ComboWrapper>
  );
};
