import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateHomePriceActionCreator,
  UpdateHomePricePayloadType,
  updateInputsActionCreator
} from '../../../preapproval/redux/simulator/inputs/actions';
import { InputsState } from '../../../preapproval/redux/simulator/inputs/state';
import { FormComponent } from '../../components/simulator/form';
import { inputsSelector } from '../../redux/simulator/inputs/selectors';

export const ConnectedForm = () => {
  const dispatch = useDispatch();
  const inputs = useSelector(inputsSelector);
  const updateInputs = useCallback(
    (payload: Partial<InputsState>) => dispatch(updateInputsActionCreator(payload)),
    [dispatch]
  );
  const updateHomePrice = useCallback(
    (payload: UpdateHomePricePayloadType) => dispatch(updateHomePriceActionCreator(payload)),
    [dispatch]
  );

  return (
    <FormComponent
      {...{
        ...inputs,
        updateInputs,
        updateHomePrice
      }}
    />
  );
};
