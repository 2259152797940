import { SliderConstraintsState } from '../../redux/simulator/slider-constraints/state';
import {
  HIGH_DTI_SCENARIO,
  LOW_CREDIT_SCENARIO,
  LOW_DOWN_PAYMENT_SCENARIO,
  LOW_LOAN_AMOUNT_SCENARIO,
  NO_ANNUAL_INCOME_SCENARIO
} from '../../redux/simulator/special-scenario/state';

export const computeSpecialScenario = ({
  annualIncome,
  downPayment,
  homePrice,
  maxDTI,
  approximateCreditScore,
  monthlyDebt,
  sliderConstraints: {
    sliderMin,
    sliderMax,
    sliderGradient: { maxAffordable, maxStretch }
  }
}: {
  annualIncome: number;
  downPayment: number;
  homePrice: number;
  maxDTI: number;
  approximateCreditScore: number;
  monthlyDebt: number;
  sliderConstraints: Omit<SliderConstraintsState, 'sliderValue'>;
}) => {
  const noAnnualIncome = annualIncome === 0;
  const lowCredit = approximateCreditScore < 620;
  const lowLoanAmount = homePrice - downPayment < 100_000;
  const highDTI = (monthlyDebt * 12) / annualIncome >= maxDTI;

  // If multiple values for the slider constraint are the same,
  // it's because the down payment is depressing the value space.
  const sliderConstraintValues = [sliderMin, maxAffordable, maxStretch, sliderMax];

  const downPaymentConstraint = sliderConstraintValues.some(
    (element, index) => element !== null && sliderConstraintValues.indexOf(element) !== index
  );

  if (noAnnualIncome) {
    return NO_ANNUAL_INCOME_SCENARIO;
  }
  if (lowCredit) {
    return LOW_CREDIT_SCENARIO;
  }
  if (lowLoanAmount) {
    return LOW_LOAN_AMOUNT_SCENARIO;
  }
  if (highDTI) {
    return HIGH_DTI_SCENARIO;
  }
  if (downPaymentConstraint) {
    return LOW_DOWN_PAYMENT_SCENARIO;
  }
  return null;
};
