import { all, fork } from 'redux-saga/effects';
import { dataSaga } from './data';
import { generateSaga } from './generate';
import { loadingSaga } from './loading';
import { afterReducerSaga } from './local-state';
import { shareSaga } from './share';
import { simulatorSaga } from './simulator/saga';

export const sagaWithoutApi = function* () {
  yield all([
    fork(afterReducerSaga),
    fork(dataSaga),
    fork(generateSaga),
    fork(loadingSaga),
    fork(shareSaga),
    fork(simulatorSaga)
  ]);
};
