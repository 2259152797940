import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React, { PropsWithChildren } from 'react';
import { CSSObject } from 'styled-components';
import { colors } from '../../../../modules/colors';
import { RegularDarkText } from '../typography';
import HandleSVG from './handle.svg';

const sliderOverrides: CSSObject = {
  '& > div.override': {
    width: '100%'
  },
  '& > div.override > div.override-track': {
    width: '100% !important' // !important flag required to override styles from rc-slider package
  },
  '& > div.override > div.override-mark': {
    position: 'relative',
    width: '100%',
    bottom: '32px',
    left: 0
  },
  '& > div.override > div.override-mark > span': {
    position: 'absolute'
  },
  '& > div.override > div.override-mark > span:first-child': {
    left: 'calc(0% + 40px) !important' // !important flag required to override styles from rc-slider package
  },
  '& > div.override > div.override-mark > span:last-child': {
    left: 'calc(100% - 40px) !important' // !important flag required to override styles from rc-slider package
  },
  '& > div.override > div.override-handle:focus-visible': {
    boxShadow: '0 0 0 2px blue !important', // !important flag required to override styles from rc-slider package
    outline: 'none'
  }
};

export const WrapperWithOverrides = createOwnUpComponent(
  'div',
  ({ isMobile }: { isMobile?: boolean }) =>
    createOwnUpStyle({
      width: '100%',
      margin: '24px auto 0',
      ...sliderOverrides,
      ...(isMobile ? { width: '312px' } : {})
    })
);

export const Label = ({ children }: PropsWithChildren<{}>) => (
  <RegularDarkText>{children}</RegularDarkText>
);

export const handleStyle: CSSObject = {
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  border: `1px solid ${colors.PRIMARY_DISABLED}`,
  textAlign: 'center',
  boxShadow: '0 1px 8px rgba(62, 83, 81, 0.16)',
  position: 'relative',
  top: '-35px',
  backgroundColor: 'white',
  backgroundImage: `url(${HandleSVG})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '20px',
  zIndex: 100
};

export const trackStyle: CSSObject = {
  height: '10px',
  top: '-10px',
  position: 'relative',
  borderRadius: '100px'
};

export const railStyle: CSSObject = {
  height: '10px',
  borderRadius: '100px',
  backgroundColor: `${colors.MOSS_100}`,
  position: 'relative',
  top: '-62px'
};
