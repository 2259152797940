import { browserInit as segment } from '@rategravity/frontend/modules/segment';
import {
  event,
  EventAction,
  identify,
  pageViewObservable
} from '@rategravity/frontend/modules/user-actions';
import { History } from 'history';
import { Middleware } from 'redux';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { PREAPPROVALS_API_RESPONSE_ACTION_TYPE, PreapprovalsApiResponseAction } from './api';

export const trackEventType = 'segment.track';
export const track = (id: string, properties: Record<string, unknown>) => ({
  type: trackEventType,
  id,
  properties
});

export const createMiddleware = (history: History<unknown>): Middleware => {
  const actionPipe = new ReplaySubject(10);
  // Route page view actions to the action pipe
  pageViewObservable(history).subscribe(actionPipe);

  // Create unique event names for question view/answer before
  // passing to segment as this facilitates funnel creation
  segment(actionPipe);

  return () => (next) => (action) => {
    switch (action.type) {
      case trackEventType:
        const { id, properties } = action as unknown as EventAction;
        actionPipe.next(event(id, properties));
        break;
      case PREAPPROVALS_API_RESPONSE_ACTION_TYPE:
        const { data: { userId } = {} } = action as unknown as PreapprovalsApiResponseAction<
          { userId?: string },
          any
        >;
        if (userId) {
          actionPipe.next(identify(userId));
        }
    }
    return next(action);
  };
};
