import React, { PropsWithChildren, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReRenderAt } from '../../../hooks/use-re-render-at';
import { generatingPreapprovalSelector } from '../../redux/selectors';
import { LoadingPreapproval } from '.';

// given a boolean value and a timeout, returns a value that will "latch" open to true until
// the timeout has expired.
const useTimedLatch = (value: boolean, time: number) => {
  const timeout = useRef<number | null>(null);
  const hasExpired = () => {
    return timeout.current === null || timeout.current + time < Date.now();
  };
  if (value && timeout.current === null) {
    timeout.current = Date.now();
  }
  if (!value && hasExpired()) {
    timeout.current = null;
  }
  useReRenderAt(timeout.current === null ? null : timeout.current + time);
  return value || !hasExpired();
};

export const GeneratingOverlay = ({ children }: PropsWithChildren<{}>) => {
  const { show, errors } = useSelector(generatingPreapprovalSelector);
  const shouldShow = useTimedLatch(show, 5000) && !errors;

  if (shouldShow) {
    return <LoadingPreapproval />;
  } else {
    return <div>{children}</div>;
  }
};
