import { event } from '@rategravity/frontend/modules/user-actions';
import {
  OwnUpFillButtonPrimary,
  OwnUpFillButtonSecondary,
  OwnUpNavLink,
  OwnUpNavMenuModal
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { ActionDispatcher, useActionPipe } from '../../hooks/use-action-pipe/hook';

const navButtons = ({
  logOut,
  setOpenModal,
  showLogOut,
  dispatchAction
}: {
  logOut: () => Promise<void>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  showLogOut: boolean;
  dispatchAction: ActionDispatcher;
}) => [
  ...(showLogOut
    ? [
        <OwnUpFillButtonSecondary
          key="logout"
          onClick={(e: React.MouseEvent) => {
            dispatchAction(event('Nav Bar Modal Logout Clicked'));
            e.preventDefault();
            void logOut();
          }}
        >
          Sign out
        </OwnUpFillButtonSecondary>
      ]
    : []),
  <OwnUpFillButtonPrimary
    key="return-home"
    onClick={() => {
      dispatchAction(event('Nav Bar Modal Return Home Clicked'));
      setOpenModal(false);
    }}
  >
    Return home
  </OwnUpFillButtonPrimary>
];

export interface Link {
  name: string;
  onClick: () => void;
}

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)}px;
`;

export const NavModal = ({
  open,
  setOpenModal,
  links,
  logOut,
  showLogOut,
  logoLink
}: {
  open: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  links: Link[];
  logOut: () => Promise<void>;
  showLogOut: boolean;
  logoLink: string;
}) => {
  const dispatchAction = useActionPipe();
  const onClickWrapper = (name: string, onClick: () => void) => () => {
    setOpenModal(false);
    dispatchAction(event('Nav Bar Modal Link Clicked', { link: name }));
    onClick();
  };

  return (
    <OwnUpNavMenuModal
      open={open}
      onClose={() => setOpenModal(false)}
      buttons={navButtons({ logOut, setOpenModal, showLogOut, dispatchAction })}
      homepageLinkProps={{ href: logoLink }}
    >
      <LinksWrapper>
        {links.map(({ name, onClick }, index) => (
          <OwnUpNavLink size="large" key={index} onClick={onClickWrapper(name, onClick)}>
            {name}
          </OwnUpNavLink>
        ))}
      </LinksWrapper>
    </OwnUpNavMenuModal>
  );
};
