import {
  BERRY_100,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpLargeBody,
  OwnUpRadioButton,
  OwnUpRadioGroup,
  OwnUpSmallBody
} from '@rategravity/own-up-component-library';
import { ErrorLightIcon } from '@rategravity/own-up-component-library/icon-library/notification-icons/error-light';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectIntent } from '../../redux/generate';
import { intentErrorSelector, intentSelector } from '../../redux/selectors';

const RadioGroupWrapper = styled('div')(({ theme }) => ({
  margin: '24px 0',
  [theme.breakpoints.up('lg')]: {
    margin: '32px 0'
  }
}));

const HeaderWrapper = styled(OwnUpGridItem)(({ theme }) => ({
  paddingTop: '24px',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '32px'
  }
}));

const ErrorWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-bottom: 16px;
`;

const ErrorIconWrapper = styled('div')`
  display: flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 20px;
`;

const ErrorMessage = ({ error }: { error: string }) => (
  <ErrorWrapper>
    <ErrorIconWrapper>
      <ErrorLightIcon />
    </ErrorIconWrapper>
    <OwnUpSmallBody variant="body1" style={{ color: BERRY_100 }}>
      {error}
    </OwnUpSmallBody>
  </ErrorWrapper>
);

export const IntentFilter = () => {
  const intent = useSelector(intentSelector);
  const intentError = useSelector(intentErrorSelector);
  const dispatch = useDispatch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(selectIntent(e.target.value));
  return (
    <>
      <HeaderWrapper xs={12} sm={10}>
        <OwnUpLargeBody variant="body1">
          Are you making an offer or just looking at properties?
        </OwnUpLargeBody>
      </HeaderWrapper>
      <OwnUpGridOffset xs={0} sm={2} />
      <OwnUpGridItem xs={12} sm={10} md={6}>
        <RadioGroupWrapper>
          {intentError ? <ErrorMessage error={intentError} /> : null}
          <OwnUpRadioGroup onChange={onChange} value={intent}>
            <OwnUpRadioButton value="offer">I&apos;m making an offer</OwnUpRadioButton>
            <OwnUpRadioButton value="looking">I&apos;m just looking</OwnUpRadioButton>
          </OwnUpRadioGroup>
        </RadioGroupWrapper>
      </OwnUpGridItem>
    </>
  );
};
