import { isRefinance, Loan } from './loan';
import { monthlyFV, monthlyPMT } from './math';

export function loanValue(
  params: (
    | { purchasePrice: number; downPayment: number }
    | { remainingLoanBalance: number; cashOut: number }
  ) & { upMip?: number; amtToFinance?: number }
): number {
  const { upMip = 0, amtToFinance = 0 } = params;
  if ('cashOut' in params) {
    return params.remainingLoanBalance + params.cashOut + upMip + amtToFinance;
  } else {
    return params.purchasePrice + upMip + amtToFinance - params.downPayment;
  }
}

export function payments(
  params: (
    | { purchasePrice: number; downPayment: number }
    | { remainingLoanBalance: number; cashOut: number }
  ) & {
    upMip?: number;
    amtToFinance?: number;
    rate: number;
    loanTerm: number;
  }
): number {
  return monthlyPMT(params.rate, params.loanTerm, loanValue(params));
}

export function futureValue(
  params: (
    | { purchasePrice: number; downPayment: number }
    | { remainingLoanBalance: number; cashOut: number }
  ) & {
    upMip?: number;
    amtToFinance?: number;
    rate: number;
    loanTerm: number;
    remainingTerm: number;
    pAndI: number;
  }
): number {
  return monthlyFV(
    params.rate,
    params.pAndI,
    loanValue(params),
    params.loanTerm - params.remainingTerm
  );
}

export function ltv(balance: number, loan: Loan): number {
  return balance / (isRefinance(loan) ? loan.propertyValue : loan.purchasePrice);
}
