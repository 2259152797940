import React from 'react';
import * as colors from '../../../colors';

export const MenuIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.75 3C2.33579 3 2 3.33579 2 3.75C2 4.16421 2.33579 4.5 2.75 4.5H17.25C17.6642 4.5 18 4.16421 18 3.75C18 3.33579 17.6642 3 17.25 3H2.75Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      d="M2 10C2 9.58579 2.33579 9.25 2.75 9.25H17.25C17.6642 9.25 18 9.58579 18 10C18 10.4142 17.6642 10.75 17.25 10.75H2.75C2.33579 10.75 2 10.4142 2 10Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      d="M2 16.25C2 15.8358 2.33579 15.5 2.75 15.5H17.25C17.6642 15.5 18 15.8358 18 16.25C18 16.6642 17.6642 17 17.25 17H2.75C2.33579 17 2 16.6642 2 16.25Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
