import { validTLDs } from './valid-tlds';

// This is the pretty close to the rfc2822 specification expect no quotes
const expression =
  /^[a-zA-Z0-9]+[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*[^.!@#$%&'*+/=?^_`{|}~-]@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// Check that the local and domain email sections meet the required characters
const localAndDomainCheck = (email: string) => {
  const [local, domain] = email.split('@');
  return local.length <= 63 && domain.length <= 63;
};

// Check that the top level domain is valid according to our set.
const tldCheck = (email: string) => {
  const emailParts = email.split('.');
  return emailParts.length > 1 && validTLDs.has(emailParts[emailParts.length - 1].toUpperCase());
};

// Check the total email does not execede 254 characters.
const emailLengthCheck = (email: string) => email.length <= 254;

/**
 * This validation function is essentailly an implementation of hubspot's email
 * validation. It tests the email is rfc2822 valid as well as has no quotes and
 * has a valid TLD.
 */
export const validate = (email: string) =>
  emailLengthCheck(email) &&
  expression.test(email) &&
  localAndDomainCheck(email) &&
  tldCheck(email);
