import { identify } from '@rategravity/frontend/modules/user-actions';
import { createAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { fetchAccountManifest, StandardizedManifestResponse } from '../../modules/account';
import { actionPipe } from '../../modules/action-pipe';
import { sendToSentry } from '../../modules/sentry';
import { sleep } from '../../modules/sleep';
import { isWaitForComplete } from '../../modules/wait-for';
import { LOAD_ACCOUNT_ACTION_TYPE, LoadAccountAction } from '../actions';
import { questionnaireStateLoad } from '../questionnaire-state/actions';
import {
  MANIFEST_LOADED_FAILED_ACTION_TYPE,
  manifestLoadedFailed,
  ManifestLoadedFailedAction,
  manifestLoadedSuccess
} from './actions';

export const MAX_LOAD_ATTEMPTS = 3;
export const RELOAD_LOAD_INTERVAL_MS = 5000;

export const reloadManifestAction = createAction<LoadAccountAction['payload']>('RELOAD_MANIFEST');

export function* loadAccountManifest({ payload: { accountId, waitFor } }: LoadAccountAction) {
  try {
    const { success, json, error }: StandardizedManifestResponse = yield call(
      fetchAccountManifest,
      {
        accountId
      }
    );
    if (success && json) {
      const { advisor, ...manifest } = json;
      const { incompleteQuestionnaire, preapprovals, publishedRates, consult, ...accountInfo } =
        manifest;
      actionPipe.next(identify(accountId, { advisor, ...accountInfo }));
      const successPayload = {
        ...manifest,
        advisorEmail: advisor?.email,
        accountId
      };
      // If `waitFor` is defined and not yet finished...
      if (waitFor && !isWaitForComplete(waitFor, json)) {
        const cycle = (waitFor.cycle || 0) + 1;
        if (cycle > MAX_LOAD_ATTEMPTS) {
          const message = `No ${waitFor.manifestField} was returned after wait period`;
          if (waitFor.errorIfNotFound) {
            yield put(manifestLoadedFailed(message));
          } else {
            yield call(sendToSentry, new Error(message + ' -- page rendered anyway'));
            yield put(manifestLoadedSuccess(successPayload));
          }
        } else {
          yield call(sleep, cycle * RELOAD_LOAD_INTERVAL_MS);
          yield put(
            reloadManifestAction({
              accountId,
              waitFor: { ...waitFor!, cycle }
            })
          );
        }
      } else {
        yield put(manifestLoadedSuccess(successPayload));
        if (incompleteQuestionnaire && !incompleteQuestionnaire.evaluation) {
          yield put(
            questionnaireStateLoad(
              incompleteQuestionnaire.userId,
              incompleteQuestionnaire.questionnaireId
            )
          );
        }
      }
    } else {
      yield put(manifestLoadedFailed(error || ''));
    }
  } catch (err) {
    // User is redirected to sign in page if authentication expires, no need to report error to sentry
    if (
      typeof err === 'object' &&
      err !== null &&
      (('status' in err && err.status !== 401) || !('status' in err))
    ) {
      let message: string;

      if ('message' in err) {
        message = typeof err.message === 'string' ? err.message : JSON.stringify(err.message);
      } else {
        message = 'Loading Account Manifest Failed';
      }

      yield put(manifestLoadedFailed(message));
    }
  }
}

export function* failedAccountManifestLoad({ payload: { error } }: ManifestLoadedFailedAction) {
  yield call(sendToSentry, new Error('Failed to Load Account Manifest:' + error));
}

export function* saga() {
  yield all([
    takeEvery([LOAD_ACCOUNT_ACTION_TYPE, reloadManifestAction.type], loadAccountManifest),
    takeEvery([MANIFEST_LOADED_FAILED_ACTION_TYPE], failedAccountManifestLoad)
  ]);
}
