import { createHash } from 'crypto';
import cookies from 'js-cookie';

/**
 * Name of our OU 1PT cookie where we store a JSON-ified
 *   copy of the user's information.
 */
export const OU_1PT_COOKIE_NAME = 'OU_1PT_USER';

/** How our 1PT user cookies are structured */
interface CookieData {
  properties: {
    email?: string;
    phone?: string;
  };
}

/** Fetch the {@see OU_1PT_COOKIE_NAME} cookie */
const getCookieData = () => cookies.get(OU_1PT_COOKIE_NAME);

/**
 * Parse the {@see OU_1PT_COOKIE_NAME} cookie as {@see CookieData}.
 *
 * We don't force this through a converter, we'll just let the downstream
 *   operations fail silently if the data is malformed.
 */
const parseCookieData = (rawCookieData: string) => JSON.parse(rawCookieData) as CookieData;

/**
 * SHA-256 encode the input string (if provided) using Node's
 *   crypto functionality.
 */
const toSha256 = (str?: string) => {
  if (!str) {
    return undefined;
  }
  return Buffer.from(
    createHash('sha256').update(str.trim().toLowerCase()).digest('hex')
  ).toString();
};

/**
 * Convert any phone number into a `+1XXXXXXXXXX` format.
 */
const normalizePhone = (str?: string) => {
  if (!str) {
    return undefined;
  }
  // Strip any non-digit characters.
  const justNumbers = str.replace(/\D/g, '');
  // Assume we only have US numbers, since we don't operate outside of
  //   the US. Not a great assumption, but we already do it in our system.
  if (!justNumbers.startsWith('1')) {
    return `+1${justNumbers}`;
  }
  return `+${justNumbers}`;
};

/**
 * Extract and hash the enhanced conversion data from the
 *   parsed cookie payload.
 */
const extractConversionData = ({
  properties: { email, phone }
}: CookieData): typeof window.enhancedConversionData => ({
  /* eslint-disable @typescript-eslint/naming-convention */
  sha256_email_address: toSha256(email),
  sha256_phone_number: toSha256(normalizePhone(phone))
  /* eslint-enable @typescript-eslint/naming-convention */
});

/**
 * Get the enhanced conversion data for the current user based
 *   on their OU 1PT cookie data. All PII fields will be SHA-256
 *   encoded, which isn't amazing but it's better than nothing.
 *
 * If cookie data was found, it will be cached for the duration
 *   this user is on the page. We assume the cookie will not be
 *   updated on the page as that only happens at page load time.
 *
 * This data is ONLY meant to be sent to Google Analytics. We
 *   should not send this to 1PT, Facebook, Split, or any other
 *   platform.
 */
export const getEnhancedConversionData = () => {
  if (!window.enhancedConversionData) {
    try {
      const cookieData = getCookieData();
      if (cookieData) {
        const parsed = parseCookieData(cookieData);
        window.enhancedConversionData = extractConversionData(parsed);
      }
    } catch (err) {
      // ... swallow the error
    }
  }
  return window.enhancedConversionData;
};
