import { identify } from '@rategravity/frontend/modules/user-actions';
import { OwnUpComponentLibraryProvider } from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { match, useRouteMatch } from 'react-router-dom';
import { createSelector } from 'reselect';
import { Header } from '../components/header';
import { NavBar } from '../components/nav-bar';
import { useActionPipe } from '../hooks/use-action-pipe';
import { useNewGrid } from '../hooks/use-new-grid';
import { useSplitTreatmentWhenClientReady } from '../hooks/use-split-treatments-when-client-ready';
import { accountLogOut } from '../modules/authentication';
import { SHOW_CHAT_BOT } from '../modules/split';
import {
  hasChatBotExperienceSelector,
  isProvisionalAccountSelector,
  leadSplitAttributesSelector,
  manifestAccountInfoSelector
} from '../redux/manifest';
import { suggestedHomePageSelector } from '../redux/selector';

const customerInfoSelector = createSelector(manifestAccountInfoSelector, ({ name, email }) => ({
  name,
  email
}));

type HeaderViewProps = {
  isLoggedIn?: boolean;
  cobranding?: string;
  fixedHeader?: boolean;
};

export const HeaderView = ({ isLoggedIn = true, cobranding, fixedHeader }: HeaderViewProps) => {
  const headerProps = useSelector(customerInfoSelector);
  const isProvisionalAccount = useSelector(isProvisionalAccountSelector).value;
  const actionPipe = useActionPipe();
  const {
    params: { id: accountId }
  }: match<{ id: string }> = useRouteMatch();

  const logOut = async () => {
    actionPipe(identify(undefined));
    await accountLogOut(accountId);
  };

  const suggestedHomePage = useSelector(suggestedHomePageSelector);
  const leadAttributePayload = useSelector(leadSplitAttributesSelector);
  const hasChatBotExperience = useSelector(hasChatBotExperienceSelector);
  const { treatment: katinaChatBotTreatment } = useSplitTreatmentWhenClientReady(
    leadAttributePayload.value?.leadID,
    SHOW_CHAT_BOT,
    leadAttributePayload.value
  );

  return useNewGrid() ? (
    <OwnUpComponentLibraryProvider>
      <NavBar
        logOut={logOut}
        accountId={accountId}
        isProvisionalAccount={isProvisionalAccount}
        isLoggedIn={isLoggedIn}
        cobranding={cobranding}
        fixedHeader={fixedHeader}
      />
    </OwnUpComponentLibraryProvider>
  ) : (
    <Header
      logOut={logOut}
      accountId={accountId}
      isProvisionalAccount={isProvisionalAccount}
      isLoggedIn={isLoggedIn}
      cobranding={cobranding}
      useKatinaHeader={
        suggestedHomePage === 'lender-direct' &&
        // Frontend eval for the split treatment
        katinaChatBotTreatment === 'on' &&
        // Backend eval for the split treatment (won't update if split treatment changes)
        hasChatBotExperience
      }
      {...headerProps}
    />
  );
};
