import { InputsState } from '../../../preapproval/redux/simulator/inputs/state';
import { computeMonthlyPAndI } from './compute-monthly-p-and-i';
import { computeMonthlyPMI } from './compute-monthly-pmi';
import { computeMonthlyPropertyTax } from './compute-monthly-property-tax';

export const computeMonthlyPayment = ({
  homePrice,
  downPayment,
  approximateCreditScore,
  annualInterestRate,
  annualPropertyTaxRate,
  monthlyFee,
  monthlyHomeownersInsurance
}: InputsState) => {
  const monthlyPAndI =
    homePrice !== null && downPayment !== null && annualInterestRate !== null
      ? computeMonthlyPAndI(homePrice, downPayment, annualInterestRate)
      : null;

  const monthlyPropertyTax =
    homePrice !== null && annualPropertyTaxRate !== null
      ? computeMonthlyPropertyTax(homePrice, annualPropertyTaxRate)
      : null;

  const monthlyPMI =
    homePrice !== null && downPayment !== null && approximateCreditScore !== null
      ? computeMonthlyPMI(homePrice, downPayment, approximateCreditScore)
      : null;

  return {
    monthlyPAndI,
    monthlyPropertyTax,
    monthlyHomeownersInsurance,
    monthlyFee,
    monthlyPMI
  };
};
