/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useContext } from 'react';
import { createTrackingInstance, TrackingInstance } from './create-tracking-instance';

const trackingContext = createContext<TrackingInstance>(
  createTrackingInstance({ url: 'https://1pt.ownup.com', debug: true })
);

export const TrackingProvider = trackingContext.Provider;

export const useTrackingInstance = (): TrackingInstance => {
  const trackingInstance = useContext(trackingContext);
  return trackingInstance;
};
