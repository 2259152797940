import { createSelector } from 'reselect';

export const basePathSelector = createSelector(
  ({
    router: {
      location: { pathname }
    }
  }: {
    router: { location: { pathname: string } };
  }): string => pathname,
  (pathname) => {
    const [prefix] = pathname.split('/preapproval');
    return prefix;
  }
);
