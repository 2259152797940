import { FOG_20 } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const PageDiv = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  flex-grow: 1;
  background-color: ${FOG_20};
`;

export const Page = ({ header, children }: PropsWithChildren<{ header?: JSX.Element }>) => (
  <PageDiv>
    {header}
    <Main>{children}</Main>
  </PageDiv>
);
