import {
  OwnUpComponentLibraryProvider,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export const PageWrapper = styled.div<PropsWithTheme<DrawerPageWrapperProps>>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  ${({ leftMarginInPx, rightMarginInPx }) =>
    leftMarginInPx
      ? css`
          ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
            margin-left: ${leftMarginInPx}px;
          }
        `
      : css`
          ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
            margin-right: ${rightMarginInPx}px;
          }
        `}
`;

const DrawerRoot = styled.div`
  flex: 1 1 auto;
`;

type DrawerPageWrapperProps = {
  leftMarginInPx?: number;
  rightMarginInPx?: number;
};

export const DrawerPageWrapper = ({
  children,
  leftMarginInPx,
  rightMarginInPx
}: PropsWithChildren<DrawerPageWrapperProps>) => (
  <OwnUpComponentLibraryProvider>
    <PageWrapper leftMarginInPx={leftMarginInPx} rightMarginInPx={rightMarginInPx}>
      <div>{children}</div>
      <DrawerRoot id="drawer-root" />
    </PageWrapper>
  </OwnUpComponentLibraryProvider>
);
