import parseFullName from 'parse-full-name';

export interface ParsedName {
  first: string;
  middle: string;
  last: string;
  suffix: string;
}

export function parseName(name: string): ParsedName {
  const parsed = parseFullName.parseFullName(name.replace(/\s\s+/g, ' ').trim());
  if (!parsed.first && parsed.last) {
    const splitName = parsed.last.split(' ');
    parsed.first = splitName[0];
    parsed.last = '';
    if (splitName.length === 2) {
      parsed.last = splitName[1];
    }
    if (splitName.length === 3) {
      parsed.middle = splitName[1];
      parsed.last = splitName[2];
    }
  }
  return parsed;
}
