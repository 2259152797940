import { AddressFuzzyMatchService, County } from '@rategravity/address-verification';
import { useCallback, useEffect, useState } from 'react';

export async function buildFuzzyMatchService() {
  return await AddressFuzzyMatchService.build('/assets/');
}

//returns singleton instance of fuzzyMatchService
const initFuzzyMatchService = async (
  setAddressFuzzyMatchService: React.Dispatch<
    React.SetStateAction<AddressFuzzyMatchService | undefined>
  >
) => {
  setAddressFuzzyMatchService(await buildFuzzyMatchService());
};

export const useFuzzyMatchCountyService = ({
  setCounties
}: {
  setCounties: React.Dispatch<React.SetStateAction<County[]>>;
}) => {
  const [addressFuzzyMatchService, setAddressFuzzyMatchService] = useState<
    AddressFuzzyMatchService | undefined
  >(undefined);

  const setupFuzzyMatchService = useCallback(
    () => initFuzzyMatchService(setAddressFuzzyMatchService),
    [setAddressFuzzyMatchService]
  );

  useEffect(() => {
    void setupFuzzyMatchService();
  }, [setupFuzzyMatchService]);

  const genCountyList = useCallback(
    async (state: string) => {
      if (addressFuzzyMatchService) {
        const matchedCounties = await addressFuzzyMatchService
          .getCounties(state)
          .then(({ counties }) => counties);
        setCounties(matchedCounties || []);
      }
    },
    [addressFuzzyMatchService, setCounties]
  );

  const addressFuzzyMatchCounty = useCallback(
    async ({ state, county }: { state: string; county: string }) => {
      if (addressFuzzyMatchService) {
        return addressFuzzyMatchService.findCounty(state, county);
      }
    },
    [addressFuzzyMatchService]
  );

  return { genCountyList, addressFuzzyMatchCounty };
};

export type AddressFuzzyMatchCountyType = ReturnType<
  typeof useFuzzyMatchCountyService
>['addressFuzzyMatchCounty'];
export type GenCountyListType = ReturnType<typeof useFuzzyMatchCountyService>['genCountyList'];
