import { OwnUpIcon } from '@rategravity/own-up-component-library-legacy';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFocusTrap } from '../../../../hooks/use-focus-trap';
import { useIsShortViewport } from '../../../../hooks/use-is-short-viewport';
import { ConnectedAffordabilityDetails } from '../../../views/simulator/affordability-details';
import { ConnectedNumbersSummary } from '../../../views/simulator/numbers-summary';
import { RotatingDiv } from '../rotating-div';
import {
  ButtonContentContainer,
  ContentContainer,
  DrawerContents,
  DrawerWrapper,
  SeeMoreButton,
  SpacedGreenText
} from './components';

export const Drawer = ({ children }: React.PropsWithChildren<{}>) => {
  const [isOpen, setOpen] = useState(false);

  const isShortViewport = useIsShortViewport();

  const target = document.getElementById('drawer');

  useEffect(() => {
    if (target) {
      if (isOpen) {
        disableBodyScroll(target);
      } else {
        clearAllBodyScrollLocks();
        target.scrollTop = 0;
      }
    }
  }, [target, isOpen]);

  const focusTrap = useFocusTrap(isOpen);

  const [drawerRoot, setDrawerRoot] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setDrawerRoot(document.getElementById('drawer-root'));
  }, [drawerRoot]);

  if (!drawerRoot) {
    // Make sure the page is fully rendered first
    return null;
  }

  const content = (
    <DrawerWrapper ref={focusTrap} isOpen={isOpen} isShortViewport={isShortViewport} id="drawer">
      <SeeMoreButton
        onClick={() => {
          setOpen(!isOpen);
        }}
        isOpen={isOpen}
      >
        <ButtonContentContainer>
          <RotatingDiv variant={isOpen ? 'rotateOneEighty' : ''}>
            <OwnUpIcon
              icon="ChevronUp"
              style={isOpen ? { marginTop: '4px' } : { marginTop: '2px' }}
            />
          </RotatingDiv>
          <SpacedGreenText isOpen={isOpen}>See {isOpen ? 'less' : 'more'} detail</SpacedGreenText>
        </ButtonContentContainer>
      </SeeMoreButton>
      {isShortViewport && !isOpen ? null : (
        <ContentContainer>
          <ConnectedNumbersSummary />
          {!isOpen && <ConnectedAffordabilityDetails isDrawerCollapsed={true} />}
          <DrawerContents isOpen={isOpen}>{children}</DrawerContents>
        </ContentContainer>
      )}
    </DrawerWrapper>
  );

  return ReactDOM.createPortal(content, drawerRoot);
};
