export interface Fee {
  name?: string;
  value: number;
}

export interface FixedQuote {
  loanTerm: number;
  fixedTerm: number;
}

export interface ARMQuote {
  loanTerm: number;
  fixedTerm: number;
  adjustmentPeriod: number;
  caps: {
    inital: number;
    periodic: number;
    lifetime: number;
  };
  margin: number;
  indexValue: number;
}

export interface BaseLoan {
  product: FixedQuote | ARMQuote;
  rate: number;
  pAndI: number;
  fees: Fee[];
  prepaids: Fee[];
  mortgageInsurance: number;
  upMip?: number; // default to zero if ommitted.
  amtToFinance?: number;
}

export interface PurchaseLoan extends BaseLoan {
  purchasePrice: number;
  downPayment: number;
}

export interface RefinanceLoan extends BaseLoan {
  remainingLoanBalance: number;
  cashOut: number;
  propertyValue: number;
}

export type Loan = PurchaseLoan | RefinanceLoan;

export function isRefinance(loan: Loan): loan is RefinanceLoan {
  return 'propertyValue' in loan;
}
