import debounce from 'lodash/debounce';
import { useContext, useLayoutEffect, useState } from 'react';
import { ViewportContext } from '../../viewport-context';

const DEFAULT_MAX_MOBILE_WIDTH = 720;

/**
 *
 * @deprecated This hook is not SSR friendly. Instead, create separate desktop and mobile components
 * that have `display: none` set on screen sizes where they shouldn't show.
 */
export const useIsMobile = (maxMobileWidth = DEFAULT_MAX_MOBILE_WIDTH): boolean => {
  const [mobile, setMobile] = useState(window.innerWidth <= maxMobileWidth);
  useLayoutEffect(() => {
    const onResize = () => {
      setMobile(window.innerWidth <= maxMobileWidth);
    };
    const debouncedResize = debounce(onResize, 1000);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [setMobile, maxMobileWidth]);
  return mobile;
};

export const useIsMobileContext = (maxMobileWidth = DEFAULT_MAX_MOBILE_WIDTH): boolean => {
  const { width } = useContext(ViewportContext);
  return width !== undefined ? width <= maxMobileWidth : false;
};
