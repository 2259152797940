export const singularStates = (n: number) => {
  if (n === 1) {
    return '1 other state';
  } else if (n <= 0) {
    return '0 other states';
  }
  return `${n} other states`;
};

export const getStatesText = (licensedStates: string[], state?: string) => {
  const numStates = licensedStates.length;
  switch (state) {
    case 'DC':
      return `DC and ${singularStates(Math.min(numStates - 1, 50))}`;
    case undefined:
      return '';
    default:
      if (licensedStates.includes('DC')) {
        return `${state}, ${singularStates(Math.min(numStates - 2, 48))}, and DC`;
      }
      return `${state} and ${singularStates(Math.min(numStates - 1, 49))}`;
  }
};
