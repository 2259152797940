import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { reducer as dataReducer } from './data';
import { reducer as generateReducer } from './generate';
import { reducer as loadingReducer } from './loading';
import { reducer as shareReducer } from './share';
import { simulatorReducer } from './simulator/reducer';

export const history = createBrowserHistory();

export const preapprovalsReducer = combineReducers({
  loading: loadingReducer,
  generate: generateReducer,
  share: shareReducer,
  data: dataReducer,
  simulator: simulatorReducer
});

export const reducer = combineReducers({
  router: connectRouter(history),
  preapprovals: preapprovalsReducer
});
