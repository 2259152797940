import { USStateMapping } from '@rategravity/core-lib/enums';
import { createSelector } from 'reselect';
import { getDownPayment } from './generate';
import { PreapprovalsState } from './state';

const preapprovalGeneratorSelector = ({ preapprovals }: { preapprovals: PreapprovalsState }) =>
  preapprovals;

const maxPreapprovalSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { maxPreapprovalAmount } }) => maxPreapprovalAmount
);

const maxLoanValueSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { maxLoanValue } }) => maxLoanValue
);

export const maxValuesSelector = createSelector(
  maxPreapprovalSelector,
  maxLoanValueSelector,
  (maxPreapprovalAmount, maxLoanValue) => ({
    maxPreapprovalAmount,
    maxLoanValue
  })
);

export const loadingSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ loading }) => loading
);

export const advisorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ data: { advisor } }) => advisor
);

const isExpiredSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ data: { isExpired } }) => isExpired
);

const borrowerSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ data: { borrower } }) => borrower
);

export const expiredSelector = createSelector(
  isExpiredSelector,
  advisorSelector,
  borrowerSelector,
  (expired, advisor, name) => ({
    expired,
    advisor,
    name
  })
);

export const errorSelector = createSelector(
  advisorSelector,
  borrowerSelector,
  (advisor, borrower) => ({ advisor, borrower })
);

const generatingSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { generating } }) => generating
);

const preapprovalAmountErrorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { preapprovalAmountError } }) => preapprovalAmountError
);

const downPaymentErrorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { downPaymentError } }) => downPaymentError
);

const loanValueErrorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { loanValueError } }) => loanValueError
);

export const generatingPreapprovalSelector = createSelector(
  isExpiredSelector,
  generatingSelector,
  preapprovalAmountErrorSelector,
  downPaymentErrorSelector,
  loanValueErrorSelector,
  (isExpired, generating, preapprovalAmountError, downPaymentError, loanValueError) => {
    const errors = isExpired || preapprovalAmountError || downPaymentError || loanValueError;
    return {
      show: !errors && generating,
      errors
    };
  }
);

export const successPageSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ data }) => `/preapproval/api/download/${data.preapprovalId}.pdf`
);

export const statusSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ share: { status } }) => status
);

const interestedStatesSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { constraintsByState } }) => Object.keys(constraintsByState)
);

const stateErrorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { stateError } }) => stateError
);

const addressSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { address } }) => address
);

export const addressFormSelector = createSelector(
  interestedStatesSelector,
  stateErrorSelector,
  addressSelector,
  (interestedStates, stateError, address) => ({
    stateOptions: USStateMapping.reduce(
      (acc, { name, abbreviation }) => {
        if (interestedStates.includes(abbreviation)) {
          acc.push({ value: abbreviation, label: name });
        }
        return acc;
      },
      [] as { value: string; label: string }[]
    ),
    stateError,
    address
  })
);

const preapprovalAmountSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { preapprovalAmount } }) => preapprovalAmount || 0
);

export const purchasePriceSelector = createSelector(
  preapprovalAmountSelector,
  preapprovalAmountErrorSelector,
  (preapprovalAmount, error) => ({
    preapprovalAmount,
    error
  })
);

export const downPaymentSelector = createSelector(
  createSelector(preapprovalGeneratorSelector, ({ generate }) => getDownPayment(generate)),
  downPaymentErrorSelector,
  ({ downPaymentAmount, downPaymentPercent }, error) => ({
    downPaymentAmount,
    downPaymentPercent,
    error
  })
);

const loanValueSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { loanValue } }) => loanValue || 0
);

export const loanAmountSelector = createSelector(
  loanValueSelector,
  loanValueErrorSelector,
  (loanAmount, error) => ({
    loanAmount,
    error
  })
);

export const intentSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { intent } }) => intent
);

export const intentErrorSelector = createSelector(
  preapprovalGeneratorSelector,
  ({ generate: { intentError } }) => intentError
);
