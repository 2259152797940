import { event } from '@rategravity/frontend/modules/user-actions';
import { PropsWithTheme } from '@rategravity/own-up-component-library';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useActionPipe } from '../../hooks/use-action-pipe';
import { scrollToElement, scrollToTop } from '../../modules/scroll-to';
import { SideNavLink } from './side-nav-link';

/**
 * This maps the id of the element to how it will be displayed on the nav bar
 */
const idMapping: Record<string, string> = {
  yourAdvisor: 'Your advisor',
  rates: 'Rates',
  progressTracker: 'Your progress',
  resources: 'Resources',
  consultPitch: 'Schedule a call',
  howToPrepare: 'How to prepare',
  questionnaire: 'Questionnaire',
  generalContact: 'Contact'
};

/**
 * Returns an ordered list of links that are present in the current page
 */
export const getLinks = () => {
  const elements = Object.keys(idMapping)
    .map((id) => window.document.getElementById(id))
    .filter((el) => !!el);

  const orderedElements = elements.sort(
    (a, b) => a!.getBoundingClientRect().top - b!.getBoundingClientRect().top
  );
  const links = orderedElements.map((el) => ({
    name: idMapping[el!.id],
    onClick: scrollToElement(el!.id, -80)
  }));
  // 'Home' link replaces the top section
  links.shift();
  links.unshift({ name: 'Home', onClick: scrollToTop });

  // If only 1 link means there is only a single section
  return links.length > 1 ? links : [];
};

const StyledSideNavLink = styled(SideNavLink)`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const NavList = () => {
  const [links, setLinks] = useState<JSX.Element[]>([]);
  const dispatchAction = useActionPipe();

  const observer = useMemo(() => {
    const onClickWrapper = (name: string, onClick: () => void) => () => {
      dispatchAction(event('Side Nav Link Clicked', { link: name }));
      onClick();
    };

    return new MutationObserver(() => {
      const orderedLinks = getLinks();
      setLinks(
        orderedLinks.map((link, index) => (
          <StyledSideNavLink onClick={onClickWrapper(link.name, link.onClick)} key={index}>
            {link.name}
          </StyledSideNavLink>
        ))
      );
    });
  }, [setLinks, dispatchAction]);
  useLayoutEffect(() => {
    observer.observe(document.getElementById('root')!, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [observer]);

  return <React.Fragment>{links}</React.Fragment>;
};
