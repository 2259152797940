import { Action, ActionCreator } from 'redux';
import { InputsState } from './state';

export const UPDATE_INPUTS_ACTION_TYPE = 'INPUTS__UPDATE_ACTION' as const;

export interface UpdateInputsAction extends Action {
  type: typeof UPDATE_INPUTS_ACTION_TYPE;
  payload: Partial<InputsState>;
}

export const updateInputsActionCreator: ActionCreator<UpdateInputsAction> = (
  payload: Partial<InputsState>
) => ({
  type: UPDATE_INPUTS_ACTION_TYPE,
  payload
});

export const UPDATE_HOME_PRICE_ACTION_TYPE = 'HOME_PRICE__UPDATE_ACTION' as const;

export interface UpdateHomePricePayloadType {
  homePrice: number;
  mode: 'inputs' | 'slider' | 'specialConstraints';
}

export interface UpdateHomePriceAction extends Action {
  type: typeof UPDATE_HOME_PRICE_ACTION_TYPE;
  payload: UpdateHomePricePayloadType;
}

export const updateHomePriceActionCreator: ActionCreator<UpdateHomePriceAction> = (
  payload: UpdateHomePricePayloadType
) => ({
  type: UPDATE_HOME_PRICE_ACTION_TYPE,
  payload
});
