import React from 'react';
import * as colors from '../../../colors';

export const DollarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 1C10.4142 1 10.75 1.33579 10.75 1.75V3.05723C11.6238 3.19097 12.4384 3.553 13.0838 4.07857C13.9122 4.75307 14.5 5.73378 14.5 6.875C14.5 7.28921 14.1642 7.625 13.75 7.625C13.3358 7.625 13 7.28921 13 6.875C13 6.27664 12.6932 5.69485 12.1367 5.24174C11.5792 4.78775 10.8092 4.5 10 4.5C8.29287 4.5 7 5.68511 7 6.875C7 7.58415 7.21614 8.14745 7.63354 8.53883C8.05569 8.93466 8.78553 9.25 10 9.25C11.4626 9.25 12.608 9.62921 13.3918 10.3625C14.1809 11.1007 14.5 12.1007 14.5 13.125C14.5 14.2618 13.9115 15.2421 13.0845 15.917C12.4394 16.4434 11.6247 16.8078 10.75 16.9424V18.25C10.75 18.6642 10.4142 19 10 19C9.58579 19 9.25 18.6642 9.25 18.25V16.9411C7.28381 16.6334 5.5 15.1472 5.5 13.125C5.5 12.7108 5.83579 12.375 6.25 12.375C6.66421 12.375 7 12.7108 7 13.125C7 14.3059 8.29462 15.5 10 15.5C10.8078 15.5 11.5777 15.2106 12.1361 14.7549C12.6939 14.2996 13 13.7174 13 13.125C13 12.4097 12.7833 11.8473 12.3671 11.4579C11.9455 11.0635 11.2159 10.75 10 10.75C8.5359 10.75 7.39074 10.3674 6.60753 9.63305C5.81957 8.89421 5.5 7.89501 5.5 6.875C5.5 4.84245 7.28491 3.3645 9.25 3.05858V1.75C9.25 1.33579 9.58579 1 10 1Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
