import { all, put, takeEvery } from 'redux-saga/effects';
import { updateHomePriceActionCreator } from '../inputs/actions';
import { UPDATE_SLIDER_VALUE_ACTION_TYPE, UpdateSliderValueAction } from './actions';

export function* handleUpdateSliderValueAction({
  payload: { sliderValue, mode }
}: UpdateSliderValueAction) {
  if (mode === 'slider') {
    yield put(updateHomePriceActionCreator({ homePrice: Math.round(sliderValue), mode }));
  }
}

export function* sliderConstraintsSaga() {
  yield all([takeEvery(UPDATE_SLIDER_VALUE_ACTION_TYPE, handleUpdateSliderValueAction)]);
}
