import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SliderComponent } from '../../components/simulator/slider';
import {
  updateSliderValueActionCreator,
  UpdateSliderValuePayloadType
} from '../../redux/simulator/slider-constraints/actions';
import { sliderConstraintsSelector } from '../../redux/simulator/slider-constraints/selectors';
import { errorScenarioSelector } from '../../redux/simulator/special-scenario/selectors';

export const ConnectedSlider = () => {
  const dispatch = useDispatch();
  const sliderConstraints = useSelector(sliderConstraintsSelector);
  const disabledSlider =
    useSelector(errorScenarioSelector) ||
    sliderConstraints.sliderMin === sliderConstraints.sliderMax;
  const updateSliderValue = useCallback(
    (payload: UpdateSliderValuePayloadType) => dispatch(updateSliderValueActionCreator(payload)),
    [dispatch]
  );

  return (
    <SliderComponent
      {...{
        ...sliderConstraints,
        disabled: disabledSlider,
        updateSliderValue
      }}
    />
  );
};
