import { event } from '@rategravity/frontend/modules/user-actions';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpIcon
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { Disclosure, DisclosureContent, DisclosureStateReturn } from 'reakit/Disclosure';
import { CSSObject } from 'styled-components';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { useActionPipe } from '../../../../hooks/use-action-pipe';
import { colors } from '../../../../modules/colors';
import { RotatingDiv } from '../rotating-div';
import { MediumGreenText } from '../typography';

export const AccordionWrapperBase = createOwnUpComponent('div', () =>
  createOwnUpStyle({
    border: `.5px solid ${colors.LIGHT_GREY}`,
    borderRadius: '10px',
    backgroundColor: 'inherit',
    width: '100%',
    padding: '16px',
    boxSizing: 'border-box',
    '& button': {
      height: '28px'
    }
  })
);

export type AccordionWrapperProps = React.PropsWithChildren<{
  controller: DisclosureStateReturn;
  action?: string;
  onClick?: () => void;
  style?: CSSObject;
}>;

// Accept controller as an arg even though we don't use it here so that user-defined
// wrappers (see WrapperComponent arg for Accordion) can access it if needed.
export const AccordionWrapper = ({
  children,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  controller,
  style = {}
}: AccordionWrapperProps) => {
  const isMobile = useIsMobile(1024);

  return (
    <AccordionWrapperBase isMobile={isMobile} style={style} onClick={onClick}>
      {children}
    </AccordionWrapperBase>
  );
};

export type AccordionToggleIconProps = {
  controller: DisclosureStateReturn;
  style?: CSSObject;
};
export const AccordionToggleIcon = ({ controller, style = {} }: AccordionToggleIconProps) => (
  <RotatingDiv
    style={{ marginRight: '16px', cursor: 'pointer', ...style }}
    variant={controller.visible ? 'rotateOneEighty' : ''}
  >
    <OwnUpIcon icon="ChevronDown" />
  </RotatingDiv>
);

export type AccordionProps = React.PropsWithChildren<{
  title: string;
  controller: DisclosureStateReturn;
  border?: boolean;
  backgroundColor?: string;
  action?: string;
  WrapperComponent?: ({ children }: React.PropsWithChildren<AccordionWrapperProps>) => JSX.Element;
  IconComponent?: (props: AccordionToggleIconProps) => JSX.Element;
}>;
export const Accordion = ({
  children,
  title,
  border = true,
  action,
  backgroundColor,
  WrapperComponent = AccordionWrapper,
  IconComponent = AccordionToggleIcon,
  controller
}: AccordionProps) => {
  const dispatchAction = useActionPipe();
  const dispatchMixpanelEvent = event('resultsInteraction-PAS', {
    action: action
  });

  const handleClick = () => {
    dispatchAction(dispatchMixpanelEvent);
  };

  return (
    <WrapperComponent
      controller={controller}
      style={{
        backgroundColor: backgroundColor || 'inherit',
        ...(border ? {} : { border: backgroundColor || 'inherit' })
      }}
    >
      <Disclosure
        {...controller}
        onClick={handleClick}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'inherit',
          border: 'none',
          textAlign: 'left',
          padding: 0
        }}
      >
        <IconComponent controller={controller} />
        <MediumGreenText>{title}</MediumGreenText>
      </Disclosure>
      <DisclosureContent {...controller}>
        <div style={{ marginTop: '5px' }}>{children}</div>
      </DisclosureContent>
    </WrapperComponent>
  );
};
