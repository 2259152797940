import { encode } from 'querystring';
import { AnyAction } from 'redux';
import { CallEffect, delay, put } from 'redux-saga/effects';
import { getLoginToken } from './token-storage';

export async function loggedInFetch(
  info: RequestInfo,
  { headers, ...init }: RequestInit = { headers: {} },
  on401 = () => {
    window.location.href = `https://${process.env.DEPLOY_ENV == 'staging' ? 'staging.' : ''}www.ownup.com?${encode(
      {
        redirectPath: window.location.pathname,
        error: 'Expired'
      }
    )}`;
    return new Response(undefined, {
      status: 401,
      statusText: 'You are no longer signed in, redirecting home page'
    });
  }
): Promise<Response> {
  const token = getLoginToken();
  if (token) {
    const authorizedHeaders = new Headers(headers);
    authorizedHeaders.set('Authorization', `Bearer ${token}`);
    authorizedHeaders.append('Accept', 'application/json');
    if (!authorizedHeaders.get('Content-Type')) {
      authorizedHeaders.append('Content-Type', 'application/json');
    }

    const result = await fetch(info, {
      ...init,
      headers: authorizedHeaders
    });

    // don't return expired login tokens
    if (result.status !== 401) {
      return result;
    }
  }

  return on401();
}

interface RetryOptions {
  attempts: number;
}

interface FetchSuccess<TData> {
  success: true;
  data: TData;
}

interface FetchFailure<TError> {
  success: false;
  data: TError;
}

type FetchReturn<TData, TError> = FetchSuccess<TData> | FetchFailure<TError>;

export function* fetchWithRetry<TData, TError>(
  fetchFn: () => Generator<CallEffect<unknown>, FetchReturn<TData, TError>>,
  onSuccess: (params?: TData) => AnyAction,
  onError: (params?: TError) => AnyAction,
  { attempts }: RetryOptions = {
    attempts: 10
  }
) {
  try {
    for (let retry = 0; retry <= attempts; retry++) {
      const resp: FetchReturn<TData, TError> = yield fetchFn();
      if (resp.success) {
        yield put(onSuccess(resp.data));
        break;
      } else if (retry < attempts) {
        // back-off
        yield delay(100 * Math.pow(1.2, retry));
      } else {
        // last iteration (retry === 10)
        throw new Error(`10 attempts at polling have failed ${JSON.stringify(resp.data)}`);
      }
    }
  } catch (err) {
    console.error('Error fetching:', err);
    yield put(onError(err as TError));
  }
}
