import { Action, ActionCreator } from 'redux';
import { SpecialScenarioState } from './state';

export const UPDATE_SPECIAL_SCENARIO_ACTION_TYPE = 'SPECIAL_SCENARIO__UPDATE_ACTION' as const;

export interface UpdateSpecialScenarioAction extends Action {
  type: typeof UPDATE_SPECIAL_SCENARIO_ACTION_TYPE;
  payload: SpecialScenarioState;
}

export const updateSpecialScenarioActionCreator: ActionCreator<UpdateSpecialScenarioAction> = (
  payload: SpecialScenarioState
) => ({
  type: UPDATE_SPECIAL_SCENARIO_ACTION_TYPE,
  payload
});
