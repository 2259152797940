export const formatCurrency = (
  value: number,
  returnNegative: boolean = true,
  fractionDigits: number = 0
) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  }).format(returnNegative ? value : Math.abs(value));
