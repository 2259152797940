import React, { Fragment } from 'react';
import { FullAddressProps } from '../../../../components/address/address-helpers';
import { Header } from '../header';
import { CountyDropdown } from './state-county';
import { GridContainer, HalfWidthGridItem } from './util';

export const CountyGrid = ({
  addressModel,
  counties,
  onCountyChange
}: Required<Pick<FullAddressProps, 'addressModel' | 'counties' | 'onCountyChange'>>) => (
  <Fragment>
    <Header
      header={'What county is the property located in?'}
      body={
        'Your offer is more likely to be accepted and your preapproval letter will be more accurate if you specify the county.'
      }
    />
    <GridContainer>
      <HalfWidthGridItem>
        <CountyDropdown
          address={addressModel}
          countyOptions={counties}
          disabled={!addressModel.state}
          onChange={(_, selectedValue) => {
            onCountyChange(selectedValue || undefined);
          }}
        />
      </HalfWidthGridItem>
    </GridContainer>
  </Fragment>
);
