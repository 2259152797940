import { parse } from 'querystring';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LoadAccountPayload } from '../redux/actions/load-account';
import { ManifestResponse } from './account';

/**
 * Hook to fetch a `waitFor` configuration from the URL query string
 *   that can be used to dictate whether or not we consider the manifest
 *   to have been fully loaded.
 */
export const useWaitFor = (): LoadAccountPayload['waitFor'] => {
  const { search } = useLocation();
  return useMemo(() => {
    if (!search) {
      return undefined;
    }
    const {
      'wait-for': manifestField,
      'wait-for-key': manifestValueKey,
      'wait-for-value': manifestValueValue
    } = parse(search.substring(1));
    if (!manifestField) {
      return undefined;
    }
    return {
      manifestField,
      ...(manifestValueValue
        ? { manifestValue: { key: manifestValueKey, value: manifestValueValue } }
        : {})
    } as LoadAccountPayload['waitFor'];
  }, [search]);
};

/**
 * Based on the `waitFor` configuration, determine if the manifest load has
 *   completed. If the manifest does not match the predicates defined by
 *   the `waitFor` configuration, this function will return false.
 */
export const isWaitForComplete = (
  { manifestField, manifestValue }: Exclude<LoadAccountPayload['waitFor'], undefined>,
  json: ManifestResponse
) => {
  try {
    // Get the value on the manifest matching `manifestField`
    const field = json[manifestField];
    // If the `manifestValue` is defined...
    if (manifestValue) {
      // ... if a `key` is given...
      if (manifestValue.key) {
        // ... return true if the value at `field.key` matches `value`
        return (field as any)[manifestValue.key] === manifestValue.value;
      }
      // ... if no key given, return true if the field value matches `value`
      return field === manifestValue.value;
    }
    // ... if no `manifestValue` is given, return true if the field is truthy
    return !!field;
  } catch (e) {
    // ... suppress error
  }
};
