import { none, of, Optional } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState, LoadedRate, Offer } from '../state';
import { currentPathSelector } from './path';

export const rateQuoteIdSelector = createSelector(
  currentPathSelector,
  (path): string | undefined => path?.split('rates/')[1]?.split('/')[0]
);

export const rateQuoteSelector = createSelector(
  (state: ApplicationState) => state?.rates?.allRates,
  rateQuoteIdSelector,
  (allRates, id): Optional<LoadedRate> =>
    allRates && id
      ? (of(allRates[id]).filter(
          ({ loading, error }) => !loading && !error
        ) as Optional<LoadedRate>)
      : none()
);

const offerIdSelector = createSelector(
  currentPathSelector,
  (path) => path?.split(/details\/|lock\//)[1]?.split('/')[0]
);

// Note, don't use createSelector here since the offerId
// parameter would defeat memoization when accessing 2 offers, instead just
// make the function that invokes this memoize.
export const offerByIdSelector = (
  rateQuote: Optional<LoadedRate>,
  offerId?: string
): Optional<Offer> => {
  return rateQuote.flatMap(({ offerPermutations }) =>
    offerPermutations.reduce((acc: Optional<Offer>, curr) => {
      if (!acc.isSome()) {
        return of(curr.offers.find(({ id }) => id === offerId));
      }
      return acc;
    }, none<Offer>())
  );
};

export const offerSelector = createSelector(
  rateQuoteSelector,
  offerIdSelector,
  (rateQuote, offerId) => offerByIdSelector(rateQuote, offerId)
);
