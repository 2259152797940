import { currency } from '@rategravity/frontend/modules/numbers';
import { FeeValue } from '../redux/state';

/**
 * Used for computations with fee values. Makes Unknown fees 0.
 * @param v Fee Value
 */
export const resolveFeeValue = (v: FeeValue): number => (typeof v === 'number' ? v : 0);

/**
 * Used to display fee values. Displays Unknown fees as -- and regular values with currency
 * @param v Fee Value
 */
export const displayFeeValue = (v: FeeValue): string =>
  typeof v === 'number' ? currency(v, 0) : '--';
