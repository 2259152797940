// Optionals
// Optionals present an "array like" interface (map, filter, find, some) however
// they effectively have either 1 (Some) or 0 (None) elements.
// Optionals are highly useful to express the return type of functions that may not return.
// Specifically search / lookup operations work great with Optionals.
// The 2 advantages they have over using a type union with undefined is that.
// A) You don't need to check if a value is undefined when chaining operations.
// B) You can express a successful operation that returns undefined vs. a failure
// Combinators (all, coalesce) are provided to make it easier to work with Optionals.
// lazy constructors can be used to create Optionals that only evaluate when their data is needed.
// especially in combination with coalesce this allows defining an order of
// functions that should be executed to fetch a value. eg:
// coalesce([lazy(() => store.getA()), lazy(() => store.getB())]).map(v => ....);

/**
 * FlattenTypes, When calling flat() the result is to recursively flatten up-to depth
 *  However it's not possible to express that in Typescript because Recursive Types don't work
 *  Using conditional types we can express that each level either unwraps or doesn't
 */
export type FlattenOptional1<T> =
  T extends Some<infer TValue>
    ? Some<TValue>
    : T extends None<infer TValue1>
      ? None<TValue1>
      : T extends Optional<infer TValue2>
        ? Optional<TValue2>
        : Optional<T>;
export type FlattenSome1<T> =
  T extends Some<infer TValue>
    ? Some<TValue>
    : T extends None<infer TValue1>
      ? None<TValue1>
      : T extends Optional<infer TValue2>
        ? Optional<TValue2>
        : Some<T>;
export type FlattenNone1<T> =
  T extends Some<infer TValue>
    ? None<TValue>
    : T extends None<infer TValue1>
      ? None<TValue1>
      : T extends Optional<infer TValue2>
        ? None<TValue2>
        : None<T>;
export type FlattenOptional2<T> =
  T extends Some<infer TValue>
    ? FlattenSome1<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone1<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional1<TValue2>
        : Optional<T>;
export type FlattenSome2<T> =
  T extends Some<infer TValue>
    ? FlattenSome1<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone1<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional1<TValue2>
        : Some<T>;
export type FlattenNone2<T> =
  T extends Some<infer TValue>
    ? FlattenNone1<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone1<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenNone1<TValue2>
        : None<T>;
export type FlattenOptional3<T> =
  T extends Some<infer TValue>
    ? FlattenSome2<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone2<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional2<TValue2>
        : Optional<T>;
export type FlattenSome3<T> =
  T extends Some<infer TValue>
    ? FlattenSome2<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone2<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional2<TValue2>
        : Some<T>;
export type FlattenNone3<T> =
  T extends Some<infer TValue>
    ? FlattenNone2<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone2<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenNone2<TValue2>
        : None<T>;
export type FlattenOptional4<T> =
  T extends Some<infer TValue>
    ? FlattenSome3<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone3<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional3<TValue2>
        : Some<T>;
export type FlattenSome4<T> =
  T extends Some<infer TValue>
    ? FlattenSome3<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone3<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional3<TValue2>
        : Some<T>;
export type FlattenNone4<T> =
  T extends Some<infer TValue>
    ? FlattenNone3<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone3<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenNone3<TValue2>
        : None<T>;
export type FlattenSome5<T> =
  T extends Some<infer TValue>
    ? FlattenSome4<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone4<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenOptional4<TValue2>
        : Some<T>;
export type FlattenNone5<T> =
  T extends Some<infer TValue>
    ? FlattenNone4<TValue>
    : T extends None<infer TValue1>
      ? FlattenNone4<TValue1>
      : T extends Optional<infer TValue2>
        ? FlattenNone4<TValue2>
        : None<T>;

/**
 * Some<T> represents the presence of something.
 * Roughly Matches the interface of the Array
 */
export interface Some<T> extends Iterable<T> {
  readonly value: T;
  some(): true;
  some(predicate: (v: T) => boolean): boolean;
  filter(predicate: (v: T) => boolean): Optional<T>;
  map<TValue>(selector: (v: T) => TValue): Optional<TValue>;
  flatMap<TValue>(selector: (v: T) => Optional<TValue>): Optional<TValue>;
  flat(depth?: 1): FlattenSome1<T>;
  flat(depth: 2): FlattenSome2<T>;
  flat(depth: 3): FlattenSome3<T>;
  flat(depth: 4): FlattenSome4<T>;
  flat(depth: 5): FlattenSome5<T>;
  flat(depth?: number): Optional<any>;
  orElse(defaultSelector: () => T): T;
  find(): T;
  find(predicate: (v: T) => boolean): T | undefined;
  run(code: (v: T) => void): void;
  isSome(): this is Some<T>;
  toString(): string;
}

/**
 * None<T> represents the lack of presence of something.
 */
export interface None<T> extends Iterable<T> {
  readonly value: undefined;
  some(predicate?: (v: T) => boolean): false;
  filter(predicate: (v: T) => boolean): None<T>;
  map<TValue>(selector: (v: T) => TValue): Optional<TValue>;
  flatMap<TValue>(selector: (v: T) => Optional<TValue>): Optional<TValue>;
  flat(depth?: 1): FlattenNone1<T>;
  flat(depth: 2): FlattenNone2<T>;
  flat(depth: 3): FlattenNone3<T>;
  flat(depth: 4): FlattenNone4<T>;
  flat(depth: 5): FlattenNone5<T>;
  flat(depth?: number): None<any>;
  orElse(defaultSelector: () => T): T;
  find(predicate?: (v: T) => boolean): undefined;
  run(code: (v: T) => void): void;
  isSome(): false;
  toString(): string;
}

/**
 * Optional<T> is either Some<T> or None<T>;
 */
export type Optional<T> = Some<T> | None<T>;

/**
 * Check if the value is an Optional
 * @param value anything
 */
export const isOptional = (value: unknown): value is Optional<unknown> =>
  value != null &&
  typeof value === 'object' &&
  'value' in value! &&
  'isSome' in value &&
  'orElse' in value &&
  'run' in value &&
  'some' in value &&
  'filter' in value &&
  'map' in value &&
  'flatMap' in value &&
  'flat' in value &&
  'find' in value;

// constant NONE since the behavior isn't type specific
const NONE: None<any> = Object.defineProperties(
  {
    value: undefined,
    some: () => false,
    filter: () => NONE,
    map: <TValue>(_: (v: any) => TValue) => NONE,
    flatMap: <TValue>(_: (v: any) => Optional<TValue>) => NONE,
    flat: () => NONE,
    orElse: (defaultSelector: () => any) => defaultSelector(),
    find: () => undefined,
    run: () => {
      // noop
    },
    isSome: () => false,
    toString: () => 'None',
    [Symbol.iterator]: () => ({
      next: () => ({ done: true, value: undefined })
    })
  },
  {
    value: {
      configurable: false,
      enumerable: true,
      value: undefined,
      writable: false
    }
  }
);

/**
 * create a None, the function
 * returns a cached constant but is used
 * to enable the type of the Optional to be specified
 */
export const none = <T>(): None<T> => NONE;

/**
 * Given a value return a Some capturing that value.
 * If the value is undefined the result will be a Some<undefined>
 */
export const some = <T>(value: T): Some<T> =>
  Object.defineProperties(
    {
      some: (predicate: (v: T) => boolean = () => true) => predicate(value),
      filter: (predicate: (v: T) => boolean) => (predicate(value) ? some(value) : none<T>()),
      map: <TValue>(selector: (v: T) => TValue) => some(selector(value)),
      flatMap: <TValue>(selector: (v: T) => Optional<TValue>) => selector(value),
      flat: (depth: number = 1) => {
        let r: Optional<unknown> = some(value);
        for (let i = 0; i < depth; i++) {
          // none is always none
          if (!r.isSome()) {
            break;
          }
          // grab the value inside the some
          const v = r.find();
          // check if the value is an optional
          if (isOptional(v)) {
            r = v as Optional<unknown>;
          } else {
            // no further flattening
            break;
          }
        }
        return r;
      },
      orElse: (_: () => T) => value,
      find: (predicate: (v: T) => boolean = () => true) => (predicate(value) ? value : undefined),
      run: (code: (v: T) => void) => {
        code(value);
      },
      isSome: () => true,
      toString: () => `Some[${String(value)}]`,
      [Symbol.iterator]: () => {
        let done = false;
        return {
          next: () => {
            if (done) {
              return { done: true };
            } else {
              done = true;
              return { value, done: false };
            }
          }
        };
      }
    },
    {
      value: {
        configurable: false,
        enumerable: true,
        value,
        writable: false
      }
    }
  ) as Some<T>;

export interface All {
  <TValue1>(options: [Optional<TValue1>]): Optional<[TValue1]>;
  <TValue1, TValue2>(options: [Optional<TValue1>, Optional<TValue2>]): Optional<[TValue1, TValue2]>;
  <TValue1, TValue2, TValue3>(
    options: [Optional<TValue1>, Optional<TValue2>, Optional<TValue3>]
  ): Optional<[TValue1, TValue2, TValue3]>;
  <TValue1, TValue2, TValue3, TValue4>(
    options: [Optional<TValue1>, Optional<TValue2>, Optional<TValue3>, Optional<TValue4>]
  ): Optional<[TValue1, TValue2, TValue3, TValue4]>;
  <TValue1, TValue2, TValue3, TValue4, TValue5>(
    options: [
      Optional<TValue1>,
      Optional<TValue2>,
      Optional<TValue3>,
      Optional<TValue4>,
      Optional<TValue5>
    ]
  ): Optional<[TValue1, TValue2, TValue3, TValue4, TValue5]>;
  <TValue1, TValue2, TValue3, TValue4, TValue5, TValue>(
    options: [
      Optional<TValue1>,
      Optional<TValue2>,
      Optional<TValue3>,
      Optional<TValue4>,
      Optional<TValue5>,
      ...Optional<TValue>[]
    ]
  ): Optional<[TValue1, TValue2, TValue3, TValue4, TValue5, ...TValue[]]>;
}

/**
 * Given an array of Optionals return an Optional of an array.
 * The returned optional will be Some if all the optionals are Some
 * The returned optional will be None if any of the optionals are None
 */
export const all: All = ((options: Optional<any>[]) =>
  options.every((o) => o.isSome())
    ? some(options.map((o) => (o as Some<any>).find()))
    : none<any>()) as any; // this doesn't typecheck cleanly but it's fine...

/**
 * Given an array of Optionals return a Optional with the first
 * present value. If all of the optionals are None then the returned value will be None
 * Otherwise the returned value will be the first Some
 */
export const coalesce = <TValue>(
  options: [Optional<TValue>, ...Optional<TValue>[]]
): Optional<TValue> => options.find((o) => o.isSome()) || none<TValue>();

export const coallesce = coalesce;

/**
 * Given a value that is potentially null or undefined return an Optional
 * @param value A value, possibly null or undefined
 * The returned Optional will be None if the value is null or undefined otherwise it will be Some
 */
export const of = <TValue>(value: TValue | undefined | null): Optional<TValue> =>
  value === undefined || value === null ? none<TValue>() : some<TValue>(value);

/**
 * Given a generator function create an optional
 * @param generator A function to generate an Optional
 * The returned Optional chains operations until a terminal operation is invoked
 * then invokes the generator once to determine the result.
 * A Terminal operation is anything that doesn't return an Optional.
 */
export const lazy = <T>(generator: () => Optional<T>): Optional<T> => {
  let cachingGenerator = () => {
    const v = generator();
    cachingGenerator = () => v;
    return v;
  };
  return Object.defineProperties(
    {
      some: (predicate?: (v: T) => boolean) => cachingGenerator().some(predicate),
      filter: (predicate: (v: T) => boolean) => lazy(() => cachingGenerator().filter(predicate)),
      map: <TValue>(selector: (v: T) => TValue): Optional<TValue> =>
        lazy<TValue>(() => cachingGenerator().map(selector)),
      flatMap: <TValue>(selector: (v: T) => Optional<TValue>) =>
        lazy<TValue>(() => cachingGenerator().flatMap(selector)),
      flat: (depth?: number) => lazy(() => cachingGenerator().flat(depth)),
      orElse: (defaultSelector: () => T) => cachingGenerator().orElse(defaultSelector),
      find: (predicate?: (v: T) => boolean) => cachingGenerator().find(predicate),
      run: (code: (v: T) => void) => cachingGenerator().run(code),
      isSome: () => cachingGenerator().isSome(),
      toString: () => cachingGenerator().toString(),
      [Symbol.iterator]: () => cachingGenerator()[Symbol.iterator]()
    },
    {
      value: {
        configurable: false,
        enumerable: true,
        get: () => cachingGenerator().value
      }
    }
  ) as Optional<T>;
};

/**
 * Given a generator that may return null or undefined returns
 * a lazy Optional where the value will be None if the generator returned null or undefined.
 */
export const lazyOf = <TValue>(generator: () => TValue | undefined | null): Optional<TValue> =>
  lazy(() => of(generator()));
