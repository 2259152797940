import { pmiTable } from './pmi-table';

export const lookupPMIRate = (
  percentDownPayment: number,
  approximateCreditScore: number,
  loanTerm?: number
) => {
  const ratesForCreditScore = pmiTable[approximateCreditScore];

  let minimumDownPaymentRates = null;
  if (ratesForCreditScore) {
    // Extract an array of all the down payment rates/keys for the given creditScore
    minimumDownPaymentRates = Object.keys(ratesForCreditScore);

    // Check current percentDownPayment against minimumDownPaymentPercentage keys,
    // returning the largest minimumDownPaymentPercentage that is less than percentDownPayment.
    // This assumes that the minimumDownPaymentPercentage keys are sorted highest to lowest in the pmi table.
    const rateForMinDownPaymentPercent = parseFloat(
      minimumDownPaymentRates.filter((rate) => percentDownPayment >= parseFloat(rate))[0]
    );

    const fixedRate = loanTerm && loanTerm <= 20 ? 20 : 30;
    const annualPMIRate = pmiTable[approximateCreditScore][rateForMinDownPaymentPercent][fixedRate];

    return annualPMIRate;
  }

  return null;
};
