import {
  OwnUpUrgentErrorNotification,
  OwnUpUrgentSuccessNotification
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { AnimatedHourglassWrapper } from '../../../components/icons/hourglass-icon';

export const SuccessNotice = () => (
  <OwnUpUrgentSuccessNotification
    title="All set!"
    description="We emailed your preapproval letter to your realtor."
    $hasIcon={true}
  />
);

export const ErrorNotice = () => (
  <OwnUpUrgentErrorNotification
    title="We're sorry!"
    description="We weren’t able to email your realtor."
    $hasIcon={true}
  />
);

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Loading = (
  <LoadingWrapper>
    <AnimatedHourglassWrapper />
    <div style={{ marginLeft: '24px' }}>Sending...</div>
  </LoadingWrapper>
);

export const LoadingNotice = () => (
  <OwnUpUrgentSuccessNotification title={Loading} description="" $hasIcon={false} />
);
