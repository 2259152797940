import { event } from '@rategravity/frontend/modules/user-actions';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useDisclosureState } from 'reakit/Disclosure';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { useActionPipe } from '../../../../hooks/use-action-pipe';
import { colors } from '../../../../modules/colors';
import { updateInputsActionCreator } from '../../../redux/simulator/inputs/actions';
import {
  UpdateHomePriceAction,
  UpdateHomePricePayloadType,
  UpdateInputsAction
} from '../../../redux/simulator/inputs/actions';
import { DEFAULT_INPUTS_STATE } from '../../../redux/simulator/inputs/state';
import { InputsState } from '../../../redux/simulator/inputs/state';
import { CreditScoreDropdown } from '../../../views/simulator/credit-score-dropdown';
import { Accordion } from '../accordion';
import { Button } from '../button';
import { HomePriceDownPaymentCombo } from '../home-price-downpayment-combo';
import {
  AnnualIncomeInput,
  AnnualInterestRateInput,
  AnnualPropertyTaxRateInput,
  MonthlyDebtInput,
  MonthlyFeeInput,
  MonthlyHomeownersInsuranceInput
} from '../inputs';
import { creditScores } from '../inputs/dropdown/data/sample-dropdown-options';
import { WithTooltip } from '../tooltip';
import { InputLabelText, RegularDarkText } from '../typography';

const tooltipCopy = {
  homePrice:
    'The price of the home for preapproval. The 3 most important factors that impact this number are loan-to-value (LTV) ratio, debt-to-income (DTI) ratio, and credit score.',
  downPayment:
    'The initial portion of the home price that is required at closing. This excludes closing costs, which are assessed separately.',
  annualInterestRate:
    'The cost of borrowing money typically expressed as a %. In this tool, loan payments are amortized over a 30 year period (i.e. using a 30 yr fixed mortgage).',
  annualIncome:
    "Your annual household income, including yours and your co-borrowers' if applicable, before taxes. Consult your advisor to discuss types of income that apply.",
  monthlyDebt:
    "This only includes the minimum amount you're required to pay each month towards things like credit cards, car loans, personal loans, student loans or child support. Decreasing debt is related to a lower debt-to-income (DTI) ratio, which increases the value of the loan for which you can get preapproved.",
  creditScore:
    'The minimum credit score between you and your co-borrower is used to calculate mortgage insurance if it applies to your loan.',
  annualPropertyTaxRate:
    "This is the tax you are required to pay by your city or municipality, based on a percentage of the home's value. Usually, an escrow account is used to help set this money aside on a monthly basis.",
  monthlyFee:
    "These are dues paid to a group like a homeowner's association or condo board. Not applicable to all borrowers.",
  monthlyHomeownersInsurance:
    'This is the premium for insurance to cover damage to your property and the things you keep in it. The cost depends on the location of your home and the value of the home and the things in it. Usually, an escrow account is used to help set this money aside on a monthly basis.'
};

const FormWrapper = createOwnUpComponent('section', ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    width: '310px',
    ...(isMobile ? { boxSizing: 'border-box' } : {}),
    variants: {
      mediumAndDown: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto'
      }
    }
  })
);

const AccordionWrapper = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    paddingTop: '12px'
  })
);

const CustomAdvancedOptionsWrapper = createOwnUpComponent(
  'form',
  createOwnUpStyle({
    border: `.5px solid ${colors.LIGHT_GREY}`,
    borderRadius: '10px',
    backgroundColor: 'inherit',
    padding: '16px',
    '& button': {
      height: '28px'
    },
    width: '278px',
    variants: {
      smallAndDown: {
        width: '100%',
        boxSizing: 'border-box'
      }
    }
  })
);

const LearnMoreLink = createOwnUpComponent(
  'a',
  createOwnUpStyle({
    color: 'inherit !important',
    textDecoration: 'underline'
  })
);

const HomePriceLabel = (
  <WithTooltip content={tooltipCopy['homePrice']} name="home price">
    <InputLabelText htmlFor="homePrice" variant="withTooltip">
      Home price
    </InputLabelText>
  </WithTooltip>
);
const DownPaymentLabel = (
  <WithTooltip content={tooltipCopy['downPayment']} name="down payment">
    <InputLabelText htmlFor="downPayment" variant="withTooltip">
      Down payment
    </InputLabelText>
  </WithTooltip>
);

interface FormProps {
  homePrice: number;
  downPayment: number;
  annualIncome: number;
  monthlyDebt: number;
  approximateCreditScore: number;
  annualInterestRate: number;
  annualPropertyTaxRate: number;
  monthlyFee: number;
  monthlyHomeownersInsurance: number;
  updateInputs: (payload: Partial<InputsState>) => UpdateInputsAction;
  updateHomePrice: (payload: UpdateHomePricePayloadType) => UpdateHomePriceAction;
}

export const FormComponent = ({
  homePrice,
  downPayment,
  approximateCreditScore,
  updateHomePrice,
  updateInputs
}: FormProps) => {
  const isMobile = useIsMobile(1024);
  const controller = useDisclosureState({ visible: false });
  const dispatch = useDispatch();

  const dispatchAction = useActionPipe();
  const learnMoreLink = event('clickedCreditScoreEducationLink-PAS');

  return (
    <FormWrapper isMobile={isMobile} aria-label="your scenario form">
      <HomePriceDownPaymentCombo
        homePriceLabel={HomePriceLabel}
        downPaymentLabel={DownPaymentLabel}
        homePrice={homePrice}
        downPayment={downPayment}
        updateHomePrice={updateHomePrice}
        updateDownPayment={updateInputs}
        reverse={true}
      />
      <WithTooltip content={tooltipCopy['annualInterestRate']} name="annual interest rate">
        <InputLabelText htmlFor="annualInterestRate" variant="withTooltip">
          Interest rate
        </InputLabelText>
      </WithTooltip>
      <AnnualInterestRateInput />
      <WithTooltip content={tooltipCopy['annualIncome']} name="annual income">
        <InputLabelText htmlFor="annualIncome" variant="withTooltip">
          Annual income
        </InputLabelText>
      </WithTooltip>
      <AnnualIncomeInput />
      <WithTooltip content={tooltipCopy['monthlyDebt']} name="monthly debt">
        <InputLabelText htmlFor="monthlyDebt" variant="withTooltip">
          Monthly debts
        </InputLabelText>
      </WithTooltip>
      <MonthlyDebtInput />
      <WithTooltip content={tooltipCopy['creditScore']} name="credit score">
        <InputLabelText variant="withTooltip" htmlFor="creditScoreLabel">
          Credit score
        </InputLabelText>
      </WithTooltip>
      <CreditScoreDropdown
        options={creditScores}
        value={approximateCreditScore}
        onChange={updateInputs}
        labelId={'creditScoreLabel'}
      />
      {approximateCreditScore < 700 && homePrice - downPayment > 548250 && (
        <RegularDarkText>
          Our lenders require a credit score of at least 700 to secure a Jumbo loan.{' '}
          <LearnMoreLink
            target="_blank"
            onClick={() => dispatchAction(learnMoreLink)}
            href="https://resources.ownup.com/credit-scores-for-mortgages-what-you-need-to-get-approved"
          >
            Learn more about your credit score affects your mortgage.
          </LearnMoreLink>
        </RegularDarkText>
      )}
      <AccordionWrapper>
        <Accordion
          title={`${controller.visible ? 'Hide' : 'Show'} advanced options`}
          controller={controller}
          WrapperComponent={CustomAdvancedOptionsWrapper}
        >
          <div style={{ paddingTop: '12px' }}>
            <WithTooltip
              content={tooltipCopy['annualPropertyTaxRate']}
              name="annual property tax rate"
            >
              <InputLabelText htmlFor="annualPropertyTaxRate" variant="withTooltip">
                Annual property taxes
              </InputLabelText>
            </WithTooltip>
            <AnnualPropertyTaxRateInput />
            <WithTooltip content={tooltipCopy['monthlyFee']} name="monthly fee">
              <InputLabelText htmlFor="monthlyFee" variant="withTooltip">
                Monthly HOA/condo fees
              </InputLabelText>
            </WithTooltip>
            <MonthlyFeeInput />
            <WithTooltip
              content={tooltipCopy['monthlyHomeownersInsurance']}
              name="monthly home owners insurance"
            >
              <InputLabelText htmlFor="monthlyHomeownersInsurance" variant="withTooltip">
                Monthly homeowners insurance
              </InputLabelText>
            </WithTooltip>
            <MonthlyHomeownersInsuranceInput />
          </div>
        </Accordion>
      </AccordionWrapper>
      <Button
        style={{ marginTop: '15px' }}
        onClick={() => {
          dispatch(updateInputsActionCreator({ ...DEFAULT_INPUTS_STATE }));
        }}
      >
        Return to defaults
      </Button>
    </FormWrapper>
  );
};
