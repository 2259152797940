import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpIcon
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { colors } from '../../../../../modules/colors';
import { UpdateInputsAction } from '../../../../redux/simulator/inputs/actions';
import { InputsState } from '../../../../redux/simulator/inputs/state';

export interface DropdownProps {
  options: { value: number; display: string | JSX.Element }[];
  value: number | undefined;
  onChange: (payload: Partial<InputsState>) => UpdateInputsAction;
  labelId?: string;
}

const ChevronDownStyle = createOwnUpStyle({
  position: 'absolute',
  right: '20px',
  marginBottom: '15px',
  display: 'inline-block',
  pointerEvents: 'none'
});
export const DropDownToggleIcon = createOwnUpComponent(OwnUpIcon, ChevronDownStyle);

const BootstrapInput = withStyles(() =>
  createStyles({
    input: {
      position: 'relative',
      display: 'flex',
      height: '60px',
      margin: '16px 0',
      padding: '0 0 0 20px',
      justifyContent: 'space-between',
      border: `1px solid ${colors.GREY}`,
      borderRadius: '3px',
      fontSize: '15px',
      ...fonts.GRAPHIK_REGULAR,
      alignItems: 'center',
      // ensures that the background color of the dropdown remains white when an element is selected and focus
      // (which normally gives the dropdown a gray background) is applied to a dropdown item
      backgroundColor: `${colors.WHITE} !important`,
      color: colors.PRIMARY,
      '&:focus': {
        border: `solid 1px ${colors.PRIMARY}`,
        outline: 0
      }
    }
  })
)(InputBase);

const useStyles = makeStyles(() =>
  createStyles({
    dropdownClass: {
      display: 'flex',
      position: 'relative',
      overflow: 'auto',
      width: '100%',
      height: '60px',
      padding: 0,
      paddingLeft: '20px',
      fontSize: '15px',
      color: colors.PRIMARY,
      backgroundColor: colors.WHITE,
      margin: 0,
      '&:focus': {
        border: `solid 1px ${colors.DARK_GREY}`,
        backgroundColor: colors.BACKGROUND,
        outline: 0,
        // overrides default MaterialUI styling for background color of selected item
        '&.Mui-selected': {
          backgroundColor: colors.BACKGROUND
        }
      },
      '&:hover': {
        backgroundColor: colors.BACKGROUND,
        '&.Mui-selected': {
          // overrides default MaterialUI styling for background color of selected item
          backgroundColor: colors.BACKGROUND
        }
      },
      '&.Mui-selected': {
        backgroundColor: colors.WHITE
      }
    },
    ulDropdownClass: {
      padding: 0,
      border: `solid 1px ${colors.GREY}`,
      boxShadow: 'none'
    }
  })
);

export function Dropdown({ options, value, onChange, labelId }: DropdownProps) {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (typeof event.target.value == 'number') {
      onChange({ approximateCreditScore: event.target.value });
    }
  };
  const dropDownOptions = options.map((option, i) => {
    return (
      <MenuItem key={`dd-${i}`} value={option.value} className={classes.dropdownClass}>
        {option.display}
      </MenuItem>
    );
  });

  return (
    <FormControl fullWidth={true}>
      <Select
        IconComponent={() => <DropDownToggleIcon icon="ChevronDown" strokeWidth={1} />}
        value={value}
        onChange={handleChange}
        input={<BootstrapInput id={labelId} />}
        autoWidth={false}
        defaultValue={value}
        MenuProps={{
          classes: {
            // MaterialUI's select component implements dropdown options with Paper, a component that mimicks the styling of paper. Pass in ulDropdownClass styling to paper to remove default box shadows and reposition dropdown options
            paper: classes.ulDropdownClass
          }
        }}
      >
        {dropDownOptions}
      </Select>
    </FormControl>
  );
}
