import { createSelector } from 'reselect';
import { ApplicationState } from '../../../../redux/state';

export const monthlyPaymentSelector = ({
  preapprovals: {
    simulator: {
      monthlyPayment: {
        monthlyPAndI,
        monthlyPropertyTax,
        monthlyHomeownersInsurance,
        monthlyFee,
        monthlyPMI
      }
    }
  }
}: ApplicationState) => ({
  monthlyPAndI: monthlyPAndI || 0,
  monthlyPropertyTax: monthlyPropertyTax || 0,
  monthlyHomeownersInsurance: monthlyHomeownersInsurance || 0,
  monthlyFee: monthlyFee || 0,
  monthlyPMI: monthlyPMI || 0
});

export const monthlyPaymentValueSelector = createSelector(
  monthlyPaymentSelector,
  (monthlyPayment) => {
    return Object.values(monthlyPayment)
      .map(Number)
      .reduce((sum, key) => sum + key, 0);
  }
);
