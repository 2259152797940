import { Action, ActionCreator } from 'redux';
import { SliderConstraintsState } from './state';

export const UPDATE_SLIDER_CONSTRAINTS_ACTION_TYPE = 'SLIDER_CONSTRAINTS__UPDATE_ACTION' as const;

export interface UpdateSliderConstraintsAction extends Action {
  type: typeof UPDATE_SLIDER_CONSTRAINTS_ACTION_TYPE;
  payload: Partial<SliderConstraintsState>;
}

export const updateSliderConstraintsActionCreator: ActionCreator<UpdateSliderConstraintsAction> = (
  payload: Partial<SliderConstraintsState>
) => ({
  type: UPDATE_SLIDER_CONSTRAINTS_ACTION_TYPE,
  payload
});

export const UPDATE_SLIDER_VALUE_ACTION_TYPE = 'SLIDER_VALUE__UPDATE_ACTION' as const;

export interface UpdateSliderValuePayloadType {
  sliderValue: number;
  mode: 'inputs' | 'slider';
}

export interface UpdateSliderValueAction extends Action {
  type: typeof UPDATE_SLIDER_VALUE_ACTION_TYPE;
  payload: UpdateSliderValuePayloadType;
}

export const updateSliderValueActionCreator: ActionCreator<UpdateSliderValueAction> = (
  payload: UpdateSliderValuePayloadType
) => ({
  type: UPDATE_SLIDER_VALUE_ACTION_TYPE,
  payload
});
