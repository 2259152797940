import {
  colors,
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpNumberInput
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { updateInputsActionCreator } from '../../../redux/simulator/inputs/actions';
import { inputsSelector } from '../../../redux/simulator/inputs/selectors';
import { InputsState } from '../../../redux/simulator/inputs/state';

export const InputWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile: boolean }) =>
  createOwnUpStyle({
    display: 'flex',
    justifyContent: 'space-between',
    width: '310px',
    borderWidth: '1px',
    borderColor: colors.GREY,
    borderStyle: 'solid',
    borderRadius: '3px',
    backgroundColor: colors.WHITE,
    margin: '16px 0',
    variants: {
      accordion: {
        width: 'unset'
      }
    },
    ...(isMobile ? { width: '100%' } : {})
  })
);

export const InternalNumberInput = createOwnUpComponent(
  OwnUpNumberInput,
  createOwnUpStyle({
    display: 'inline-block',
    position: 'inherit',
    width: '100%',
    height: '60px',
    paddingLeft: '5px',
    margin: '0 !important',
    outline: 0,
    fontSize: '15px',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.PRIMARY,
    backgroundColor: colors.WHITE,
    variants: {
      percent: {
        width: '25%',
        textAlign: 'end'
      }
    }
  })
);

export const InputLabel = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    ...fonts.GRAPHIK_REGULAR,
    fontSize: '15px',
    fontWeight: 'bold',
    color: colors.PRIMARY,
    backgroundColor: colors.WHITE,
    border: 'none',
    position: 'relative',
    marginTop: '21px',
    marginLeft: '20px',
    ':focus': { outline: 'none' },
    variants: {
      percent: {
        marginLeft: '5px',
        paddingRight: '20px'
      }
    }
  })
);

export const InputPrefix = createOwnUpComponent(
  'span',
  createOwnUpStyle({
    display: 'flex',
    alignSelf: 'center',
    fontWeight: 'bold',
    margin: '0 16px'
  })
);

export type ChangeHandlerArgs = [string | React.SyntheticEvent, string?];
export type SimpleInputDisplayElementProps<TVALUETYPE> = {
  value: TVALUETYPE;
  onChange: (...props: ChangeHandlerArgs) => void;
  onBlur?: (...props: ChangeHandlerArgs) => void;
};

type CreateMemoizedSimpleInputOptions = {
  prefix?: string;
  InputElement?: React.ComponentType;
  inputElementProps?: { [key: string]: any };
};
const createMemoizedSimpleInputOptionsDefaults = {
  InputElement: InternalNumberInput
};

// Returns an input element that manages the given redux property efficiently
export const createMemoizedSimpleInput = (
  stateProperty: keyof InputsState,
  options: CreateMemoizedSimpleInputOptions = {}
) => {
  const { InputElement, prefix, inputElementProps } = {
    ...createMemoizedSimpleInputOptionsDefaults,
    ...options
  };
  type PropertyType = InputsState[typeof stateProperty];

  const addAccordionVariant = [
    'annualPropertyTaxRate',
    'monthlyFee',
    'monthlyHomeownersInsurance'
  ].includes(stateProperty);

  // Stateless, memoized component for rendering
  const DisplayElement = (props: SimpleInputDisplayElementProps<PropertyType>) => {
    const renderedPrefix =
      prefix !== undefined ? <InputPrefix aria-hidden>{options.prefix}</InputPrefix> : '';
    const isMobile = useIsMobile(1024);
    return (
      <InputWrapper
        isMobile={isMobile}
        {...{ ...{ variant: addAccordionVariant ? 'accordion' : '' } }}
      >
        {renderedPrefix}
        <InputElement
          {...{ id: stateProperty, name: stateProperty, ...props, ...inputElementProps }}
        />
      </InputWrapper>
    );
  };

  // Wrapper element that manages state and renders the display element
  return () => {
    const inputs = useSelector(inputsSelector);
    const value = inputs[stateProperty];
    const dispatch = useDispatch();
    const changeHandler = (...args: ChangeHandlerArgs) => {
      // if DisplayElement uses useDebounce, then the handler will
      // get the previous value of the input as a second argument
      // that we don't care about, and other handlers should get
      // a single argument, so in either case we can ignore everything
      // after the first arg.
      let changeValue = args.shift();
      if (typeof changeValue === 'object' && 'target' in changeValue) {
        changeValue = (changeValue.target as HTMLInputElement).value;
      }
      const payload = { ...inputs, [stateProperty]: changeValue };
      dispatch(updateInputsActionCreator(payload));
    };
    return <DisplayElement value={value} onChange={changeHandler} />;
  };
};

export const createMemoizedDollarInput = (stateProperty: keyof InputsState) => {
  return createMemoizedSimpleInput(stateProperty, {
    prefix: '$',
    inputElementProps: { positive: true }
  });
};

export const createMemoizedPercentInput = (stateProperty: keyof InputsState) => {
  return createMemoizedSimpleInput(stateProperty, {
    prefix: '%',
    inputElementProps: { positive: true, decimalPlaces: 3 }
  });
};

export const AnnualIncomeInput = createMemoizedDollarInput('annualIncome');
export const AnnualInterestRateInput = createMemoizedPercentInput('annualInterestRate');
export const AnnualPropertyTaxRateInput = createMemoizedPercentInput('annualPropertyTaxRate');
export const MonthlyDebtInput = createMemoizedDollarInput('monthlyDebt');
export const MonthlyFeeInput = createMemoizedDollarInput('monthlyFee');
export const MonthlyHomeownersInsuranceInput = createMemoizedDollarInput(
  'monthlyHomeownersInsurance'
);
