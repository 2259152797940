import { composeReducers, ofType, withDefault } from 'redux-compose';
import { LOAD_ACCOUNT_ACTION_TYPE } from '../actions';
import {
  MANIFEST_LOADED_FAILED_ACTION_TYPE,
  MANIFEST_LOADED_SUCCESS_ACTION_TYPE,
  ManifestLoadedFailedAction,
  ManifestLoadedSuccessAction,
  OPTED_INTO_LEAD_TARGET_ACTION_TYPE,
  OptedIntoLeadTargetAction
} from './actions';
import { State } from './state';

export const reducer = composeReducers(
  withDefault<State>({ loading: true }),
  ofType(LOAD_ACCOUNT_ACTION_TYPE, (): State => ({ loading: true })),
  ofType(
    MANIFEST_LOADED_SUCCESS_ACTION_TYPE,
    (_, { payload: { manifest: data } }: ManifestLoadedSuccessAction): State => ({
      loading: false,
      success: true,
      data
    })
  ),
  ofType(
    MANIFEST_LOADED_FAILED_ACTION_TYPE,
    (_, { payload: { error } }: ManifestLoadedFailedAction): State => ({
      loading: false,
      success: false,
      error
    })
  ),
  ofType(
    OPTED_INTO_LEAD_TARGET_ACTION_TYPE,
    (state, { payload: { leadID, targetId } }: OptedIntoLeadTargetAction): State => {
      if (state.loading || !state.success) {
        return { ...state };
      }
      return {
        ...state,
        data: {
          ...state.data,
          // Find the lead target this impacted and update it inline.
          leadTargets: state.data.leadTargets.map((leadTarget) => {
            const isTheTarget = leadTarget.leadID === leadID && leadTarget.targetId === targetId;
            return { ...leadTarget, ...(isTheTarget ? { optedIn: Date.now() } : {}) };
          })
        }
      };
    }
  )
);
