import { event } from '@rategravity/frontend/modules/user-actions';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { useActionPipe } from '../../../../hooks/use-action-pipe';
import { Button } from '../button';
import { MediumGreenText, RegularDarkText } from '../typography';

const YCBMLink = createOwnUpComponent(
  'a',
  createOwnUpStyle({
    marginTop: '16px',
    width: '312px',
    ':hover': {
      textDecoration: 'none'
    }
  })
);

const CTAWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column',
    margin: '40px auto 40px 0',
    width: '360px',
    // To keep an eye on: iOS 15 Safari places the url bar at the bottom as an overlay,
    // cutting off this button. If they fix this issue we can remove the bottom margin here
    ...(isMobile ? { width: '312px', margin: '40px auto 120px' } : {})
  })
);

export interface NextStepsLinkProps {
  // Optional function to run before leaving the site
  beforeNavigation?: () => void;
  link: string;
  disabled?: boolean;
}

const NextStepsLink = ({
  children,
  beforeNavigation,
  link,
  disabled
}: React.PropsWithChildren<NextStepsLinkProps>) =>
  disabled ? (
    <>{children}</>
  ) : (
    <YCBMLink
      href={link}
      onClick={
        beforeNavigation
          ? (e: any) => {
              // If beforeNavigation is defined, prevent the typical link behavior
              e.preventDefault();
              // ...then perform the beforeNavigation function.
              beforeNavigation();
              // Allow background tasks to run before leaving
              setTimeout(() => {
                window.location.href = link;
              }, 10);
            }
          : undefined // If no beforeNavigation function is provided, this will act as a standard link
      }
    >
      {children}
    </YCBMLink>
  );

export const NextStep = ({ ycbmLink, disabled }: { ycbmLink: string; disabled?: boolean }) => {
  const isMobile = useIsMobile(1024);
  const dispatchAction = useActionPipe();
  const scheduleCallPAS = event('scheduleCall-PAS');

  return (
    <CTAWrapper isMobile={isMobile}>
      {disabled ? null : (
        <>
          <MediumGreenText>Ready for your next step?</MediumGreenText>
          <RegularDarkText>Let&apos;s work together to get you preapproved.</RegularDarkText>
        </>
      )}
      <NextStepsLink
        beforeNavigation={() => {
          dispatchAction(scheduleCallPAS);
        }}
        link={ycbmLink}
        disabled={disabled}
      >
        <Button disabled={disabled}>Get preapproved</Button>
      </NextStepsLink>
    </CTAWrapper>
  );
};
