/**
 * assumes the input string is camelCased and will Sentence case it
 * @param s string to sentence case
 */
export const camelCaseToSentence = (s: string) => {
  let result = '';
  for (const c of s) {
    const capitalC = c.toUpperCase();
    // first letter should be capitalized
    if (result === '') {
      result += capitalC;
      continue;
    }
    // capitals split words
    if (capitalC === c) {
      result += ' ';
    }
    result += c.toLowerCase();
  }
  return result;
};

export const spacesToCamelCase = (s: string) => {
  let result = '';
  let nextCap = true;
  for (const c of s.toLowerCase()) {
    if (c === ' ') {
      nextCap = true;
      continue;
    }
    result += nextCap ? c.toUpperCase() : c;
    nextCap = false;
  }
  return result;
};
