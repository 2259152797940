/**
 * returns string product type
 * @param type loan type received from backend
 */
export const getLoanType = (type: string): string => {
  const terms = getLoanTerms(type);
  if (Number.isNaN(terms.loanTerm)) {
    return 'Other';
  }
  if (terms.adjustmentPeriod != null) {
    return `${terms.fixedTerm}/${
      terms.adjustmentPeriod < 1 ? `${12 * terms.adjustmentPeriod}m` : terms.adjustmentPeriod
    } ARM`;
  } else {
    return `${terms.loanTerm} Year Fixed`;
  }
};

export const getLoanTerms = (
  type: string
): { loanTerm: number; fixedTerm: number; adjustmentPeriod?: number } => {
  if (!type) {
    return { loanTerm: Number.NaN, fixedTerm: Number.NaN };
  }
  const match = type.match(/^(arm|fixed)(\d+)(?:-([.\d]+))?$/);
  if (!match) {
    return { loanTerm: Number.NaN, fixedTerm: Number.NaN };
  }
  if (match[1] === 'arm') {
    const fixedTerm = Number(match[2]);
    const adjustmentPeriod = Number(match[3] || 1);
    return {
      loanTerm: 30,
      fixedTerm,
      adjustmentPeriod
    };
  }
  const loanTerm = Number(match[2]);
  return {
    loanTerm,
    fixedTerm: loanTerm
  };
};

/**
 * if n > 1 then creates a plural phrase otherwise just returns the word
 *
 * ex: 1 month => month
 * ex: 2 year => 2 years
 * @param n number
 * @param word object word in its singular form
 */
const plural = (n: number, word: string) => (n > 1 ? `${n} ${word}s` : word);

/**
 * returns the fixed and adjustment period (in string format) of the product
 * @param type loan type received from backend
 */
export const getFixedAndAdjustable = (type: string) => {
  const terms = getLoanTerms(type);
  if (terms.adjustmentPeriod != null) {
    return {
      fixedPeriod: plural(Number(terms.fixedTerm), 'year'),
      adjustmentPeriod:
        terms.adjustmentPeriod < 1
          ? plural(12 * terms.adjustmentPeriod, 'month')
          : plural(terms.adjustmentPeriod, 'year')
    };
  }
  return { fixedPeriod: '', adjustmentPeriod: '' };
};
