import { useEffect, useRef } from 'react';

export const useWaitTill = (time: number | null, callback: () => void): void => {
  const callbackRef = useRef(callback);
  callbackRef.current = callback;
  useEffect(() => {
    if (time !== null) {
      const timeout = time - Date.now();
      if (timeout > 0) {
        const t = setTimeout(() => callbackRef.current(), timeout);
        return () => clearTimeout(t);
      } else {
        callbackRef.current();
      }
    }
  }, [time, callbackRef]);
};
