import { OwnUpGridContainer, OwnUpGridItem } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const PaddedGridContainer = styled(OwnUpGridContainer)`
  width: 100%;
  margin: -6px;
`;

const PaddedGridItem = styled(OwnUpGridItem)`
  padding: 6px !important;
`;

export const GridContainer = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <PaddedGridContainer variant="slim">{children}</PaddedGridContainer>
);

export const HalfWidthGridItem = ({ children }: PropsWithChildren<{}>) => (
  <PaddedGridItem xs={12} md={6}>
    {children}
  </PaddedGridItem>
);

export const FullWidthGridItem = ({ children }: PropsWithChildren<{}>) => (
  <PaddedGridItem xs={12}>{children} </PaddedGridItem>
);
