import React from 'react';
import { colors } from '../../../../modules/colors';

interface LinePatternProps {
  id?: string;
  lineWidth?: number;
  lineSpacing?: number;
  lineColor?: string;
  lineOpacity?: number;
  bgColor?: string;
}

export const CrosshatchPattern = ({
  id = 'crosshatch',
  lineWidth = 1,
  lineSpacing = 7,
  lineColor = 'white',
  lineOpacity = 0.5,
  bgColor = colors.CHART_BLUE_FOUR
}: LinePatternProps) => (
  <pattern
    id={id}
    patternUnits="userSpaceOnUse"
    width={lineWidth + lineSpacing}
    height={lineWidth + lineSpacing}
  >
    <rect width="100%" height="100%" fill={bgColor} />
    <line
      x2={lineWidth + lineSpacing}
      y2={lineWidth + lineSpacing}
      stroke={lineColor}
      strokeWidth={lineWidth}
      opacity={lineOpacity}
    />
    <line
      x2={lineWidth + lineSpacing}
      y1={lineWidth + lineSpacing}
      stroke={lineColor}
      strokeWidth={lineWidth}
      opacity={lineOpacity}
    />
  </pattern>
);

export const HorizontalStripePattern = ({
  id = 'horizontal-stripe',
  lineWidth = 1,
  lineSpacing = 4,
  lineColor = 'white',
  lineOpacity = 1,
  bgColor = colors.CHART_BLUE_ONE
}: LinePatternProps) => (
  <pattern id={id} patternUnits="userSpaceOnUse" width="10" height={lineWidth + lineSpacing}>
    <rect width="100%" height="100%" fill={bgColor} />
    <line x2="100%" stroke={lineColor} strokeWidth={lineWidth} opacity={lineOpacity} />
  </pattern>
);

export const VerticalStripePattern = ({
  id = 'vertical-stripe',
  lineWidth = 1,
  lineSpacing = 4,
  lineColor = 'white',
  lineOpacity = 1,
  bgColor = colors.CHART_BLUE_THREE
}: LinePatternProps) => (
  <pattern id={id} patternUnits="userSpaceOnUse" width={lineWidth + lineSpacing} height="10">
    <rect width="100%" height="100%" fill={bgColor} />
    <line y2="100%" stroke={lineColor} strokeWidth={lineWidth} opacity={lineOpacity} />
  </pattern>
);

interface DotPatternProps {
  id?: string;
  dotWidth?: number;
  dotSpacing?: number;
  dotColor?: string;
  dotOpacity?: number;
  bgColor?: string;
}

export const DotPattern = ({
  id = 'dots',
  dotWidth = 2,
  dotSpacing = 7,
  dotColor = 'white',
  dotOpacity = 1,
  bgColor = colors.CHART_BLUE_TWO
}: DotPatternProps) => {
  const boxSize = dotWidth + dotSpacing;
  return (
    <pattern id={id} patternUnits="userSpaceOnUse" width={boxSize} height={boxSize}>
      <rect width="100%" height="100%" fill={bgColor} />
      <circle
        cx={boxSize / 2}
        cy={boxSize / 2}
        r={dotWidth / 2}
        fill={dotColor}
        opacity={dotOpacity}
      />
    </pattern>
  );
};
