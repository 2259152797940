import { composeReducers, ofType, withDefault } from 'redux-compose';
import { getCleanObject } from '../../../modules/simulator/util';
import {
  UPDATE_HOME_PRICE_ACTION_TYPE,
  UPDATE_INPUTS_ACTION_TYPE,
  UpdateHomePriceAction,
  UpdateInputsAction
} from './actions';
import { DEFAULT_INPUTS_STATE, InputsState } from './state';

const updateInputs = (state: InputsState, { payload }: UpdateInputsAction): InputsState => {
  getCleanObject(payload);

  return {
    ...state,
    ...payload
  };
};

const updateHomePrice = (
  state: InputsState,
  { payload: { homePrice } }: UpdateHomePriceAction
): InputsState => ({
  ...state,
  homePrice
});

export const inputsReducer = composeReducers(
  withDefault(DEFAULT_INPUTS_STATE),
  ofType(UPDATE_INPUTS_ACTION_TYPE, updateInputs),
  ofType(UPDATE_HOME_PRICE_ACTION_TYPE, updateHomePrice)
);
