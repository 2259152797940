import { monthlyPMT } from '@rategravity/core-lib/rates';
import { term } from './constants';

export const computeMonthlyPAndI = (
  homePrice: number,
  downPayment: number,
  annualInterestRate: number
) => {
  const presentValue = homePrice - downPayment;
  const rate = annualInterestRate;
  const payments = term;

  return parseFloat(monthlyPMT(rate, payments, presentValue).toFixed(2));
};
