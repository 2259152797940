import { OwnUpFillButtonPrimary } from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { TextSkeleton } from '../../components/text-skeleton';
import { expiredSelector } from '../../redux/selectors';

export const ExpiredPage = ({
  advisor,
  name,
  accountId
}: {
  advisor: string;
  name: string;
  accountId: string;
}) => (
  <TextSkeleton
    title="Your ability to create new preapprovals has expired"
    body="Contact your advisor for more details."
    ctas={[
      <a href={`https://consult.ownup.com/schedule/general/${advisor}?NAME=${name}`} key="1">
        <OwnUpFillButtonPrimary>Schedule a call</OwnUpFillButtonPrimary>
      </a>
    ]}
    accountId={accountId}
  />
);

export const Expired = ({ children }: { children: JSX.Element }) => {
  const { expired, advisor, name } = useSelector(expiredSelector);
  const { accountId } = useSelector(manifestAccountInfoSelector);
  return expired ? (
    <ExpiredPage advisor={advisor} name={name} accountId={accountId} />
  ) : (
    <div>{children}</div>
  );
};
