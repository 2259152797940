import { push } from 'connected-react-router';
import { ofType, withDefault } from 'redux-compose';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { PREAPPROVALS_API_RESPONSE_ACTION_TYPE, PreapprovalsApiResponseAction } from './api';
import { basePathSelector } from './router';
import { Preapproval } from './state';

const DATA_RESET_ACTION_TYPE = 'PREAPPROVALS__DATA_RESET';
export const reset = () => ({
  type: DATA_RESET_ACTION_TYPE
});

export function* handlePreapprovalDataApiResponse({
  status
}: PreapprovalsApiResponseAction<Preapproval, object>) {
  const {
    preapprovals: {
      data: { preapprovalId, constraintsId }
    }
  } = yield select();
  const basePath: string = yield select(basePathSelector);
  if (status >= 400) {
    // includes 400 and 500 errors
    yield put(push(`${basePath}/preapproval/error`));
  } else if (preapprovalId && status === 200) {
    yield put(push(`${basePath}/preapproval/${constraintsId}/${preapprovalId}`));
  }
}

export function* handleReset() {
  const {
    preapprovals: {
      data: { constraintsId }
    }
  } = yield select();
  const basePath: string = yield select(basePathSelector);
  yield put(push(`${basePath}/preapproval/${constraintsId}`));
}

export function* dataSaga() {
  yield all([
    takeEvery(PREAPPROVALS_API_RESPONSE_ACTION_TYPE, handlePreapprovalDataApiResponse),
    takeEvery(DATA_RESET_ACTION_TYPE, handleReset)
  ]);
}
export const reducer = withDefault(
  {
    userId: null,
    versionId: null,
    constraintsId: null,
    preapprovalId: null,
    borrower: '',
    coborrower: '',
    isExpired: false,
    occupancy: 'PrimaryResidence',
    propertyType: 'SingleFamily',
    advisor: 'mike'
  },
  ofType(
    PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
    (
      state: Preapproval,
      { data = {} as Preapproval }: PreapprovalsApiResponseAction<Preapproval, object>
    ) => ({
      userId: data.userId || state.userId,
      versionId: data.versionId || state.versionId,
      constraintsId: data.constraintsId || state.constraintsId,
      preapprovalId: data.preapprovalId || state.preapprovalId,
      borrower: data.borrower || state.borrower,
      coborrower: data.coborrower || state.coborrower,
      isExpired: data.isExpired || state.isExpired,
      occupancy: data.occupancy || state.occupancy,
      propertyType: data.propertyType || state.propertyType,
      advisor: data.advisor || state.advisor
    })
  )
);
