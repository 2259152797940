import {
  OwnUpBody,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpLargeBody,
  SLATE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const TypographyOffset = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <>
    <OwnUpGridItem xs={12} sm={10}>
      {children}
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} sm={2} />
  </>
);

const BodyText = styled(OwnUpBody)`
  color: ${SLATE_100};
  padding-top: 16px;
`;

const HeaderWrapper = styled.div<{ includeTopPadding: boolean }>`
  padding: ${({ theme }) => theme.spacing(4, 0, 3)};
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }) => theme.spacing(5, 0, 4)};
  }
  ${({ includeTopPadding }) => (!includeTopPadding ? 'padding-top: 0 !important;' : undefined)};
`;

export const Header = ({
  header,
  body,
  includeTopPadding = true
}: {
  header: string;
  body: string;
  includeTopPadding?: boolean;
}) => (
  <HeaderWrapper includeTopPadding={includeTopPadding}>
    <TypographyOffset>
      <OwnUpLargeBody variant="body1">{header}</OwnUpLargeBody>
      <BodyText variant="body1">{body}</BodyText>
    </TypographyOffset>
  </HeaderWrapper>
);
