import { all, put, select, takeEvery } from 'redux-saga/effects';
import { constantsSelector } from '../../../../redux/constants/selector';
import { computeMonthlyPayment } from '../../../modules/simulator/compute-monthly-payment';
import { computeSliderConstraints } from '../../../modules/simulator/compute-slider-constraints';
import { computeSliderValue } from '../../../modules/simulator/compute-slider-value';
import { computeSpecialScenario } from '../../../modules/simulator/compute-special-scenario';
import { INPUTS_KEY, maxDTI } from '../../../modules/simulator/constants';
import { updateMonthlyPaymentActionCreator } from '../monthly-payment/actions';
import { updateSliderConstraintsActionCreator } from '../slider-constraints/actions';
import { sliderConstraintsSelector } from '../slider-constraints/selectors';
import { updateSpecialScenarioActionCreator } from '../special-scenario/actions';
import { HIGH_DTI_SCENARIO, LOW_DOWN_PAYMENT_SCENARIO } from '../special-scenario/state';
import { UPDATE_HOME_PRICE_ACTION_TYPE, UpdateHomePriceAction } from './actions';
import { inputsSelector } from './selectors';

export function* handleUpdateHomePriceAction({
  payload: { homePrice, mode }
}: UpdateHomePriceAction) {
  if (mode === 'specialConstraints') {
    // This happens when down payment constraints the slider,
    // kicking off a special scenario.
    return;
  }

  try {
    let {
      annualIncome,
      downPayment,
      monthlyDebt,
      approximateCreditScore,
      annualInterestRate,
      annualPropertyTaxRate,
      monthlyFee,
      monthlyHomeownersInsurance,
      jumboLoanLimit
    } = yield select(inputsSelector);
    const { conformingLoanLimit } = yield select(constantsSelector);

    annualIncome = annualIncome || 0;
    downPayment = downPayment || 0;
    monthlyDebt = monthlyDebt || 0;
    approximateCreditScore = approximateCreditScore || 0;
    annualInterestRate = annualInterestRate || 0;
    annualPropertyTaxRate = annualPropertyTaxRate || 0;
    monthlyFee = monthlyFee || 0;
    monthlyHomeownersInsurance = monthlyHomeownersInsurance || 0;
    jumboLoanLimit = jumboLoanLimit || conformingLoanLimit + 1;

    const inputs = {
      homePrice,
      annualIncome,
      downPayment,
      monthlyDebt,
      approximateCreditScore,
      annualInterestRate,
      annualPropertyTaxRate,
      monthlyFee,
      monthlyHomeownersInsurance,
      jumboLoanLimit
    };

    const { sliderMin, sliderMax, sliderGradient } =
      mode === 'inputs'
        ? computeSliderConstraints({
            annualIncome,
            downPayment,
            monthlyDebt,
            approximateCreditScore,
            annualInterestRate,
            annualPropertyTaxRate,
            monthlyFee,
            monthlyHomeownersInsurance,
            conformingLoanLimit,
            jumboLoanLimit
          })
        : yield select(sliderConstraintsSelector);

    const newSpecialScenario = computeSpecialScenario({
      annualIncome,
      downPayment,
      homePrice,
      maxDTI,
      approximateCreditScore,
      monthlyDebt,
      sliderConstraints: { sliderMin, sliderMax, sliderGradient }
    });

    yield put(updateSpecialScenarioActionCreator(newSpecialScenario));

    const newMonthlyPayment =
      newSpecialScenario === HIGH_DTI_SCENARIO || newSpecialScenario === LOW_DOWN_PAYMENT_SCENARIO
        ? {
            monthlyPAndI: null,
            monthlyPropertyTax: null,
            monthlyHomeownersInsurance: null,
            monthlyFee: null,
            monthlyPMI: null
          }
        : computeMonthlyPayment(inputs);
    yield put(updateMonthlyPaymentActionCreator(newMonthlyPayment));

    if (mode === 'inputs') {
      const newSliderValue =
        newSpecialScenario === LOW_DOWN_PAYMENT_SCENARIO
          ? sliderMax
          : computeSliderValue({ homePrice, sliderMin, sliderMax });
      yield put(updateSliderConstraintsActionCreator({ sliderValue: newSliderValue }));
    }
    sessionStorage.setItem(INPUTS_KEY, JSON.stringify(inputs));
  } catch (err) {
    console.error("Couldn't update home price: ", err);
  }
}

export function* saga() {
  yield all([takeEvery(UPDATE_HOME_PRICE_ACTION_TYPE, handleUpdateHomePriceAction)]);
}
