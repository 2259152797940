import {
  OwnUpComponentLibraryProvider,
  OwnUpGridItem,
  OwnUpLogoLink,
  OwnUpLogoWithTextLink,
  OwnUpNavBar,
  OwnUpNavLink
} from '@rategravity/own-up-component-library';
import { ProfileIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/profile';
import React from 'react';
import styled from 'styled-components';
import { CobrandingLogo } from '../cobranding';

const AccountNavBar = styled(OwnUpNavBar)`
  position: relative;
`;

const LogoContainer = styled(OwnUpGridItem)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const KatinaHeader = styled(LogoContainer)`
  width: 100%;
`;

interface HeaderProps {
  accountId: string;
  isProvisionalAccount?: boolean;
  logOut: () => Promise<void>;
  isLoggedIn?: boolean;
  cobranding?: string;
  useKatinaHeader?: boolean;
}

export const Header = ({
  accountId,
  isProvisionalAccount,
  logOut,
  isLoggedIn,
  cobranding,
  useKatinaHeader
}: HeaderProps) => (
  <OwnUpComponentLibraryProvider>
    <AccountNavBar>
      {useKatinaHeader ? (
        <KatinaHeader>
          <OwnUpLogoWithTextLink
            href={!!accountId ? `/account/${accountId}` : `${process.env.WWW}`}
          />
        </KatinaHeader>
      ) : (
        <>
          <LogoContainer>
            <OwnUpLogoLink href={!!accountId ? `/account/${accountId}` : `${process.env.WWW}`} />
          </LogoContainer>
          <OwnUpGridItem style={{ flexGrow: 1 }} />
          <LogoContainer>
            {!!cobranding ? (
              <CobrandingLogo source={cobranding} />
            ) : (
              !isProvisionalAccount &&
              isLoggedIn && (
                <OwnUpNavLink
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    void logOut();
                  }}
                  href="javascript:void(0)"
                  icon={ProfileIcon}
                  size="small"
                >
                  Sign out
                </OwnUpNavLink>
              )
            )}
          </LogoContainer>
        </>
      )}
    </AccountNavBar>
  </OwnUpComponentLibraryProvider>
);
