import { TreatmentsWithConfig } from '@splitsoftware/splitio/types/splitio';
import { useClient } from '@splitsoftware/splitio-react';
import { useState } from 'react';

type Result = {
  treatments: TreatmentsWithConfig;
  splitLoading: boolean;
};

/**
 * Initializes a new SplitClient with the specified splitKey.
 *
 * Returns the treatment and config for the specified splitName when the client is ready.
 *  If the client is not ready, returns default values ('control' and null)
 *
 * Also returns splitLoading, which is true until the client reports
 *  being ready.
 */
export const useSplitTreatmentsWhenClientReady = (
  splitKey: string,
  splitNames: string[],
  attributes?: Record<string, string>
) => {
  const defaultTreatments = splitNames.reduce(
    (acc, splitName) => ({
      ...acc,
      [splitName]: {
        treatment: 'control',
        config: null
      }
    }),
    {} as TreatmentsWithConfig
  );
  // Stuff everything into a single object to avoid triggering multiple renders
  const [result, setResult] = useState<Result>({
    treatments: defaultTreatments,
    splitLoading: true
  });
  const client = useClient(splitKey);
  if (client) {
    client.on(client.Event.SDK_READY, () => {
      const treatments = client.getTreatmentsWithConfig(splitNames, attributes);
      setResult({ treatments, splitLoading: false });
    });
  }

  return result;
};

/**
 * Wrapper around the above to make it easier to get the treatment for a single split
 */
export const useSplitTreatmentWhenClientReady = (
  splitKey: string,
  splitName: string,
  attributes?: Record<string, string>
) => {
  const {
    treatments: {
      [splitName]: { treatment, config }
    },
    splitLoading
  } = useSplitTreatmentsWhenClientReady(splitKey, [splitName], attributes);

  return { treatment, config, splitLoading };
};
