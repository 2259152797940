import { createSelector } from 'reselect';
import { ApplicationState } from '../state';

export const advisorDataSelector = (state: ApplicationState) => state.advisorData;

export const advisorDataLoadingSelector = createSelector(
  advisorDataSelector,
  ({ status }) => status === 'loading'
);

export const allAdvisorReviewsSelector = createSelector(
  advisorDataSelector,
  ({ allAdvisorReviews }) => allAdvisorReviews
);
