import { CACTUS_100, FOG_60, OwnUpBody } from '@rategravity/own-up-component-library';
import { LoadingSpinner } from '@rategravity/own-up-component-library-legacy/components/images/icons/svgs';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  height: 44px;
  width: 44px;
  margin-bottom: 16px;
`;

export const LoadingPreapproval = () => (
  <Container>
    <LoadingWrapper>
      <LoadingSpinner color={CACTUS_100} secondaryColor={FOG_60} />
    </LoadingWrapper>
    <OwnUpBody variant="body1">Generating your preapproval...</OwnUpBody>
  </Container>
);
