import {
  OwnUpAppBar,
  OwnUpCallButton,
  useAdvisors
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useSelector } from 'react-redux';
import { advisorSelector } from '../../redux/selectors';

export const PreapprovalHeader = () => {
  const advisorId = useSelector(advisorSelector);
  const allAdvisors = useAdvisors();
  const advisorPhone = allAdvisors[advisorId]?.phone;

  return (
    <OwnUpAppBar variant="minimal">
      {!!advisorPhone ? (
        <OwnUpCallButton
          variant={['header', 'headerAlt']}
          phoneNumber={advisorPhone}
          fullText={advisorPhone}
        />
      ) : (
        <span />
      )}
    </OwnUpAppBar>
  );
};
