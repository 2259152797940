import { getStatesText } from './states-text';

/**
 * given lender info and the propertyState
 * returns an array of the lender highlights
 * if info is missing, bullet will be removed
 * At least need to have fundedAmount or yearFounded in order to show bullets
 */
export const getLenderHighlights = ({
  yearFounded,
  fundedAmountLastYear,
  licensedStates,
  propertyState
}: {
  yearFounded?: number;
  fundedAmountLastYear?: string;
  licensedStates?: string[];
  propertyState?: string;
}) => {
  if (!yearFounded && !fundedAmountLastYear) {
    return [];
  }
  return [
    !!yearFounded ? `Founded in ${yearFounded}` : '',
    !!fundedAmountLastYear ? `Funded $${fundedAmountLastYear} in loans last year` : '',
    licensedStates && licensedStates.length > 0 && !!propertyState
      ? `Lends in ${getStatesText(licensedStates, propertyState)}`
      : ''
  ].filter((s) => !!s);
};
