import { none, Optional, some } from '@rategravity/core-lib/optional';
import { parse, stringify } from 'querystring';
import { TEMP_TOKEN_PARAM, TOKEN_ID } from './token-constants';

export const storeLoginToken = (token: string) => {
  localStorage.setItem(TOKEN_ID, token);
};

export const removeLoginToken = () => {
  localStorage.removeItem(TOKEN_ID);
};

/**
 * Find and return a temp token from the location hash.
 *   Will remove that token from the hash afterwards.
 *
 * Returns undefined if not found.
 */
export const parseTempToken = () => {
  if (window.location.hash) {
    const params = parse(window.location.hash.replace('#', ''));
    const found = params[TEMP_TOKEN_PARAM];
    if (found) {
      delete params[TEMP_TOKEN_PARAM];
      window.location.hash = stringify(params);
      const stringified = found.toString();
      storeLoginToken(stringified);
      return stringified;
    }
  }
  return undefined;
};

export const maybeGetToken = (): Optional<string> => {
  const token = parseTempToken() || localStorage.getItem(TOKEN_ID);
  return token ? some(token) : none();
};
