import { OwnUpTooltip } from '@rategravity/own-up-component-library';
import React from 'react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const TooltipContentWrapper = styled.div`
  display: inline-block;
  width: 100%;
`;

export const Tooltip = ({
  children,
  description,
  open,
  onClose,
  onOpen
}: PropsWithChildren<{
  description: string;
  open: boolean;
  onClose?: () => void;
  onOpen?: () => void;
}>) => (
  <OwnUpTooltip {...{ open, onClose, onOpen, description }}>
    <TooltipContentWrapper>{children}</TooltipContentWrapper>
  </OwnUpTooltip>
);
