import { QuestionnaireState } from '../../modules/questionnaire-state/type';

export const QUESTIONNAIRE_STATE_LOAD_ACTION_TYPE = 'QUESTIONNAIRE_STATE_LOAD' as const;
export const questionnaireStateLoad = (userId: string, questionnaireId: string) => ({
  type: QUESTIONNAIRE_STATE_LOAD_ACTION_TYPE,
  payload: {
    userId,
    questionnaireId
  }
});
export type QuestionnaireStateLoadAction = ReturnType<typeof questionnaireStateLoad>;

export const QUESTIONNAIRE_STATE_LOAD_SUCCESS_ACTION_TYPE =
  'QUESTIONNAIRE_STATE_LOAD_SUCCESS' as const;
export const questionnaireStateLoadSuccess = (questionnaireState: QuestionnaireState) => ({
  type: QUESTIONNAIRE_STATE_LOAD_SUCCESS_ACTION_TYPE,
  payload: { questionnaireState }
});
export type QuestionnaireStateLoadSuccessAction = ReturnType<typeof questionnaireStateLoadSuccess>;

export const QUESTIONNAIRE_STATE_LOAD_FAILURE_ACTION_TYPE =
  'QUESTIONNAIRE_STATE_LOAD_FAILURE' as const;
export const questionnaireStateLoadFailure = (error: string) => ({
  type: QUESTIONNAIRE_STATE_LOAD_FAILURE_ACTION_TYPE,
  payload: {
    error
  }
});
export type QuestionnaireStateLoadFailureAction = ReturnType<typeof questionnaireStateLoadFailure>;
