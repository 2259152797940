import { Advisors, useAdvisors } from '@rategravity/own-up-component-library-legacy';
import queryString from 'querystring';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { rateQuoteSelector } from '../../rates/redux/selector';
import { ApplicationState as RatesApplicationState } from '../../rates/redux/state';
import { manifestAccountInfoSelector, pitchScenarioSelector } from '../../redux/manifest/selector';
import { getAdvisorIdByEmail } from '../../redux/selector/advisor-email-selector';
import { ApplicationState } from '../../redux/state';
import { preferredAdvisorSelector } from '../use-preferred-advisor';

/**
 * Arguments for building a YCBM consult link
 */
export interface ConsultLinkProps {
  state?: string;
  email?: string;
  name?: string;
  advisor?: string;
  questionnaireType?: string;
  /**
   * Set to true to use the dynamic schedule interface (shows
   *   the default link in an IFrame with a CTA on bottom to
   *   go to a more generic pool.) */
  dynamicSchedule?: boolean;
}

/**
 * Selector to generate consult parameters
 */
const ycbmLinkInputSelector = createSelector(
  manifestAccountInfoSelector,
  rateQuoteSelector,
  pitchScenarioSelector,
  preferredAdvisorSelector,
  (_: ApplicationState, advisors: Advisors) => ({
    allAdvisors: advisors
  }),
  ({ email, name }, rq, pitchScenario, preferredAdvisor, { allAdvisors }): ConsultLinkProps => {
    const rqState = rq.value?.propertyState;

    let advisorId = preferredAdvisor;
    if (advisorId && advisorId.includes('@')) {
      advisorId = getAdvisorIdByEmail(advisorId, allAdvisors).value?.id;
    }

    const dynamicSchedule = !!advisorId;
    return {
      email,
      name,
      // Prefer pitch state over rate quote state
      state: pitchScenario.value?.propertyState || rqState,
      questionnaireType: pitchScenario?.value?.scenario || 'general',
      advisor: advisorId,
      dynamicSchedule
    };
  }
);

/**
 * Builds a YCBM consult link based on the input arguments.
 */
export const buildConsultLink = ({
  state,
  email,
  name,
  advisor,
  questionnaireType = 'general',
  dynamicSchedule
}: ConsultLinkProps) => {
  // Generate the set of query parameters
  const queryParameters = queryString.stringify({
    ...(state ? { state: state.toUpperCase() } : {}),
    email,
    oemail: email,
    name
  });

  const advisorPath = advisor ? `/${advisor.toLowerCase()}` : '';
  // Append 'dynamic-schedule' to the path if the flag is set
  const dynamicSchedulePath = dynamicSchedule ? 'dynamic-schedule/' : '';
  // Return the built string
  return `https://consult.ownup.com/${dynamicSchedulePath}schedule/${questionnaireType.toLowerCase()}${advisorPath}?${queryParameters}`;
};

/**
 * Builds a YCBM consult link based on the current app state,
 *   also allowing for optional overrides.
 */
export const useConsultLink = (inputOverrides?: ConsultLinkProps) => {
  const { lead } = useSelector(manifestAccountInfoSelector);

  const advisors = useAdvisors();
  const derivedProps = useSelector((appState: ApplicationState & RatesApplicationState) => {
    // Check the source type or split to show all advisors calendars or a specific advisor calendar
    const a = lead?.sourceChannel === 'advisor-referral-questionnaire' ? advisors : {};
    return ycbmLinkInputSelector(appState, a);
  });

  // Mesh the two parameter sets together
  return buildConsultLink({
    ...derivedProps,
    ...inputOverrides
  });
};
