import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';

const RotatingDivStyle = () =>
  createOwnUpStyle({
    display: 'inline-block',
    width: 'max-content',
    transition: 'transform .3s ease',
    transform: 'rotate(0deg)',
    variants: {
      rotateOneEighty: {
        transform: 'rotate(180deg)'
      }
    }
  });

export const RotatingDiv = createOwnUpComponent('div', RotatingDivStyle);
