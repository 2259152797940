export const queryStringCreator = (obj: object, path: Array<string | number> = []): string =>
  (path.length === 0 ? '?' : '') +
  Object.entries(obj)
    .filter(([_, v]) => v !== undefined)
    .map(([k, v]) =>
      typeof v === 'object' && v !== null
        ? queryStringCreator(v, [...path, k])
        : (path.length > 0 ? path.join('.') + '.' : '') + `${k}=${v}`
    )
    .join('&');
