type Comparator<T> = (a: T, b: T) => number;

/**
 * Run each of the comparators sequentially and return the first non-zero result
 * @param comparators the comparators to run sequentially.
 */
export function compare<T>(
  ...comparators: [Comparator<T>, Comparator<T>, ...Comparator<T>[]]
): Comparator<T> {
  return (a: T, b: T): number => {
    for (const comparator of comparators) {
      const r = comparator(a, b);
      if (r !== 0) {
        return r;
      }
    }
    return 0;
  };
}

/**
 * create a new comparator the compares the results of the given selector.
 */
export function on<T, TValue>(
  comparator: Comparator<TValue>,
  selector: (item: T) => TValue
): Comparator<T> {
  return (a: T, b: T): number => comparator(selector(a), selector(b));
}

/**
 * The highest of two number
 */
export const highest = (a: number, b: number) => {
  if (a === b) {
    return 0;
  } else {
    return a > b ? -1 : 1;
  }
};
/**
 * The lowest of two numbers
 */
export const lowest = (a: number, b: number) => {
  if (a === b) {
    return 0;
  } else {
    return a > b ? 1 : -1;
  }
};

/**
 * prefers a true value over a false one
 */
export const truthy = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  } else {
    return a ? -1 : 1;
  }
};
/**
 * prefers a false value over a true one
 */
export const falsy = (a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  } else {
    return a ? 1 : -1;
  }
};
