import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useDisclosureState } from 'reakit/Disclosure';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { colors } from '../../../../modules/colors';
import { Accordion } from '../accordion';
import { AffordabilityDetailsProps } from '../types';
import { MediumAzureText, RegularDarkText } from '../typography';

export const ErrorWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    display: 'flex',
    flexDirection: 'column',
    border: `.5px solid ${colors.LIGHT_BLUE}`,
    borderRadius: '10px',
    backgroundColor: colors.LIGHT_BLUE,
    width: '100%',
    padding: '16px',
    ...(isMobile ? { width: '310px' } : {}),
    boxSizing: 'border-box'
  })
);

export interface ErrorDiv {
  title: string | undefined;
  copy: JSX.Element | undefined;
  isMobile: boolean;
}

const ErrorDiv = ({ title, copy, isMobile }: ErrorDiv) => (
  <ErrorWrapper isMobile={isMobile}>
    <MediumAzureText style={{ marginBottom: '5px' }}>{title}</MediumAzureText>
    <RegularDarkText>{copy}</RegularDarkText>
  </ErrorWrapper>
);

export const errorStates = [
  'Adjust your credit score',
  'Increase your loan size',
  'Increase your down payment',
  'Adjust your income'
];

export const AffordabilityDetails = React.memo(
  ({ details, scenario = 'Affordable', color }: AffordabilityDetailsProps) => {
    const controller = useDisclosureState({ visible: false });
    const isMobile = useIsMobile(1024);

    if (errorStates.includes(scenario)) {
      return <ErrorDiv title={scenario} copy={details} isMobile={isMobile} />;
    }
    return (
      <div>
        <Accordion
          title={scenario}
          backgroundColor={color}
          border={false}
          controller={controller}
          action="expandResults-PAS"
        >
          <RegularDarkText>{details}</RegularDarkText>
        </Accordion>
      </div>
    );
  }
);
