import debounce from 'lodash/debounce';
import React, { PropsWithChildren, useLayoutEffect, useState } from 'react';

interface Viewport {
  width?: number;
  height?: number;
}

export const ViewportContext = React.createContext<Viewport>({
  width: window.innerWidth,
  height: window.innerHeight
});

export const ViewportProvider = ({ children }: PropsWithChildren<{}>) => {
  const [dimensions, setDimensions] = useState({});
  useLayoutEffect(() => {
    const onResize = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    const debouncedResize = debounce(onResize, 1000);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [setDimensions]);
  return <ViewportContext.Provider value={dimensions}>{children}</ViewportContext.Provider>;
};
