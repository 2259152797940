import { OwnUpNumberInput } from '@rategravity/own-up-component-library';
import { DollarIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/dollar';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updatePreapprovalAmount } from '../../redux/generate';
import { purchasePriceSelector } from '../../redux/selectors';

export const PurchasePriceInput = ({ required = false }: { required?: boolean }) => {
  const { preapprovalAmount, error } = useSelector(purchasePriceSelector);
  const dispatch = useDispatch();
  const preapprovalAmountChanged = (amt: number) => dispatch(updatePreapprovalAmount(amt));
  return (
    <OwnUpNumberInput
      name="Purchase price"
      value={preapprovalAmount}
      decimalScale={0}
      allowNegative={false}
      label="Purchase price"
      $leadingIcon={<DollarIcon />}
      onValueChange={(value) => preapprovalAmountChanged(value.floatValue || 0)}
      error={!!error}
      helperText={error}
      required={required}
    />
  );
};
