import { all, call, put, takeEvery } from 'redux-saga/effects';
import { queryStringCreator } from '../../modules/query-string-creator';
import { Address, PreapprovalIdProperties } from './state';

type RequestType = 'load' | 'generate' | 'share';

export const PREAPPROVALS_API_REQUEST_ACTION_TYPE = 'PREAPPROVALS__API_REQUEST_ACTION';
export interface PreapprovalsApiRequestAction {
  type: typeof PREAPPROVALS_API_REQUEST_ACTION_TYPE;
  url: string;
  method: 'GET' | 'POST';
  requestType: RequestType;
  constraintsId: string;
  preapprovalId?: string;
  queryParams?: Record<string, unknown>;
}

export const load = ({
  constraintsId,
  preapprovalId
}: {
  constraintsId: string;
  preapprovalId?: string;
}): PreapprovalsApiRequestAction => ({
  type: PREAPPROVALS_API_REQUEST_ACTION_TYPE,
  url: `/preapproval/api/preapproval/${constraintsId}`,
  preapprovalId,
  constraintsId,
  method: 'GET',
  requestType: 'load'
});

export const generate = ({
  constraintsId,
  preapprovalAmount,
  downPaymentAmount,
  state,
  address,
  intent
}: {
  constraintsId: string;
  preapprovalAmount: number;
  downPaymentAmount: number;
  state: string;
  address: Address;
  intent: string;
}): PreapprovalsApiRequestAction => ({
  type: PREAPPROVALS_API_REQUEST_ACTION_TYPE,
  constraintsId,
  url: `/preapproval/api/preapproval/${constraintsId}`,
  queryParams: {
    preapprovalAmount,
    downPaymentAmount,
    state,
    address,
    intent,
    ownUpVersion: true
  },
  method: 'POST',
  requestType: 'generate'
});

export const share = ({
  constraintsId,
  preapprovalId,
  name,
  email
}: {
  constraintsId: string;
  preapprovalId: string;
  name: string;
  email: string;
}): PreapprovalsApiRequestAction => ({
  type: PREAPPROVALS_API_REQUEST_ACTION_TYPE,
  url: `/preapproval/api/preapproval/${constraintsId}/${preapprovalId}`,
  queryParams: {
    name,
    email
  },
  method: 'POST',
  requestType: 'share',
  constraintsId,
  preapprovalId
});

export const PREAPPROVALS_API_RESPONSE_ACTION_TYPE = 'PREAPPROVALS__API_RESPONSE_ACTION';

export const apiResponse = <TData extends object & PreapprovalIdProperties, TError extends object>({
  status,
  data,
  error,
  requestType
}: {
  status: number;
  data?: TData;
  error?: TError;
  requestType: RequestType;
}): PreapprovalsApiResponseAction<TData, TError> => ({
  type: PREAPPROVALS_API_RESPONSE_ACTION_TYPE,
  status,
  data,
  error,
  requestType
});
export interface PreapprovalsApiResponseAction<TData extends object, TError extends object> {
  type: typeof PREAPPROVALS_API_RESPONSE_ACTION_TYPE;
  status: number;
  data?: TData & PreapprovalIdProperties;
  error?: TError;
  requestType: RequestType;
}

export function* handlePreapprovalApiRequest({
  url,
  method,
  requestType,
  preapprovalId,
  queryParams
}: PreapprovalsApiRequestAction) {
  const assembledUrl = `${url}${queryParams ? queryStringCreator(queryParams) : ''}`;
  const response: Response = yield call(fetch, assembledUrl, { method });
  if (response.status === 200) {
    const { data, error } = yield response.json();
    yield put(
      apiResponse({
        status: 200,
        data: {
          preapprovalId,
          ...data
        },
        error,
        requestType
      })
    );
  } else if (response.status === 204) {
    yield put(apiResponse({ status: 204, requestType }));
  } else {
    yield put(apiResponse({ status: response.status, requestType }));
  }
}

export function* apiSaga() {
  yield all([takeEvery(PREAPPROVALS_API_REQUEST_ACTION_TYPE, handlePreapprovalApiRequest)]);
}
