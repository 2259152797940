import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/filter';
import { matchPath } from 'react-router';
import { composeReducers, ofType, withDefault } from 'redux-compose';
import { all, put, select, takeLeading } from 'redux-saga/effects';
import { load, PREAPPROVALS_API_RESPONSE_ACTION_TYPE } from './api';

const LOADING_ACTION_TYPE = 'PREAPPROVALS__LOADING';
const loadingStatus = (loading: boolean) => ({
  type: LOADING_ACTION_TYPE,
  loading
});
export type LoadingAction = ReturnType<typeof loadingStatus>;

const getPathMatch = (pathname: string, paths: string[]) =>
  paths.reduce(
    (p, n) => {
      const match = matchPath(pathname, { path: n });
      return match ? match : p;
    },
    { params: {} } as { params: Record<string, string> }
  );

export function* handleLoad() {
  const {
    preapprovals: {
      loading = false,
      data: { constraintsId }
    },
    router: { location: { pathname = '' } = {} } = {}
  } = yield select();

  const initialMatch = getPathMatch(pathname, [
    '/*/*/preapproval/:constraintsId',
    '/preapproval/:constraintsId'
  ]);

  if (
    !loading &&
    initialMatch &&
    initialMatch.params.constraintsId &&
    constraintsId !== initialMatch.params.constraintsId &&
    initialMatch.params.constraintsId !== 'error' &&
    initialMatch.params.constraintsId !== 'simulator'
  ) {
    const match = getPathMatch(pathname, [
      '/preapproval/:constraintsId',
      '/*/*/preapproval/:constraintsId',
      '/preapproval/:constraintsId/:preapprovalId',
      '/*/*/preapproval/:constraintsId/:preapprovalId'
    ]);

    if (match) {
      yield put(loadingStatus(true));
      yield put(
        load({
          constraintsId: match.params.constraintsId,
          preapprovalId: match.params.preapprovalId
        })
      );
    }
  }
}

export function* loadingSaga() {
  yield all([takeLeading('*', handleLoad)]);
}

export const reducer = withDefault(
  false,
  composeReducers(
    ofType(LOADING_ACTION_TYPE, (_: boolean, { loading }: LoadingAction) => loading),
    ofType(PREAPPROVALS_API_RESPONSE_ACTION_TYPE, () => false)
  )
);
