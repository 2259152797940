import { Action, ActionCreator } from 'redux';
import { MonthlyPaymentState } from './state';

export const UPDATE_MONTHLY_PAYMENT_ACTION_TYPE = 'MONTHLY_PAYMENT__UPDATE_ACTION' as const;

export interface UpdateMonthlyPaymentAction extends Action {
  type: typeof UPDATE_MONTHLY_PAYMENT_ACTION_TYPE;
  payload: Partial<MonthlyPaymentState>;
}

export const updateMonthlyPaymentActionCreator: ActionCreator<UpdateMonthlyPaymentAction> = (
  payload: Partial<MonthlyPaymentState>
) => ({
  type: UPDATE_MONTHLY_PAYMENT_ACTION_TYPE,
  payload
});
