import { OwnUpIconButton } from '@rategravity/own-up-component-library';
import { ChevronLeftIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/chevron-left';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const IconButton = styled(OwnUpIconButton)`
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export const BackButton = ({ accountId }: { accountId: string }) => {
  const history = useHistory();
  return (
    <IconButton
      icon={ChevronLeftIcon}
      name="Back"
      size={2}
      onClick={() => history.push(`/account/${accountId}`)}
    />
  );
};
