import { Actions } from '@rategravity/frontend/modules/user-actions';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { ReplaySubject } from 'rxjs';
import { context } from './action-pipe-context';

export type ActionDispatcher = (action: Actions) => void;

/**
 * Returns an action dispatcher function that pushes to the registered action pipe
 * Behind the scenes uses a reply subject to buffer actions, if the action pipe changes then
 * the replay subject will push the last few items into that new action pipe.
 */
export const useActionPipe = (): ActionDispatcher => {
  // create a ref to store a replay subject
  const { current: replaySubject } = useRef(new ReplaySubject<Actions>(10));
  // get the value from the context
  const actionPipe = useContext(context);
  // manage subscriptions
  // the action pipe will be subscribed to the replaySubject
  // since the replay subject never changes only updates to the action pipe
  // should trigger this effect.
  useEffect(() => {
    const subscription = replaySubject.subscribe(actionPipe);
    return () => subscription.unsubscribe();
  }, [actionPipe, replaySubject]);
  // return the action Dispatcher
  return useCallback((action: Actions): void => replaySubject.next(action), [replaySubject]);
};
