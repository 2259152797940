export type LockStatus = 'AVAILABLE' | 'PENDING' | 'EXPIRED' | 'INVALID';

export const getLockStatus = ({
  currentOfferId,
  lockedOfferId,
  expirationTime
}: {
  currentOfferId: string;
  lockedOfferId: string | null | undefined;
  expirationTime: number | undefined;
}): LockStatus => {
  if (lockedOfferId === currentOfferId) {
    // If the lockedOffer is the same as the offer being displayed show pending
    return 'PENDING';
  } else if (lockedOfferId) {
    // If a lockedOfferId exists but it's not the current offer then the rate cannot be locked
    return 'INVALID';
  } else if (expirationTime && expirationTime < Date.now()) {
    // expired if rates are not from today or if it's after 5pm
    // An expiration time is calculated on the backend
    return 'EXPIRED';
  }
  return 'AVAILABLE';
};
