import { of } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { ApplicationState } from '../state';
import { PreapprovalSimulatorPresets } from './state';

export const preapprovalSimulatorPresetsSelector = createSelector(
  ({ preapprovalSimulatorPresets }: ApplicationState): PreapprovalSimulatorPresets =>
    preapprovalSimulatorPresets,
  of
);
