// credit < 620
export const LOW_CREDIT_SCENARIO = 0;

// homePrice - downPayment < 100_000
export const LOW_LOAN_AMOUNT_SCENARIO = 1;

// monthlyDebts * 12 / annualIncome > maxDTI
export const HIGH_DTI_SCENARIO = 2;

// annualIncome = 0
export const NO_ANNUAL_INCOME_SCENARIO = 3;

/**
 * downPayment is so low it constrains the slider.
 *    Lowest PMI threshold is 3%
 *    High balance loan threshold is 5%
 *    Jumbo loan threshold is 20%
 *    Based on DTI, someone may have the loan allowance to afford
 *      a jumbo loan; but based on the Down Payment % provided
 *      the lender still may not provide them that loan.
 */
export const LOW_DOWN_PAYMENT_SCENARIO = 4;

export type SpecialScenarioState =
  | null
  | typeof LOW_CREDIT_SCENARIO
  | typeof LOW_LOAN_AMOUNT_SCENARIO
  | typeof HIGH_DTI_SCENARIO
  | typeof NO_ANNUAL_INCOME_SCENARIO
  | typeof LOW_DOWN_PAYMENT_SCENARIO;
