import { ComputeAmortizationInputs, computeAmortizationSchedule } from './amortization';
import { ComputeMI, ComputeMIInputs, computeRegularMI } from './mi';

export interface ComputeTotalCostInputs extends ComputeAmortizationInputs, ComputeMIInputs {
  totalFees: number;
}

/*
  calculate the total non-equity costs of a mortgage over its lifespan
*/
export const computeTotalCost = (
  inputs: ComputeTotalCostInputs,
  computeMI: ComputeMI = computeRegularMI
): number =>
  computeMI(inputs, computeAmortizationSchedule(inputs))
    .map((amorizationPeriod) => amorizationPeriod.interest + amorizationPeriod.mortgageInsurance)
    .reduce((prev, curr) => prev + curr, inputs.totalFees);
