import {
  FOG_20,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpLargeBody,
  OwnUpSmallHeadlineBook
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { GridTemplate } from '../../../components/grid/grid-template';
import { BackButton } from '../back-to-account';

const ButtonsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginTop: '40px',
  width: 'max-content',
  '> :not(:first-child)': {
    margin: '0 0 0 12px'
  },
  [theme.breakpoints.down('md')]: {
    marginTop: '32px'
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '> :not(:first-child)': {
      margin: '12px 0 0 0'
    }
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%'
  }
}));

const Body = styled(OwnUpLargeBody)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const PageGrid = ({ children, fullHeight }: { children: JSX.Element; fullHeight: boolean }) => (
  <GridTemplate backgroundColor={FOG_20} fullHeight={fullHeight}>
    {children}
  </GridTemplate>
);

const TypographyOffset = ({ children }: { children: JSX.Element }) => (
  <>
    <OwnUpGridItem xs={12} sm={10}>
      {children}
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} sm={2} />
  </>
);

export const TextSkeleton = ({
  title,
  body,
  ctas,
  additionalContent,
  fullHeight = true,
  accountId
}: {
  title: string;
  body: string;
  ctas: JSX.Element[];
  additionalContent?: JSX.Element;
  fullHeight?: boolean;
  accountId: string;
}) => (
  <PageGrid fullHeight={fullHeight}>
    <>
      <OwnUpGridContainer variant="slim">
        {!!accountId && (
          <OwnUpGridItem xs={12}>
            <BackButton accountId={accountId} />
          </OwnUpGridItem>
        )}
        <TypographyOffset>
          <OwnUpSmallHeadlineBook variant="h1">{title}</OwnUpSmallHeadlineBook>
        </TypographyOffset>
        <TypographyOffset>
          <Body variant="body1">{body}</Body>
        </TypographyOffset>
      </OwnUpGridContainer>
      <ButtonsContainer>{ctas}</ButtonsContainer>
      {additionalContent}
    </>
  </PageGrid>
);
