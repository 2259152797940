import debounce from 'lodash/debounce';
import { useContext, useLayoutEffect, useState } from 'react';
import { ViewportContext } from '../../viewport-context';

const DEFAULT_SHORT_VIEWPORT = 500;

export const useIsShortViewport = (minimumViewportHeight = DEFAULT_SHORT_VIEWPORT): boolean => {
  const [isShortViewport, setIsShortViewport] = useState(
    window.innerHeight <= minimumViewportHeight
  );
  useLayoutEffect(() => {
    const onResize = () => {
      setIsShortViewport(window.innerHeight <= minimumViewportHeight);
    };
    const debouncedResize = debounce(onResize, 100);
    window.addEventListener('resize', debouncedResize);
    return () => window.removeEventListener('resize', debouncedResize);
  }, [setIsShortViewport, minimumViewportHeight]);
  return isShortViewport;
};

export const useIsShortViewportContext = (
  minimumViewportHeight = DEFAULT_SHORT_VIEWPORT
): boolean => {
  const { height } = useContext(ViewportContext);
  return height !== undefined ? height <= minimumViewportHeight : false;
};
