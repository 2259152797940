import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const BottomBarPath =
  'M55.2879 64.6647H29.5225C29.0248 64.6647 28.6442 64.2841 28.6442 63.7863C28.6442 63.2886 29.0248 62.908 29.5225 62.908H55.2879C55.7857 62.908 56.1663 63.2886 56.1663 63.7863C56.1663 64.2841 55.7564 64.6647 55.2879 64.6647Z';
const TopBarPath =
  'M55.2879 29.2373H29.5225C29.0248 29.2373 28.6442 28.8567 28.6442 28.359C28.6442 27.8612 29.0248 27.4806 29.5225 27.4806H55.2879C55.7857 27.4806 56.1663 27.8612 56.1663 28.359C56.1663 28.8567 55.7564 29.2373 55.2879 29.2373Z';
const SideBarPath =
  'M52.3601 64.6647H32.7432V54.8563C32.7432 51.1086 34.8513 47.7123 38.1891 46.0727C34.8513 44.433 32.7432 41.0367 32.7432 37.289V27.4806H52.3601V37.289C52.3601 41.0367 50.252 44.433 46.9142 46.0727C50.252 47.7123 52.3601 51.1086 52.3601 54.8563V64.6647ZM34.5 62.908H50.6033V54.8563C50.6033 51.6064 48.6709 48.6785 45.6552 47.4195C45.0989 47.1853 44.7476 46.6582 44.7476 46.0727C44.7476 45.4871 45.0989 44.9601 45.6552 44.7258C48.6709 43.4668 50.6033 40.539 50.6033 37.289V29.2373H34.5V37.289C34.5 40.539 36.4324 43.4668 39.4481 44.7258C40.0044 44.9601 40.3557 45.4871 40.3557 46.0727C40.3557 46.6582 40.0044 47.1853 39.4481 47.4195C36.4324 48.6785 34.5 51.6064 34.5 54.8563V62.908Z';
const TopSandPath =
  'M42.4052 43.3205C39.2138 43.3205 36.6373 40.7439 36.6373 37.5525V36.4106L47.9682 39.4556L47.3826 40.4511C46.3579 42.2079 44.4548 43.3205 42.4052 43.3205ZM38.5697 38.753C39.0675 40.3926 40.5899 41.5637 42.4052 41.5637C43.43 41.5637 44.3962 41.1831 45.1282 40.5097L38.5697 38.753Z';
const BottomSandPath =
  'M48.2903 60.6535H36.7544V55.7932C36.7544 52.6018 39.331 50.0253 42.5224 50.0253C45.7138 50.0253 48.2903 52.6018 48.2903 55.7932V60.6535V60.6535ZM38.5404 58.8968H46.5628V55.7932C46.5628 53.5973 44.7768 51.782 42.5516 51.782C40.3264 51.782 38.5404 53.568 38.5404 55.7932V58.8968V58.8968Z';

const animationOrder = [BottomBarPath, TopBarPath, SideBarPath, BottomSandPath, TopSandPath];

const createPathAnimation = (paths: string[]) => {
  const length = paths.length;
  const templateString = paths.reduce(
    (p, n, i) =>
      `${p} ${Math.floor(((i + 1) / length) * 100)}% { d: path("${paths
        .slice(0, i + 1)
        .join(' ')}")} \n`,
    ''
  );
  return keyframes`${templateString}`;
};

const HourglassSVGStyle = styled.svg<{ animationDuration: string }>`
  view-box: 0 0 87 87;
  fill: #143f3d;
  path {
    animation: ${({ animationDuration }) => css`
      ${createPathAnimation(animationOrder)} ${animationDuration}s linear infinite
    `};
    animation-delay: 0.25s;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
  }
`;

interface AnimatedHourglassProps {
  diameter?: number;
  rectFill?: string;
  circleFill?: string;
  hourglassFill?: string;
  animationDuration?: string;
}

export const AnimatedHourglass = ({
  rectFill = 'none',
  circleFill = '#C7E4D6',
  hourglassFill = '143F3D',
  diameter = 87,
  animationDuration = '2.5'
}: AnimatedHourglassProps) => (
  <HourglassSVGStyle animationDuration={animationDuration}>
    <rect width={diameter} height={diameter} fill={rectFill} />
    <circle
      id="circle"
      cx={`${diameter / 2}`}
      cy={`${diameter / 2}`}
      r={`${diameter / 2}`}
      fill={circleFill}
    />
    <path fill={hourglassFill} />
  </HourglassSVGStyle>
);

export const AnimatedHourglassWrapper = ({
  rectFill,
  circleFill,
  hourglassFill,
  diameter = 87,
  animationDuration = '2.5'
}: AnimatedHourglassProps) => (
  <div
    style={{
      width: `${diameter}px`,
      minWidth: `${diameter}px`,
      height: `${diameter}px`
    }}
  >
    <AnimatedHourglass
      rectFill={rectFill}
      circleFill={circleFill}
      hourglassFill={hourglassFill}
      diameter={diameter}
      animationDuration={animationDuration}
    />
  </div>
);

export const Hourglass = () => <AnimatedHourglassWrapper animationDuration="0" />;
