import React from 'react';
import * as colors from '../../../colors';

export const DownloadIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0052 8.93514L10.75 11.1903L10.75 2.74994C10.75 2.33572 10.4142 1.99994 10 1.99994C9.5858 1.99994 9.25001 2.33572 9.25001 2.74994L9.25001 11.1904L6.9948 8.93514C6.7019 8.64225 6.22703 8.64225 5.93414 8.93514C5.64124 9.22804 5.64124 9.70291 5.93414 9.9958L9.46967 13.5313C9.76256 13.8242 10.2374 13.8242 10.5303 13.5313L14.0659 9.9958C14.3588 9.70291 14.3588 9.22804 14.0659 8.93514C13.773 8.64225 13.2981 8.64225 13.0052 8.93514Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      d="M3.75 16.4999C3.33579 16.4999 3 16.8357 3 17.2499C3 17.6642 3.33579 17.9999 3.75 17.9999H16.25C16.6642 17.9999 17 17.6642 17 17.2499C17 16.8357 16.6642 16.4999 16.25 16.4999H3.75Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
