import React from 'react';
import { colors } from '../../modules/colors';

export interface AffordabilityDetails {
  code: string;
  downPayment?: number;
  downPaymentPercent?: number;
  dti?: number;
}

export const affordabilityDetails = ({ code, downPaymentPercent, dti }: AffordabilityDetails) => {
  switch (code) {
    case 'Stretching':
      return {
        scenario: 'Stretching',
        color: `${colors.MANGO_40}`,
        details: (
          <>
            Based on your inputs, this scenario may <strong>stretch</strong> your purchase power.
            Your DTI is {dti}%, pushing the limit of 43%. To improve your chances of preapproval,
            consider paying down debts, increasing your down payment, or building up your credit
            score.
          </>
        )
      };

    case 'Aggressive':
      return {
        scenario: 'Aggressive',
        color: `${colors.PIMENTO}`,
        details: (
          <>
            Based on your inputs, lenders are <strong>unlikely</strong> to preapprove you for this
            home price. Your DTI is {dti}%. Most lenders will not issue a loan with a DTI of 43% or
            more. To improve your chances of preapproval, consider paying down debts, increasing
            your down payment, or building up your credit score.
          </>
        )
      };

    case 'Low Credit':
      return {
        scenario: 'Adjust your credit score',
        color: `${colors.LIGHT_BLUE}`,
        details: (
          <>
            Most lenders require a credit score above 620 to be approved for conventional home
            loans.
          </>
        )
      };

    case 'Small Loan Size':
      return {
        scenario: 'Increase your loan size',
        color: `${colors.LIGHT_BLUE}`,
        details: <>Most lenders have a minimum loan size of $100k.</>
      };

    case 'Low Down Payment':
      return {
        scenario: 'Increase your down payment',
        color: `${colors.LIGHT_BLUE}`,
        details: (
          <>The amount for which you can be preapproved is constrained by your down payment.</>
        )
      };

    case 'Proof of Income':
      return {
        scenario: 'Adjust your income',
        color: `${colors.LIGHT_BLUE}`,
        details: <>Qualified mortgages require some proof of income.</>
      };

    default:
      return {
        scenario: 'Affordable',
        color: `${colors.MOSS_100}`,
        details: (
          <>
            Based on your inputs, this scenario is <strong>likely to be preapproved.</strong> Your
            DTI is {dti}%, which is within the range likely to be approved by lenders. Your down
            payment is {downPaymentPercent}% of the total home price.
          </>
        )
      };
  }
};
