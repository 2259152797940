import { event } from '@rategravity/frontend/modules/user-actions';
import { ScreenSize, useScreenSize } from '@rategravity/own-up-component-library-legacy';
import Slider from 'rc-slider';
import React from 'react';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { useActionPipe } from '../../../../hooks/use-action-pipe';
import { colors } from '../../../../modules/colors';
import { SliderProps } from '../types';
import { handleStyle, Label, railStyle, trackStyle, WrapperWithOverrides } from './components';

// only display stretching label if there's enough room for it
const computeLabel = (screenSize: ScreenSize, stretchPoint: number, maxAffordable: number) => {
  const lowerBoundary = 25;
  const upperBoundary = 75;
  const mobileOffset = 13;
  if (screenSize < ScreenSize.medium) {
    if (
      stretchPoint < lowerBoundary + mobileOffset ||
      stretchPoint > upperBoundary - mobileOffset
    ) {
      return null;
    } else {
      return { [maxAffordable]: <Label>Stretching</Label> };
    }
  } else {
    if (stretchPoint < lowerBoundary || stretchPoint > upperBoundary) {
      return null;
    } else {
      return { [maxAffordable]: <Label>Stretching</Label> };
    }
  }
};

// rc-slider default behavior is for the rail to be full width while the track's width adjusts as the handle moves
// we need the opposite behavior in order for the color gradient image to maintain a full width
// this implementation creates that needed effect of the rail overlapping the track
// (combined with forcing the track width to be 100% in StyleOverride)
export const SliderComponent = React.memo(
  ({
    sliderMin,
    sliderMax,
    sliderGradient: { maxAffordable, maxStretch },
    sliderValue,
    disabled = false,
    updateSliderValue
  }: SliderProps) => {
    const screenSize = useScreenSize();
    const isMobile = useIsMobile(1024);

    const maxAffordablePercent = (maxAffordable / sliderMax) * 100;
    const maxStretchPercent = (maxStretch / sliderMax) * 100;

    const range = sliderMax - sliderMin;
    const stretchPoint = (maxStretch + maxAffordable) / 2;
    const stretchingLabelPoint = (maxStretchPercent + maxAffordablePercent) / 2;

    const handleSliderChange = (value: number) => {
      updateSliderValue({
        sliderValue: value,
        mode: 'slider'
      });
    };

    const dispatchAction = useActionPipe();
    const interactWithSlider = event('resultsInteraction-PAS', {
      action: 'sliderInteraction-PAS'
    });

    return (
      <WrapperWithOverrides isMobile={isMobile}>
        <Slider
          disabled={disabled}
          value={sliderValue}
          step={range / 50}
          min={sliderMin}
          max={sliderMax}
          marks={{
            [sliderMin - 1]: <Label>Affordable</Label>,
            ...(disabled ? null : computeLabel(screenSize, stretchingLabelPoint, stretchPoint)),
            [sliderMax + 1]: <Label>Aggressive</Label>
          }}
          handleStyle={handleStyle}
          trackStyle={{
            ...trackStyle,
            background: `linear-gradient(0.25turn, ${colors.PINE}, #92C6AD ${maxAffordablePercent}%, ${colors.MANGO}, #F5B46F, #EB8458 ${maxStretchPercent}%)`
          }}
          prefixCls="override"
          onChange={(v) => {
            handleSliderChange(v);
          }}
          onAfterChange={() => {
            dispatchAction(interactWithSlider);
          }}
        >
          <div
            style={{
              ...railStyle,
              marginLeft: `calc(${((sliderValue - sliderMin) / range) * 100}%)`
            }}
          />
        </Slider>
      </WrapperWithOverrides>
    );
  }
);
