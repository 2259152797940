import * as Sentry from '@sentry/react';
import { maybeGetToken, removeLoginToken } from '../../authentication/modules/token-storage';
import { trackingInstance } from '../1pt-tracking-instance';
import { loggedInFetch } from './fetch';

let ownUpLoginToken: string | null = null;

export const getLoginToken = (redirect: boolean = true) => {
  if (!ownUpLoginToken) {
    ownUpLoginToken = maybeGetToken().orElse(() => {
      // Redirect to www homepage if no token
      if (redirect) {
        window.location.href = `https://${process.env.DEPLOY_ENV == 'staging' ? 'staging.' : ''}www.ownup.com/`;
      }
      return '';
    });
  }
  return ownUpLoginToken;
};

export const accountLogOut = async (accountId: string, redirect = true) => {
  try {
    await loggedInFetch(
      `/api/account/${accountId}/logout`,
      {
        method: 'POST'
      },
      // on401 only return 401 status
      () => {
        return new Response(undefined, {
          status: 401,
          statusText: 'You are no longer signed in'
        });
      }
    );
  } catch (err) {
    console.error(err);
  } finally {
    removeLoginToken();
    sessionStorage.removeItem('OU_QUESTIONNAIRE_ID');
    sessionStorage.removeItem('OU_USER_ID');
    if (process.env.SENTRY_DSN) {
      Sentry.setUser(null);
      Sentry.setTag('user.userId', undefined);
    }
    trackingInstance.reset();
    if (redirect) {
      window.location.href = `https://${process.env.DEPLOY_ENV == 'staging' ? 'staging.' : ''}www.ownup.com/`;
    }
  }
};
