import {
  OwnUpDropdown,
  OwnUpFilterDropdown,
  OwnUpMenuItem
} from '@rategravity/own-up-component-library';
import React, { useState } from 'react';
import { AddressModel } from '../../../../components/address/address-helpers';
import { Tooltip } from '../tooltip';

export type DropdownOptions = {
  value: string;
  label: string;
}[];

export const StateDropdown = ({
  stateOptions,
  address,
  error,
  onChange
}: {
  stateOptions: DropdownOptions;
  address: AddressModel;
  error: string | undefined;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) => (
  <OwnUpDropdown
    label="State *"
    type="text"
    labelPosition="inner"
    value={address.state || ''}
    error={error}
    onChange={onChange}
    style={!!error ? {} : { marginBottom: '0px' }}
  >
    {stateOptions.map(({ label, value }, index) => (
      <OwnUpMenuItem key={index} value={value}>
        {label}
      </OwnUpMenuItem>
    ))}
  </OwnUpDropdown>
);

export const CountyDropdown = ({
  address,
  countyOptions,
  onChange,
  disabled
}: {
  address: AddressModel;
  countyOptions: string[];
  onChange: (event: React.ChangeEvent<{}>, selectedValue: any) => void;
  disabled: boolean;
}) => {
  const [openCountyTooltip, setOpenCountyTooltip] = useState(false);
  const handleCountyTooltipClose = () => {
    setOpenCountyTooltip(false);
  };
  const handleCountyTooltipOpen = () => {
    if (!address.state) {
      setOpenCountyTooltip(true);
    }
  };
  return (
    <Tooltip
      open={openCountyTooltip}
      onClose={handleCountyTooltipClose}
      onOpen={handleCountyTooltipOpen}
      description="Please select a state."
    >
      <OwnUpFilterDropdown
        label="County"
        labelPosition="inner"
        value={address.county || ''}
        onChange={onChange}
        disabled={disabled}
        style={{ marginBottom: '0px' }}
        options={countyOptions}
      />
    </Tooltip>
  );
};
