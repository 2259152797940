import { affordableDTIMargin, aggressiveDTIMargin, maxDTI, monthlyPayments } from './constants';
import { pmiTable } from './pmi-table';

export interface ComputeSliderConstraintsArgs {
  annualIncome: number;
  downPayment: number;
  monthlyDebt: number;
  approximateCreditScore: number;
  annualInterestRate: number;
  annualPropertyTaxRate: number;
  monthlyFee: number;
  monthlyHomeownersInsurance: number;
  jumboLoanLimit: number;
  specialScenario?: number | null;
  loanTerm?: number;
  conformingLoanLimit: number;
}

export const computeSliderConstraints = ({
  annualIncome,
  downPayment,
  monthlyDebt,
  approximateCreditScore,
  annualInterestRate,
  annualPropertyTaxRate,
  monthlyFee,
  monthlyHomeownersInsurance,
  loanTerm = 30,
  jumboLoanLimit,
  conformingLoanLimit
}: ComputeSliderConstraintsArgs) => {
  const monthlyIncome = annualIncome / 12;

  const baseDTI = monthlyDebt / monthlyIncome;

  if (approximateCreditScore < 620 || baseDTI > maxDTI) {
    return {
      sliderMin: null,
      sliderMax: null,
      sliderGradient: {
        maxAffordable: null,
        maxStretch: null
      }
    };
  }

  const pMIRates = pmiTable[approximateCreditScore];

  // Using 1200 instead of 12 since we're using annual percentage form (i.e 1.5%).
  const monthlyInterestRate = annualInterestRate / 1200;

  const preliminaryMonthlyPropertyTax = downPayment * (annualPropertyTaxRate / 1200);

  const estimatedMonthlyDebt =
    (monthlyDebt || 0) +
    (monthlyFee || 0) +
    (monthlyHomeownersInsurance || 0) +
    (preliminaryMonthlyPropertyTax || 0);

  // Monthly payment constant calculation comes from https://www.vertex42.com/ExcelArticles/amortization-calculation.html
  // (r * (1 + r)^n) / ((1+r)^n - 1) where r = monthlyInterestRate and n = monthlyPayments (from constants)
  const monthlyPaymentConstant =
    (monthlyInterestRate * Math.pow(1 + monthlyInterestRate, monthlyPayments)) /
    (Math.pow(1 + monthlyInterestRate, monthlyPayments) - 1);

  // calculate maximumLoanAllowance --> sliderMax
  const maximumLoanAllowance =
    monthlyIncome * (maxDTI + aggressiveDTIMargin) - estimatedMonthlyDebt;

  // calculate loanAllowance --> sliderGradient.maxStretch
  const loanAllowance = monthlyIncome * maxDTI - estimatedMonthlyDebt;

  // calculate minimumLoanAllowance --> sliderGradient.maxAffordable
  const minimumLoanAllowance =
    monthlyIncome * (maxDTI + affordableDTIMargin) - estimatedMonthlyDebt;

  const minimumDownPaymentPercentages = Object.keys(pMIRates).map(Number);
  const calculatedSliderValues = minimumDownPaymentPercentages.map(
    (minimumDownPaymentPercentage) => {
      const fixedRate = loanTerm && loanTerm <= 20 ? 20 : 30;
      // find the annual PMI rate for current minimum down payment percentage
      const annualPMIRate = pMIRates[minimumDownPaymentPercentage][fixedRate];

      const calculateProjectedValue = (allowance: number) => {
        // calculate projected loan value based on loan allowance, amortization, pMIRate, and annualPropertyTaxRate
        const projectedLoan =
          allowance /
          (monthlyPaymentConstant + annualPMIRate / 1200 + annualPropertyTaxRate / 1200);
        // calculate down payment constraint based on jumboLoan or highBalanceLoan else minimumDownPaymentPercentage
        let downPaymentConstraint: number;
        if (projectedLoan > conformingLoanLimit) {
          // High Balance loans require a minimum down payment of 5%
          downPaymentConstraint = Math.max(0.05, minimumDownPaymentPercentage);
        } else if (projectedLoan > jumboLoanLimit) {
          // Jumbo loans require a minimum down payment of 20%
          downPaymentConstraint = Math.max(0.2, minimumDownPaymentPercentage);
        } else {
          downPaymentConstraint = minimumDownPaymentPercentage;
        }

        return Math.min(downPayment / downPaymentConstraint, projectedLoan + downPayment);
      };

      // calculate sliderMax with maxLoanAllowance
      const sliderMax = calculateProjectedValue(maximumLoanAllowance);

      // calculate  maxStretch for slider gradient with loanAllowance
      const sliderMaxStretch = calculateProjectedValue(loanAllowance);

      // calculate maxAffordable for slider gradient with minimumLoanAllowance
      const sliderMaxAffordable = calculateProjectedValue(minimumLoanAllowance);

      return [sliderMax, sliderMaxStretch, sliderMaxAffordable];
    }
  );

  // Find the largest values for max, maxAffordable, and maxStretch in 2D array
  const { max, maxAffordable, maxStretch } = calculatedSliderValues.reduce(
    (acc, cur) => {
      if (cur[0] > acc.max) {
        acc.max = cur[0];
      }

      if (cur[1] > acc.maxStretch) {
        acc.maxStretch = cur[1];
      }

      if (cur[2] > acc.maxAffordable) {
        acc.maxAffordable = cur[2];
      }

      return acc;
    },
    { max: 0, maxAffordable: 0, maxStretch: 0 }
  );

  return {
    sliderMin: downPayment,

    // In cases of low income and high down payment, do not let sliderMax < sliderMin.
    sliderMax: Math.max(Math.round(100 * max) / 100, downPayment),
    sliderGradient: {
      maxAffordable: Math.round(100 * maxAffordable) / 100 || downPayment,
      maxStretch: Math.round(100 * maxStretch) / 100 || downPayment
    }
  };
};
