import { createSelector } from 'reselect';
import { isConsult } from '../../modules/account/type';
import {
  hasConsultPitchSelector,
  incompleteQuestionnaireSelector,
  latestExperienceSelector,
  pitchScenarioSelector,
  preapprovalsSelector,
  publishedRatesSelector,
  TARGET_ANYWHERE_ID,
  TARGET_OWN_UP_ID
} from '../../redux/manifest';
import { State as ManifestState } from '../manifest/state';
import { questionnaireStateSelector } from '../questionnaire-state/selector';
import { ApplicationState } from '../state';

/**
 * Returns whether or not any preapprovals are published
 */
const hasPreapprovalsSelector = createSelector(preapprovalsSelector, (preapprovals) =>
  preapprovals.some((x) => x.length > 0)
);

/**
 * Returns whether or not any rate quotes are published
 */
const hasPublishedRatesSelector = createSelector(publishedRatesSelector, (rateQuotes) =>
  rateQuotes.some((x) => x.length > 0)
);

/**
 * Returns true if there was an error loading the manifest
 */
const hasManifestErrorSelector = createSelector(
  ({ manifest }: ApplicationState): ManifestState => manifest,
  (manifest) => !manifest.loading && !manifest.success
);

/**
 * Returns true if there is an upcoming consult in the account that is not yet complete
 */
const hasUpcomingConsultSelector = createSelector(latestExperienceSelector, (optionalDataPoint) => {
  const consultEndDate = optionalDataPoint
    .map((dataPoint) => (isConsult(dataPoint) ? dataPoint.endDate : 0))
    .orElse(() => 0);
  return consultEndDate && consultEndDate > Date.now();
});

/**
 * Returns true if there is a consult in the account that is in the past
 */
const hasCompletedConsultSelector = createSelector(
  latestExperienceSelector,
  (optionalDataPoint) => {
    const consultEndDate = optionalDataPoint
      .map((dataPoint) => (isConsult(dataPoint) ? dataPoint.endDate : 0))
      .orElse(() => 0);
    return consultEndDate && consultEndDate < Date.now();
  }
);

/**
 * Returns true if there's a questionnaire in progress _and_ it
 *   has at least one node submitted.
 */
const hasQuestionnaireInProgressSelector = createSelector(
  incompleteQuestionnaireSelector,
  questionnaireStateSelector,
  (optionalInProgressQuestionnaire, questionnaireProgress) =>
    optionalInProgressQuestionnaire
      .map((inProgressQuestionnaire) => !!inProgressQuestionnaire)
      .orElse(() => false) && (questionnaireProgress?.percentComplete || 0) >= 0
);

/**
 * Returns true if the current pitch experience includes Anywhere. Note that this
 *   will return true if there are other targets as well, so you'll want to handle
 *   those cases separately.
 */
const hasAnywherePitchSelector = createSelector(pitchScenarioSelector, (pitchData) =>
  pitchData
    .map((x) => x?.leadTargets.some((y) => y.targetName === TARGET_ANYWHERE_ID))
    .orElse(() => false)
);

/**
 * Returns true if there are _only_ non-Own Up lenders. Note that this can return
 *   true if it's just Anywhere, so you'll need to check for that separately.
 */
const hasLenderDirectPitchSelector = createSelector(pitchScenarioSelector, (pitchData) =>
  pitchData
    .map(
      (x) =>
        (x?.leadTargets.length || 0) > 0 &&
        !x!.leadTargets.some((y) => y.targetName === TARGET_OWN_UP_ID)
    )
    .orElse(() => false)
);

/**
 * Returns true if the current pitch experience has no targets.
 */
const hasIneligiblePitchSelector = createSelector(pitchScenarioSelector, (pitchData) =>
  pitchData.map((x) => x?.leadTargets.length === 0).orElse(() => false)
);

/**
 * Selector that returns what type of home page the user should see.
 *
 * In some instances we don't want to let the user see the usual home page
 *   and instead force them into some other action.
 */
export const suggestedHomePageSelector = createSelector(
  hasManifestErrorSelector,
  hasPreapprovalsSelector,
  hasPublishedRatesSelector,
  hasUpcomingConsultSelector,
  hasCompletedConsultSelector,
  hasQuestionnaireInProgressSelector,
  hasAnywherePitchSelector,
  hasConsultPitchSelector,
  hasLenderDirectPitchSelector,
  hasIneligiblePitchSelector,
  (
    hasManifestError,
    hasPreapprovals,
    hasPublishedRates,
    hasUpcomingConsult,
    hasCompletedConsult,
    hasQuestionnaireInProgress,
    hasAnywherePitch,
    hasConsultPitch,
    hasLenderDirectPitch,
    hasIneligiblePitch
  ) => {
    // Force the how-to-prepare page if the shopper is there.
    if (window.location.href.includes('/how-to-prepare')) {
      return 'how-to-prepare';
    }
    // Throw an error if the manifest failed to load.
    if (hasManifestError) {
      return 'error-page';
    }
    // If the user has no published artifacts...
    if (!hasPublishedRates && !hasPreapprovals) {
      // ... determine which specialized home page to show.
      if (hasQuestionnaireInProgress) {
        return 'questionnaire-in-progress';
      } else if (hasUpcomingConsult) {
        return 'how-to-prepare';
      } else if (hasCompletedConsult) {
        return 'default';
      } else if (hasAnywherePitch) {
        return 'anywhere-referral';
      } else if (hasConsultPitch) {
        return 'concierge';
      } else if (hasLenderDirectPitch) {
        return 'lender-direct';
      } else if (hasIneligiblePitch) {
        return 'ineligible';
      }
    }
    return 'default';
  }
);
