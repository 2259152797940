export interface SliderConstraintsArgs {
  sliderMin: number;
  sliderMax: number;
  homePrice: number;
}

export const computeSliderValue = ({ sliderMin, sliderMax, homePrice }: SliderConstraintsArgs) => {
  if (homePrice < sliderMin) {
    return sliderMin;
  }
  if (homePrice > sliderMax) {
    return sliderMax;
  }
  return homePrice;
};
