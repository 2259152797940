import React from 'react';
import { useSelector } from 'react-redux';
import { LoadingLogo } from '../../../components/loading';
import { loadingSelector } from '../../redux/selectors';

export const LoadingPage = ({ children }: { children: JSX.Element }) => {
  const loading = useSelector(loadingSelector);

  return loading ? <LoadingLogo /> : <div>{children}</div>;
};
