interface AnnualPMIRate {
  ['30']: number;
  ['20']: number;
}

interface RatesForMinDownPayment {
  [minimumDownPaymentPercentage: number]: AnnualPMIRate;
}

interface PMIEntry {
  [approximateCreditScore: number]: RatesForMinDownPayment;
}

// The top-level field for each entry is the minimum credit score.
// The top-level field for each particular rate is the minium down payment percentage, expressed as a decimal.
export const pmiTable: PMIEntry = {
  /* eslint-disable @typescript-eslint/naming-convention */
  720: {
    0.2: {
      30: 0.22,
      20: 0.23
    },
    0.15: {
      30: 0.32,
      20: 0.37
    },
    0.1: {
      30: 0.59,
      20: 0.52
    },
    0.05: {
      30: 0.7,
      20: 0.52
    }
  },
  680: {
    0.2: {
      30: 0.27,
      20: 0.26
    },
    0.15: {
      30: 0.46,
      20: 0.51
    },
    0.1: {
      30: 0.87,
      20: 0.77
    },
    0.05: {
      30: 0.98,
      20: 0.78
    }
  },
  620: {
    0.2: {
      30: 0.42,
      20: 0.41
    },
    0.15: {
      30: 0.73,
      20: 0.89
    },
    0.1: {
      30: 1.25,
      20: 1.27
    },
    0.05: {
      30: 1.5,
      20: 1.32
    }
  }
  /* eslint-enable @typescript-eslint/naming-convention */
};
