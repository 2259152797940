import React from 'react';
import { useIsMobile } from '../../../../../hooks/is-mobile';
import {
  UpdateHomePriceAction,
  UpdateHomePricePayloadType
} from '../../../../redux/simulator/inputs/actions';
import { InputLabel, InputWrapper, InternalNumberInput } from '../../inputs/components';

export const HomePriceInput = ({
  value,
  updateHomePrice,
  id = 'homePrice'
}: {
  value: number | undefined;
  updateHomePrice: (payload: UpdateHomePricePayloadType) => UpdateHomePriceAction;
  id?: string;
}) => {
  const isMobile = useIsMobile(1024);
  return (
    <InputWrapper isMobile={isMobile}>
      <InputLabel aria-hidden>$</InputLabel>
      <InternalNumberInput
        id={id}
        onChange={(input: number | undefined) => {
          if (input) {
            updateHomePrice({ homePrice: input, mode: 'inputs' });
          }
        }}
        value={value}
        positive
      />
    </InputWrapper>
  );
};
