import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import { colors } from '../../../../modules/colors';
import { MediumGreenText } from '../typography';

const AffordabilityRangeStyle = ({ color }: { color: string }) =>
  createOwnUpStyle({
    marginBottom: '20px',
    borderRadius: '15px',
    width: '310px',
    padding: '20px 15px',
    backgroundColor: color,
    boxSizing: 'border-box'
  });

export const AffordabilityRange = createOwnUpComponent('div', AffordabilityRangeStyle);

export const ButtonContentContainer = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    display: 'flex',
    width: '310px',
    padding: '10px 0',
    margin: 'auto',
    cursor: 'pointer',
    boxSizing: 'border-box'
  })
);

export const ContentContainer = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 0,
    boxShadow: `3px 0 0 0 ${colors.LIGHT_GREY_TWO}`,
    padding: '16px 24px 0',
    backgroundColor: colors.WHITE,
    boxSizing: 'border-box'
  })
);

export const DrawerContents = createOwnUpComponent('div', ({ isOpen }: { isOpen: boolean }) =>
  createOwnUpStyle({
    display: isOpen ? 'initial' : 'none'
  })
);

export const DrawerTopContainer = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    columnGap: '20px',
    marginBottom: '16px',
    width: '310px'
  })
);

export const DrawerWrapper = createOwnUpComponent(
  'div',
  ({ isOpen, isShortViewport }: { isOpen: boolean; isShortViewport: boolean }) =>
    createOwnUpStyle({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      transition: 'height 0.2s ease',
      backgroundColor: colors.WHITE,
      zIndex: 25,
      borderTopRightRadius: '15px',
      borderTopLeftRadius: '15px',
      ...(isOpen
        ? {
            borderTop: `4px solid ${colors.WHITE}`,
            height: '100vh',
            overflow: 'auto',
            boxSizing: 'border-box'
          }
        : {
            height: isShortViewport ? '48px' : '190px',
            overflow: 'hidden',
            right: 0,
            paddingBottom: isShortViewport ? 0 : '15px'
          })
    })
);

export const PriceContainer = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    display: 'flex',
    flexDirection: 'column'
  })
);

export const SeeMoreButton = createOwnUpComponent(
  'button',
  createOwnUpStyle({
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    width: '100%',
    height: '48px',
    clear: 'both',
    position: 'sticky',
    top: 0,
    zIndex: 110, // Accommodates the thumb on the slider.
    backgroundColor: colors.EUCALYPTUS_TWO
  })
);

export const SpacedGreenText = createOwnUpComponent(
  MediumGreenText,
  ({ isOpen }: { isOpen: boolean }) =>
    createOwnUpStyle({
      marginLeft: '16px',
      ...(isOpen ? { paddingBottom: '2px' } : {})
    })
);
