import { USStateMapping } from '@rategravity/core-lib/enums';
import React, { Fragment, useCallback } from 'react';
import { AddressModel } from '../../../components/address/address-helpers';
import { AddressFuzzyMatchCountyType } from '../../../components/address/fuzzy-match-service';
import { AddressChangeDispatch } from '.';
import { AddressGrid } from './address-grid/address-grid';
import { CountyGrid } from './address-grid/county-grid';
import { DropdownOptions } from './address-grid/state-county';

const usStates: DropdownOptions = USStateMapping.map(({ name, abbreviation }) => ({
  value: abbreviation,
  label: name
}));

export type AddressFormProps = {
  address: AddressModel;
  addressChangeDispatcher: AddressChangeDispatch;
  stateOptions: DropdownOptions;
  countyOptions: string[];
  addressFuzzyMatchCounty: AddressFuzzyMatchCountyType;
  stateError: string | null;
};

export const AddressForm = ({
  address,
  addressChangeDispatcher,
  stateOptions = usStates,
  countyOptions,
  addressFuzzyMatchCounty,
  stateError
}: AddressFormProps) => {
  const handleFullAddressChange = useCallback(
    (newValue: AddressModel | undefined) => {
      addressChangeDispatcher({ ...address, ...newValue }, true);
    },
    [addressChangeDispatcher, address]
  );

  const handleAddressFieldChange = useCallback(
    (field: keyof AddressModel) => (newValue: unknown | undefined, isValidating?: boolean) => {
      addressChangeDispatcher(
        {
          ...address,
          [field]: newValue
        },
        isValidating
      );
    },
    [addressChangeDispatcher, address]
  );
  return (
    <Fragment>
      <AddressGrid
        addressModel={address}
        states={stateOptions}
        onAutocompleteChange={handleFullAddressChange}
        onAddressChange={handleAddressFieldChange('address')}
        onUnitChange={handleAddressFieldChange('unit')}
        onCityChange={handleAddressFieldChange('city')}
        onStateChange={(newValue: string | undefined) => {
          addressChangeDispatcher(
            {
              ...address,
              county: undefined,
              state: newValue
            },
            true
          );
        }}
        onZipChange={handleAddressFieldChange('zip')}
        addressFuzzyMatchCounty={addressFuzzyMatchCounty}
        stateError={stateError}
      />
      <CountyGrid
        addressModel={address}
        counties={countyOptions || []}
        onCountyChange={handleAddressFieldChange('county')}
      />
    </Fragment>
  );
};
