import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { ConnectedAffordabilityDetails } from '../../../views/simulator/affordability-details';
import { ConnectedNextStep } from '../../../views/simulator/next-step';
import { ConnectedPieChart } from '../../../views/simulator/pie-chart';
import { ConnectedSlider } from '../../../views/simulator/slider';

export const ResultsWrapper = createOwnUpComponent(
  OwnUpBox,
  ({ isMobile }: { isMobile?: boolean }) =>
    createOwnUpStyle({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      ...(isMobile ? { boxSizing: 'border-box', width: '312px' } : {})
    })
);

export const Results = ({ isMobile }: { isMobile?: boolean }) => (
  <ResultsWrapper isMobile={isMobile}>
    <ConnectedSlider />
    <ConnectedAffordabilityDetails isDrawerCollapsed={false} />
    <ConnectedPieChart />
    <ConnectedNextStep />
  </ResultsWrapper>
);
