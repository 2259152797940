import {
  buildPIIDeclaration,
  Decrypted,
  EncryptedField,
  encryptedFieldConverter
} from '@rategravity/encryption-lib';
import * as t from 'type-shift';

const creditRating = ['Excellent' as const, 'Good' as const, 'Fair' as const, 'Poor' as const];
type CreditRating = (typeof creditRating)[number];

const propertyType = [
  'SingleFamily' as const,
  'MultiFamily' as const,
  'Condo' as const,
  'Manufactured' as const,
  'Townhome' as const,
  'NewConstruction' as const,
  'Mobile' as const
];
type PropertyType = (typeof propertyType)[number];

const propertyUse = ['Primary' as const, 'Secondary' as const, 'Investment' as const];
type PropertyUse = (typeof propertyUse)[number];

const loanPurpose = ['Purchase' as const, 'Refinance' as const];
type LoanPurpose = (typeof loanPurpose)[number];

/**
 * We may have more information available to us, but this is the
 * only information that we should be sending for non-concierge leads.
 *
 * Source: {@link https://docs.google.com/spreadsheets/d/1kWbpgyb4lI3SsGtq9ArUfvxwRJDhY3v7j7VoIfzr3wU/edit#gid=0}
 */
export interface EncryptedUnifiedLeadPayload {
  // -- Lead tracking information
  /* Unique Id for this lead */
  leadID: string;
  /** What the lead will cost the target(s) */
  leadCost: number;

  firstName: string | EncryptedField;
  lastName: string | EncryptedField;
  email: string | EncryptedField;
  phoneNumber: string | EncryptedField;
  creditRating: CreditRating;
  vaEligible: boolean;
  /** If this is a first-time home buyer scenario */
  fthb?: boolean;

  // -- Property information
  propertyStreet?: string | EncryptedField;
  propertyCity?: string;
  propertyState: string;
  propertyCounty?: string;
  propertyZip?: string;
  /** How much the property is worth */
  propertyValue: number;
  /** Type of property in question */
  propertyType: PropertyType;
  /** How the property is being used */
  propertyUse: PropertyUse;

  // -- Loan information
  /** Type of transaction */
  loanPurpose: LoanPurpose;
  /** Size of the loan */
  loanAmount: number;

  /** Has an accepted offer */
  hasAcceptedOffer?: boolean;

  cashOutAmount?: number;
  currentMortgageBalance?: number;
  /**
   * Percentage is stored like a double, so 3.5% would be stored as 3.5.
   */
  currentMortgageRate?: number;

  // -- Universal Lead identification
  jornayaLeadId?: string;
  trustedFormCertificateUrl?: string;
}

export type UnifiedLeadPayload = Decrypted<EncryptedUnifiedLeadPayload>;

export const unifiedLeadPayloadConverter = t.strict<EncryptedUnifiedLeadPayload>({
  leadID: t.string,
  leadCost: t.number,

  firstName: t.string.or(encryptedFieldConverter),
  lastName: t.string.or(encryptedFieldConverter),
  email: t.string.or(encryptedFieldConverter),
  phoneNumber: t.string.or(encryptedFieldConverter),
  creditRating: t.oneOf(creditRating),
  vaEligible: t.boolean,
  fthb: t.optional(t.boolean),

  propertyStreet: t.optional(t.string.or(encryptedFieldConverter)),
  propertyCity: t.optional(t.string),
  propertyState: t.string,
  propertyCounty: t.optional(t.string),
  propertyZip: t.optional(t.string),
  propertyValue: t.number,
  propertyType: t.oneOf(propertyType),
  propertyUse: t.oneOf(propertyUse),

  loanPurpose: t.oneOf(loanPurpose),
  loanAmount: t.number,

  hasAcceptedOffer: t.optional(t.boolean),

  cashOutAmount: t.optional(t.number),
  currentMortgageBalance: t.optional(t.number),
  currentMortgageRate: t.optional(t.number),

  jornayaLeadId: t.optional(t.string),
  trustedFormCertificateUrl: t.optional(t.string)
});

/**
 * PII declaration for the {@link UnifiedLeadPayload}
 */
export const unifiedLeadPayloadPIIDeclaration = buildPIIDeclaration({
  firstName: true,
  lastName: true,
  email: true,
  phoneNumber: true,
  propertyStreet: true
});
