import { all, select, takeLatest } from 'redux-saga/effects';
import { Generate, GenerateState, Preapproval, PreapprovalIdProperties } from './state';

const collesceNumbers = (...numbers: (number | null)[]) =>
  numbers.find((num) => num != null && !Number.isNaN(num) && num > 0) || 0;

export const getUiAmounts = (data: Generate & PreapprovalIdProperties, state: GenerateState) => {
  const { constraintsId } = data;
  return {
    downPaymentAmount: collesceNumbers(
      data.downPaymentAmount,
      state.downPaymentAmount,
      parseFloat(localStorage.getItem(`${constraintsId}/downPaymentAmount`) || '0')
    ),
    preapprovalAmount: collesceNumbers(
      data.preapprovalAmount,
      state.preapprovalAmount,
      parseFloat(localStorage.getItem(`${constraintsId}/preapprovalAmount`) || '0')
    ),
    defaultDownPayment:
      data.downPaymentAmount ||
      parseFloat(localStorage.getItem(`${data.constraintsId}/downPaymentAmount`) || '0'),
    defaultPreapprovalAmount:
      data.preapprovalAmount ||
      parseFloat(localStorage.getItem(`${data.constraintsId}/preapprovalAmount`) || '0')
  };
};

export function* handleAfterReducer() {
  const {
    preapprovals: {
      data: { constraintsId } = {} as Preapproval,
      generate: { downPaymentAmount, preapprovalAmount } = {} as GenerateState
    }
  } = yield select();

  if (downPaymentAmount != null && !Number.isNaN(downPaymentAmount)) {
    localStorage.setItem(`${constraintsId}/downPaymentAmount`, downPaymentAmount.toString());
  }
  if (preapprovalAmount != null && !Number.isNaN(preapprovalAmount)) {
    localStorage.setItem(`${constraintsId}/preapprovalAmount`, preapprovalAmount.toString());
  }
}

// todo: find out what actions would trigger a change in downPaymentAmount or preapprovalAmount and key off those actions instead of a wildcard
export function* afterReducerSaga() {
  yield all([takeLatest('*', handleAfterReducer)]);
}
