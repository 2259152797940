import { all, Optional } from '@rategravity/core-lib/optional';
import { rate as formatRate } from '@rategravity/frontend/modules/numbers';
import { createSelector } from 'reselect';
import { FeeBreakdownProps } from '../../components/fee-breakdown';
import { OfferSummaryProps } from '../../components/offer-summary';
import { getFixedAndAdjustable, getLoanType } from '../../modules/loan-type';
import { Offer } from '../state';
import { getFeeDescription } from './fee-descriptions';
import { offerSelector, rateQuoteSelector } from './rate-quote';

interface OfferDetails
  extends Omit<OfferSummaryProps, 'lockStatusProps' | 'compareProps'>,
    FeeBreakdownProps {}

const feeGroupSelector = (fees: Offer['allFees'], groupName: string, isLicensedState: boolean) =>
  (fees || [])
    .filter(({ group, value }) => group === groupName && !!value)
    .map(({ type, modifiers, value, name, ...fee }) => ({
      ...fee,
      value,
      description: getFeeDescription({ type, modifiers, value }, isLicensedState),
      name: `${name}${modifiers.includes('optional') ? ' †' : ''}${
        modifiers.includes('estimate') ? '**' : ''
      }`
    }));

export const offerDetailsSelector = createSelector(
  rateQuoteSelector,
  offerSelector,
  (rates, optionalOffer): Optional<OfferDetails> =>
    all([rates, optionalOffer]).map(
      ([rq, offer]): OfferDetails => ({
        ...offer,
        purchasePrice: rq.purchasePrice,
        existingLoanSize: rq.existingLoanSize,
        loanType: getLoanType(offer.loanType),
        lenderFees: feeGroupSelector(offer.allFees, 'LenderFee', rq.isLicensedState),
        loanServiceFees: feeGroupSelector(offer.allFees, 'LoanServices', rq.isLicensedState),
        titleFees: feeGroupSelector(offer.allFees, 'Title', rq.isLicensedState),
        govFees: feeGroupSelector(offer.allFees, 'TaxesGov', rq.isLicensedState),
        prepaidFees: feeGroupSelector(offer.allFees, 'PrepaidsEscrows', rq.isLicensedState),
        hasEstimates:
          (offer.allFees || []).some(({ modifiers }) => modifiers.includes('estimate')) ||
          !!offer.closingFinanced ||
          (!!rq.homeInsurance && !!offer.downPayment),
        hasOptionals: (offer.allFees || []).some(({ modifiers }) => modifiers.includes('optional')),
        homeInsurance: {
          value: rq.homeInsurance,
          description:
            'An estimate of your monthly home insurance payment. This is not paid to your lender, but to your home insurance provider of your choosing. This is included to give a sense of much you may spend each month on your home in total.'
        },
        realEstateTax: {
          value: rq.realEstateTax,
          description:
            'An estimate of your monthly tax obligation. This is not paid to your lender, but to your county’s tax office. This is included to give a sense of how much you may spend each month on your home in total.'
        },
        pAndI: {
          value: offer.pAndI,
          description:
            'The portion of your monthly payment that goes to paying back the money you borrowed, plus interest paid to the lender.'
        },
        monthlyMI: {
          value: offer.monthlyMI.value,
          name: offer.monthlyMI.label,
          description:
            "An additional monthly fee that's typically applied when the down payment is less than 20%. Mortgage Insurance can be removed at your request if your loan balance on your mortgage falls below 80% of the purchase price of your home."
        },
        condoFee: rq.condoFee,
        cashOutValue: offer.cashOutValue || 0,
        initialCap: {
          value: offer.initialCap,
          description: `How much your rate can increase the first time after the fixed period ends. After ${
            getFixedAndAdjustable(offer.loanType).fixedPeriod
          } this rate could initially increase from ${formatRate(offer.rate, 3)} to ${formatRate(
            offer.rate + offer.initialCap,
            3
          )}.`
        },
        periodicCap: {
          value: offer.periodicCap,
          description: `How much your rate can increase during the adjustment periods. Every ${
            getFixedAndAdjustable(offer.loanType).adjustmentPeriod
          } this rate could increase by up to ${formatRate(offer.periodicCap, 0)}.`
        },
        lifetimeCap: {
          value: offer.lifetimeCap,
          description: `How much your rate can increase in total through the life of the loan. Over the life of the loan this rate could increase from ${formatRate(
            offer.rate,
            3
          )} to a maximum of ${formatRate(offer.rate + offer.lifetimeCap, 3)}.`
        }
      })
    )
);
