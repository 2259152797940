import { OwnUpGridItem } from '@rategravity/own-up-component-library';
import React, { Fragment } from 'react';
import { AddressFormProps } from './address-form';
import { CountyDropdown, StateDropdown } from './address-grid/state-county';
import { Header } from './header';

const InnerGrid = ({ children }: { children: JSX.Element }) => (
  <OwnUpGridItem xs={12} sm={10} md={6}>
    {children}
  </OwnUpGridItem>
);

const lookingAddressHeader = 'Please select a state.';

const lookingAddressBody = `Ensure the proper formatting of your preapproval letter by indicating the state you’re shopping in.`;

export const JustLookingIntentForm = ({
  address,
  stateOptions,
  countyOptions,
  addressChangeDispatcher,
  stateError
}: AddressFormProps) => (
  <Fragment>
    {stateOptions.length > 1 && (
      <Fragment>
        <Header header={lookingAddressHeader} body={lookingAddressBody} includeTopPadding={false} />
        <InnerGrid>
          <StateDropdown
            stateOptions={stateOptions}
            address={address}
            error={stateError || undefined}
            onChange={(e) => addressChangeDispatcher({ state: e.target.value as string })}
          />
        </InnerGrid>
      </Fragment>
    )}
    <Header
      header={'Are you looking in a specific county?'}
      body={'Your preapproval letter will be more accurate if you specify the county.'}
    />
    <InnerGrid>
      <CountyDropdown
        address={address}
        countyOptions={countyOptions}
        onChange={(_, selectedValue) =>
          addressChangeDispatcher({ ...address, county: selectedValue })
        }
        disabled={countyOptions.length === 0}
      />
    </InnerGrid>
  </Fragment>
);
