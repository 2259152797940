import { StandardizedResponse, standardizeResponse } from '../authentication';
import { loggedInFetch } from '../authentication';
import { manifestConverter, ManifestResponse } from './type';

export type StandardizedManifestResponse = StandardizedResponse<ManifestResponse>;

export const fetchAccountManifest = async ({
  accountId
}: {
  accountId?: string;
}): Promise<StandardizedManifestResponse> =>
  standardizeResponse(
    await loggedInFetch(`/api/account/${accountId}/manifest`, {
      method: 'GET'
    }),
    manifestConverter
  );
