import { all, put, select, takeEvery } from 'redux-saga/effects';
import { constantsSelector } from '../../../../redux/constants/selector';
import { State as ConstantsState } from '../../../../redux/constants/slice';
import { computeMonthlyPayment } from '../../../modules/simulator/compute-monthly-payment';
import { computeSliderConstraints } from '../../../modules/simulator/compute-slider-constraints';
import { computeSliderValue } from '../../../modules/simulator/compute-slider-value';
import { computeSpecialScenario } from '../../../modules/simulator/compute-special-scenario';
import { INPUTS_KEY, maxDTI } from '../../../modules/simulator/constants';
import { updateMonthlyPaymentActionCreator } from '../monthly-payment/actions';
import { updateSliderConstraintsActionCreator } from '../slider-constraints/actions';
import { updateSpecialScenarioActionCreator } from '../special-scenario/actions';
import { HIGH_DTI_SCENARIO, LOW_DOWN_PAYMENT_SCENARIO } from '../special-scenario/state';
import { UPDATE_INPUTS_ACTION_TYPE, updateHomePriceActionCreator } from './actions';
import { inputsSelector } from './selectors';
import { InputsState } from './state';

export function* handleUpdateInputsAction() {
  try {
    let {
      homePrice,
      annualIncome,
      downPayment,
      monthlyDebt,
      approximateCreditScore,
      annualInterestRate,
      annualPropertyTaxRate,
      monthlyFee,
      monthlyHomeownersInsurance,
      jumboLoanLimit
    }: InputsState = yield select(inputsSelector);
    const { conformingLoanLimit }: ConstantsState = yield select(constantsSelector);

    homePrice = homePrice || 0;
    annualIncome = annualIncome || 0;
    downPayment = downPayment || 0;
    monthlyDebt = monthlyDebt || 0;
    approximateCreditScore = approximateCreditScore || 0;
    annualInterestRate = annualInterestRate || 0;
    annualPropertyTaxRate = annualPropertyTaxRate || 0;
    monthlyFee = monthlyFee || 0;
    monthlyHomeownersInsurance = monthlyHomeownersInsurance || 0;
    jumboLoanLimit = jumboLoanLimit || conformingLoanLimit + 1;

    const newSliderConstraints = computeSliderConstraints({
      annualIncome,
      downPayment,
      monthlyDebt,
      approximateCreditScore,
      annualInterestRate,
      annualPropertyTaxRate,
      monthlyFee,
      monthlyHomeownersInsurance,
      conformingLoanLimit,
      jumboLoanLimit
    });

    const { sliderMin, sliderMax } = newSliderConstraints;

    const newSpecialScenario = computeSpecialScenario({
      annualIncome,
      downPayment,
      homePrice,
      maxDTI,
      approximateCreditScore,
      monthlyDebt,
      sliderConstraints: newSliderConstraints
    });

    // In the case that down payment constrains the slider,
    // set the new home value as the 20x the down payment (dp = 5%)
    // only on initial load (if no inputs are cached).
    const cachedInputs = sessionStorage.getItem(INPUTS_KEY);
    if (!cachedInputs && newSpecialScenario === LOW_DOWN_PAYMENT_SCENARIO) {
      // down payment is below 5%, adjust home price to make down payment 5% of loan
      homePrice = downPayment * 20;
      yield put(
        updateHomePriceActionCreator({
          homePrice,
          mode: 'specialConstraints'
        })
      );
    }

    const newSliderValue =
      typeof sliderMin === 'number' && typeof sliderMax === 'number'
        ? computeSliderValue({ homePrice, sliderMin, sliderMax })
        : null;

    yield put(
      updateSliderConstraintsActionCreator({
        ...newSliderConstraints,
        sliderValue: newSliderValue
      })
    );

    const newMonthlyPayment =
      newSpecialScenario === HIGH_DTI_SCENARIO || newSpecialScenario === LOW_DOWN_PAYMENT_SCENARIO
        ? {
            monthlyPAndI: null,
            monthlyPropertyTax: null,
            monthlyHomeownersInsurance: null,
            monthlyFee: null,
            monthlyPMI: null
          }
        : computeMonthlyPayment({
            homePrice,
            annualIncome,
            downPayment,
            monthlyDebt,
            approximateCreditScore,
            annualInterestRate,
            annualPropertyTaxRate,
            monthlyFee,
            monthlyHomeownersInsurance,
            jumboLoanLimit
          });
    yield put(updateMonthlyPaymentActionCreator(newMonthlyPayment));

    yield put(updateSpecialScenarioActionCreator(newSpecialScenario));
    sessionStorage.setItem(
      INPUTS_KEY,
      JSON.stringify({
        homePrice,
        annualIncome,
        downPayment,
        monthlyDebt,
        approximateCreditScore,
        annualInterestRate,
        annualPropertyTaxRate,
        monthlyFee,
        monthlyHomeownersInsurance
      })
    );
  } catch (err) {
    console.error("Couldn't update inputs: ", err);
  }
}
export function* saga() {
  yield all([takeEvery(UPDATE_INPUTS_ACTION_TYPE, handleUpdateInputsAction)]);
}
