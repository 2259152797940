import {
  OwnUpGridWrapper,
  OwnUpGridWrapperProps,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const StyledGridWrapper = styled(OwnUpGridWrapper)<PropsWithTheme<OwnUpGridWrapperProps>>`
  ${({ theme }: PropsWithTheme<OwnUpGridWrapperProps>) => theme.breakpoints.only('xl')} {
    max-width: 720px;
    margin: auto;
  }
  ${({ theme }: PropsWithTheme<OwnUpGridWrapperProps>) => theme.breakpoints.only('lg')} {
    max-width: 600px;
    margin: auto;
  }
`;

export const MyGridWrapper = (props: OwnUpGridWrapperProps) => <StyledGridWrapper {...props} />;
