import {
  FOG_20,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpSmallHeadlineBook
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { GridTemplate } from '../../../components/grid/grid-template';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { BackButton } from '../../components/back-to-account';
import { Form } from '../../components/form';
import { GenerateButton } from '../../components/generate-button';
import { IntentFilter } from '../../components/intent-filter';
import { intentSelector } from '../../redux/selectors';

export const GeneratePage = () => {
  const { accountId } = useSelector(manifestAccountInfoSelector);
  const intent = useSelector(intentSelector);
  return (
    <GridTemplate backgroundColor={FOG_20}>
      <>
        <OwnUpGridContainer variant="slim">
          {!!accountId && (
            <OwnUpGridItem xs={12}>
              <BackButton accountId={accountId} />
            </OwnUpGridItem>
          )}
          <OwnUpGridItem xs={12} sm={10}>
            <OwnUpSmallHeadlineBook variant="h1">
              Create a preapproval letter
            </OwnUpSmallHeadlineBook>
          </OwnUpGridItem>
          <OwnUpGridOffset xs={0} sm={2} />
        </OwnUpGridContainer>
        <IntentFilter />
        {intent !== null && <Form intent={intent} />}
        <GenerateButton />
      </>
    </GridTemplate>
  );
};
