import {
  InternalLeadPayload,
  internalLeadPayloadConverter
} from '@rategravity/partner-crm-stack-types';
import * as t from 'type-shift';
import { array } from '../log-errors';
import { sendToSentry } from '../sentry';

export interface Address {
  street: string;
  county: string;
  state: string;
  unit: string | null;
  zip: string;
}

const addressConverter = t
  .strict({
    street: t.string.default(() => ''),
    county: t.string.default(() => ''),
    state: t.string.default(() => ''),
    unit: t.string.or(t.null).default(() => null),
    zip: t.string.default(() => '')
  })
  .default(() => ({
    street: ' ',
    county: '',
    state: '',
    unit: null,
    zip: ''
  }));

export interface Preapproval {
  constraintId: string;
  shareId: string;
  propertyType: string;
  loanType: string;
  loanPeriod?: number;
}

const preapprovalsConverter = t.strict<Preapproval>({
  constraintId: t.string,
  shareId: t.string,
  propertyType: t.string,
  loanType: t.string,
  loanPeriod: t.optional(t.number)
});

export interface IncompleteQuestionnaire {
  accountId?: string;
  userId: string;
  questionnaireId: string;
  lastModifiedTime: number;
  scenario: string;
  // This needs to be here because the backend still returns all questionnaires, and we need a way to filter out completed ones
  evaluation?: string;
}

export const incompleteQuestionnaireConverter = t.shape<IncompleteQuestionnaire>({
  accountId: t.optional(t.string),
  userId: t.string,
  questionnaireId: t.string.or(t.forPath([t.ParentPath, 'id'], t.string)),
  lastModifiedTime: t.number,
  scenario: t.string,
  evaluation: t.string.or(t.forPath([t.ParentPath, 'consultPath'], t.string)).or(t.undefined)
});

interface PublishedRatesCommonAttributes {
  advisor?: string;
  condoFee?: number;
  customerDisplayStatus: boolean;
  homeInsurance?: number;
  occupancy: string;
  personalNote?: string;
  primaryBorrower: string;
  propertyType?: string;
  rateQuoteRequestId: string;
  realEstateTax?: number;
  signature: string;
  updatedTime: number;
  address: Address;
  numOffers?: number;
}

interface PurchasePublishedRates extends PublishedRatesCommonAttributes {
  purchasePrice: number;
}

interface RefiPublishedRates extends PublishedRatesCommonAttributes {
  existingLoanSize?: number;
}

export type PublishedRates = PurchasePublishedRates | RefiPublishedRates;

const publishedRatesCommonConverter = t.strict<PublishedRatesCommonAttributes>({
  advisor: t.optional(t.string),
  condoFee: t.optional(t.number),
  customerDisplayStatus: t.boolean,
  homeInsurance: t.optional(t.number),
  occupancy: t.string,
  personalNote: t.optional(t.string),
  primaryBorrower: t.string.default(() => ''),
  propertyType: t.optional(t.string),
  rateQuoteRequestId: t.string,
  realEstateTax: t.optional(t.number),
  signature: t.string,
  updatedTime: t.number,
  address: addressConverter,
  numOffers: t.optional(t.number)
});

const purchasePublishedRatesConverter = t.strict<PurchasePublishedRates>({
  ...publishedRatesCommonConverter.converters,
  purchasePrice: t.number
});

const refiPublishedRatesConverter = t.strict<RefiPublishedRates>({
  ...publishedRatesCommonConverter.converters,
  existingLoanSize: t.optional(t.number)
});

const publishedRatesConverter = t
  .shape({ purchasePrice: t.optional(t.unknown) })
  .pipe(
    t.select<PublishedRates, { purchasePrice?: unknown }>(({ purchasePrice }) =>
      purchasePrice !== undefined ? purchasePublishedRatesConverter : refiPublishedRatesConverter
    )
  );

export interface Consult {
  accountId: string;
  bookingProfile?: string;
  consultId: string;
  startDate: number;
  endDate: number;
  advisor: string;
  cancelled: boolean;
}

export const isConsult = (obj?: object): obj is Consult => !!(obj && 'consultId' in obj);

export const consultConverter = t.strict<Consult>({
  accountId: t.string,
  bookingProfile: t.optional(t.string),
  consultId: t.string,
  startDate: t.number,
  endDate: t.number,
  advisor: t.string,
  cancelled: t.boolean
});

export interface Manifest extends Omit<ManifestResponse, 'advisor'> {
  advisorEmail?: string;
  accountId: string;
}

// Note: we stub out the encryption-lib on front ends, so the Decrypted type is not available.
// If we need to use the InternalLeadPayload (decrypted) vs EncryptedInternalLeadPayload here,
// then we need to stub out the Decrypted (and EncryptedField) types here.
export interface Lead
  extends Pick<
    InternalLeadPayload,
    | 'leadID'
    | 'dateLeadReceived'
    | 'source'
    | 'sourceChannel'
    | 'sourceId'
    | 'accountId'
    | 'advisor'
    | 'loanPurpose'
    | 'propertyState'
    | 'propertyType'
    | 'questionnaireType'
    | 'hasAcceptedOffer'
    | 'loanAmount'
    | 'propertyValue'
    | 'creditRating'
    | 'currentProductType'
    | 'purchaseTimeline'
    | 'hasAgent'
    | 'firstName'
    | 'lastName'
    | 'propertyCity'
    | 'propertyZip'
    | 'hasCoborrower'
    | 'cashOutAmount'
    | 'currentMortgageBalance'
    | 'currentMortgageRate'
  > {
  experimentGroups: string[];
}

export const isLead = (obj?: object): obj is Lead => !!(obj && 'leadID' in obj);

export const leadConverter = t.strict<Lead>({
  leadID: internalLeadPayloadConverter.converters.leadID,
  dateLeadReceived: internalLeadPayloadConverter.converters.dateLeadReceived!,
  source: internalLeadPayloadConverter.converters.source,
  sourceChannel: internalLeadPayloadConverter.converters.sourceChannel!,
  sourceId: internalLeadPayloadConverter.converters.sourceId,
  accountId: internalLeadPayloadConverter.converters.accountId!,
  advisor: internalLeadPayloadConverter.converters.advisor!,
  loanPurpose: internalLeadPayloadConverter.converters.loanPurpose,
  propertyState: internalLeadPayloadConverter.converters.propertyState,
  propertyType: internalLeadPayloadConverter.converters.propertyType,
  questionnaireType: internalLeadPayloadConverter.converters.questionnaireType!,
  hasAcceptedOffer: internalLeadPayloadConverter.converters.hasAcceptedOffer!,
  loanAmount: internalLeadPayloadConverter.converters.loanAmount,
  propertyValue: internalLeadPayloadConverter.converters.propertyValue,
  creditRating: internalLeadPayloadConverter.converters.creditRating,
  currentProductType: internalLeadPayloadConverter.converters.currentProductType!,
  purchaseTimeline: internalLeadPayloadConverter.converters.purchaseTimeline!,
  firstName: internalLeadPayloadConverter.converters.firstName,
  lastName: internalLeadPayloadConverter.converters.firstName,
  propertyCity: internalLeadPayloadConverter.converters.propertyCity!,
  propertyZip: internalLeadPayloadConverter.converters.propertyZip!,
  hasCoborrower: internalLeadPayloadConverter.converters.hasCoborrower!,
  cashOutAmount: internalLeadPayloadConverter.converters.cashOutAmount!,
  currentMortgageBalance: internalLeadPayloadConverter.converters.currentMortgageBalance!,
  currentMortgageRate: internalLeadPayloadConverter.converters.currentMortgageRate!,
  hasAgent: internalLeadPayloadConverter.converters.hasAgent!,
  experimentGroups: t.array(t.string).default(() => [])
});

export interface LeadTarget {
  leadID: string;
  /**
   * randomId assigned to this target in case a single lead
   *   has multiple targets with the same targetName
   */
  targetId: string;
  /** target destination (eg: anywhere, ownup, lender id) */
  targetName: string;
  /** timestamp for when the target was opted in */
  optedIn?: number;
  /** timestamp for when the target is no longer available to be opted into */
  expiresAt?: number;
  /** Action resulting in the lead being sold */
  method?: string;
}

export const leadTargetConverter = t.strict<LeadTarget>({
  leadID: t.string,
  // Customer-facing leadTargets should be being generated with a GUID, but older
  //   leads that didn't generate a target set will not. We can generate a dummy
  //   one to satisfy the UI, as long as we don't try to use it for anything.
  // TODO: Remove this when we drop support for those older leads.
  targetId: t.string.default(() => `legacy-${Math.random()}`),
  targetName: t.string,
  optedIn: t.optional(t.number),
  method: t.optional(t.string),
  expiresAt: t.optional(t.number)
});

export interface ManifestResponse {
  consult?: Consult;
  preapprovals: Preapproval[];
  publishedRates: PublishedRates[];
  incompleteQuestionnaire?: IncompleteQuestionnaire;
  name: string;
  email: string;
  phone?: string;
  hubspotContactId: string;
  provisional: boolean;
  advisor?: {
    email: string;
  };
  showPreapprovalSimulator: boolean;
  lead?: Lead;
  leadTargets: LeadTarget[];
  /**
   * Status of the anywhere opt-in. Its existence means we should show something.
   *   Its value indicates what.
   */
  anywhereOptInStatus?: string;
}

export interface Manifest extends Omit<ManifestResponse, 'advisor'> {
  advisorEmail?: string;
  accountId: string;
}

/**
 * Wrapper around other converters to prevent a single converter failure from
 * breaking the entire manifest.  If the converter fails, the error will be
 * reported to sentry, and the default value passed in will be returned so the
 * manifest can load.
 * @param fieldName - name of field on base manifest object
 * @param inputConverter - the converter to call
 * @param defaultValue - if converter fails, returns this value
 */
const errorHandlingConverter = <T>(
  fieldName: string,
  inputConverter: t.Converter<T, unknown> | t.ConverterFunction<T, unknown>,
  defaultValue: T
) =>
  t.createConverter((input, path, entity) => {
    try {
      return inputConverter(input, path, entity);
    } catch (err) {
      sendToSentry(`converter ${fieldName} failed: ${err}`);
      return defaultValue;
    }
  });

/**
 * Note: wrap individual converters around the 'errorHandlingConverter' to avoid a single
 * converter failure from breaking the entire manifest/account.
 */
export const manifestConverter = t.strict<ManifestResponse>({
  consult: errorHandlingConverter('consult', t.optional(consultConverter), undefined),
  preapprovals: errorHandlingConverter('preapprovals', array(preapprovalsConverter), []),
  publishedRates: errorHandlingConverter('publishedRates', array(publishedRatesConverter), []),
  incompleteQuestionnaire: errorHandlingConverter(
    'incompleteQuestionnaire',
    incompleteQuestionnaireConverter
      .or(t.forPath([t.ParentPath, 'questionnaire'], incompleteQuestionnaireConverter))
      .or(t.undefined),
    undefined
  ),
  name: t.string,
  email: t.string,
  phone: t.optional(t.string),
  hubspotContactId: t.string,
  advisor: t.optional(t.strict({ email: t.string })),
  provisional: t.boolean.default(() => false),
  showPreapprovalSimulator: t.boolean.default(() => false),
  lead: errorHandlingConverter('lead', t.optional(leadConverter), undefined),
  leadTargets: errorHandlingConverter(
    'leadTargets',
    t.array(leadTargetConverter).default(() => []),
    []
  ),
  anywhereOptInStatus: errorHandlingConverter(
    'anywhereOptInStatus',
    t.optional(t.string),
    undefined
  )
});
