export interface MonthlyPaymentState {
  monthlyPAndI: number | null;
  monthlyPropertyTax: number | null;
  monthlyHomeownersInsurance: number | null;
  monthlyFee: number | null;
  monthlyPMI: number | null;
}

export const DEFAULT_MONTHLY_PAYMENT_STATE = {
  monthlyPAndI: null,
  monthlyPropertyTax: null,
  monthlyHomeownersInsurance: null,
  monthlyFee: null,
  monthlyPMI: null
};
