import { event } from '@rategravity/frontend/modules/user-actions';
import {
  OwnUpGridItem,
  OwnUpIconButton,
  OwnUpLogoLink,
  OwnUpLogoWithTextLink,
  OwnUpNavBar,
  OwnUpNavLink,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import { MenuIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/menu';
import { ProfileIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/profile';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useActionPipe } from '../../hooks/use-action-pipe';
import { CobrandingLogo } from '../cobranding';
import { NavModal } from './modal';
import { getLinks } from './nav-list';

const AccountNavBar = styled(OwnUpNavBar)<PropsWithTheme<{ fixedHeader: boolean }>>`
  position: ${({ fixedHeader }) => (fixedHeader ? 'fixed' : 'relative')};
`;

const FullWidthGridItem = styled(OwnUpGridItem)`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const MediumWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    display: none;
  }
`;

const LargeWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

export interface NavBarProps {
  accountId: string;
  isProvisionalAccount?: boolean;
  logOut: () => Promise<void>;
  isLoggedIn?: boolean;
  cobranding?: string;
  fixedHeader?: boolean;
}

export const NavBar = ({
  accountId,
  isProvisionalAccount,
  logOut,
  isLoggedIn,
  cobranding,
  fixedHeader
}: NavBarProps) => {
  const dispatchAction = useActionPipe();
  const [openModal, setOpenModal] = useState(false);
  const showLogOut = !isProvisionalAccount && !!isLoggedIn;
  const logoLink = !!accountId ? `/account/${accountId}` : `${process.env.WWW}`;

  return (
    <React.Fragment>
      <AccountNavBar fixedHeader={!!cobranding || !!fixedHeader}>
        <FullWidthGridItem xs>
          <LargeWrapper>
            {showLogOut && (
              <OwnUpNavLink
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  void logOut();
                }}
                href="javascript:void(0)"
                icon={ProfileIcon}
                size="small"
              >
                Sign out
              </OwnUpNavLink>
            )}
          </LargeWrapper>
          <MediumWrapper>
            <OwnUpIconButton
              icon={<MenuIcon />}
              name="navigation menu"
              size={2.5}
              onClick={() => {
                dispatchAction(event('Nav Bar Open Modal Clicked'));
                setOpenModal(true);
              }}
            />
          </MediumWrapper>
          {!!cobranding && (
            <div>
              <CobrandingLogo source={cobranding} />
            </div>
          )}

          {!!cobranding ? (
            <div>
              <OwnUpLogoLink href={logoLink} />
            </div>
          ) : (
            <MediumWrapper>
              <OwnUpLogoWithTextLink href={logoLink} />
            </MediumWrapper>
          )}
        </FullWidthGridItem>
      </AccountNavBar>
      <NavModal
        {...{
          open: openModal,
          setOpenModal,
          links: getLinks(),
          logOut,
          showLogOut,
          logoLink
        }}
      />
    </React.Fragment>
  );
};
