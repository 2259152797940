import { ManifestResponse } from '../../modules/account';

export const LOAD_ACCOUNT_ACTION_TYPE = 'LOAD_ACCOUNT' as const;

export interface LoadAccountPayload {
  accountId: string;
  waitFor?: {
    /**
     * Manifest field to wait for before marking the load as successful.
     *
     * Will wait for it to be truthy unless `manifestValue` is also defined,
     *   in which case it will wait for those to match.
     */
    manifestField: keyof ManifestResponse;

    /**
     * Key/Value pair to wait for in the `manifestField`. If defined, will only
     *   mark the load as successful if `[manifestField][key] === value`.
     */
    manifestValue?: {
      key?: string;
      value: unknown;
    };
    /** Manifest field value to wait for */
    /**
     * If set to true, an error will be thrown if the `manifestField` is not loaded.
     *  Otherwise, the manifest will be loaded as-is even without the field.
     */
    errorIfNotFound?: boolean;
    /** Attempt number for this reload. Should be left empty by default */
    cycle?: number;
  };
}

/**
 * @param accountId AccountId to load
 * @param waitFor Don't finish loading until a certain data point
 *   exists in the manifest. Will either error or proceed without
 *   it depending on config.
 */
export const loadAccount = (
  accountId: LoadAccountPayload['accountId'],
  waitFor?: LoadAccountPayload['waitFor']
) => ({
  type: LOAD_ACCOUNT_ACTION_TYPE,
  payload: { accountId, waitFor }
});

export type LoadAccountAction = ReturnType<typeof loadAccount>;
