import { lookupPMIRate } from './lookup-pmi-rate';

export const computeMonthlyPMI = (
  homePrice: number,
  downPayment: number,
  approximateCreditScore: number
) => {
  const percentDownPayment = Math.round((downPayment / homePrice) * 100) / 100;
  const annualPMIRate = lookupPMIRate(percentDownPayment, approximateCreditScore);

  if (annualPMIRate) {
    return parseFloat(((annualPMIRate / 1200) * (homePrice - downPayment)).toFixed(2));
  }

  return null;
};
