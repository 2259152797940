import { all, Optional } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { resolveFeeValue } from '../../modules/fee-helpers';
import { getLockStatus, LockStatus } from '../../modules/get-lock-status';
import { getLoanType } from '../../modules/loan-type';
import { LockRequest } from '../state';
import { offerSelector, rateQuoteSelector } from './rate-quote';

export const lockEventPropertiesSelector = createSelector(rateQuoteSelector, (rates) =>
  rates
    .map((rateQuote) => {
      const { purchasePrice, advisor } = rateQuote;
      const scenario = purchasePrice && purchasePrice > 0 ? 'purchase' : 'refinance';
      return { advisor, scenario };
    })
    .orElse(() => ({ advisor: '', scenario: '' }))
);

export const lockRequestSelector = createSelector(
  rateQuoteSelector,
  offerSelector,
  (rates, optionalOffer): Optional<LockRequest> =>
    all([rates, optionalOffer]).map(
      ([rq, offer]): LockRequest => ({
        loanType: getLoanType(offer.loanType),
        apr: offer.apr,
        rate: offer.rate,
        lenderCredit: resolveFeeValue(
          offer.allFees.find(({ type }) => type === 'lenderCredit')?.value || 0
        ),
        points: resolveFeeValue(offer.allFees.find(({ type }) => type === 'points')?.value || 0),
        rateLock: offer.rateLock,
        pAndI: offer.pAndI,
        totalClosing: offer.allFees.reduce((sum, { value }) => sum + resolveFeeValue(value), 0),
        logo: offer.logo,
        lockStatus: rq.lockStatus,
        lender: offer.lenderName,
        lenderFees: offer.allFees
          .filter(({ group }) => group === 'LenderFee')
          .reduce((p, n) => p + resolveFeeValue(n.value), 0)
      })
    )
);

export const lockStatusSelector = createSelector(
  rateQuoteSelector,
  offerSelector,
  (
    rates,
    optionalOffer
  ): { showLock: false } | { showLock: true; lockStatus: LockStatus; offerId: string } =>
    all([rates, optionalOffer])
      .map(([rq, offer]) => {
        const inAccount = window.location.pathname.includes('account');
        if (rq.lockedOfferId === undefined || rq.sampleRate || !rq.isLicensedState || !inAccount) {
          return { showLock: false as const };
        }
        return {
          showLock: true,
          lockStatus: getLockStatus({
            currentOfferId: offer.id,
            expirationTime: rq.expirationTime,
            lockedOfferId: rq.lockedOfferId
          }),
          offerId: offer.id
        };
      })
      .orElse(() => ({ showLock: false }))
);
