import { ApplicationState } from '../../../../redux/state';

export const sliderConstraintsSelector = ({
  preapprovals: {
    simulator: {
      sliderConstraints: {
        sliderValue,
        sliderMax,
        sliderMin,
        sliderGradient: { maxAffordable, maxStretch }
      }
    }
  }
}: ApplicationState) => ({
  // set nulls to 0
  sliderMin: sliderMin || 0,
  sliderMax: sliderMax || 0,
  sliderGradient: {
    maxAffordable: maxAffordable || 0,
    maxStretch: maxStretch || 0
  },
  sliderValue: sliderValue || 0
});
