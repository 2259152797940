const getOpenModal = () => document.getElementsByClassName('modal-body')[0];

/**
 * Scroll the window and any open modals to the top of the page.
 */
export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });

  const modal = getOpenModal();
  if (modal) {
    modal.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
};

/**
 * Scroll the window and any open modals to an element matching
 *   the given id.
 */
export const scrollToElement =
  (id: string, offset: number = 0) =>
  () => {
    const element = document.getElementById(id);
    if (!element) {
      scrollToTop();
      return;
    }
    const modal = getOpenModal();
    if (modal) {
      // Calculate how far the top of the modal is from our current view.
      // Get the distance the element is from our current view (element.getBoundingClientRect().top)
      // + the distance the modal is scrolled vertically (modal.scrollTop) + any offset
      const modalTop = element.getBoundingClientRect().top + modal.scrollTop + offset;
      modal.scrollTo({ top: modalTop, behavior: 'smooth' });
    } else {
      const windowTop = element.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({ top: windowTop, behavior: 'smooth' });
    }
  };
