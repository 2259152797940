/* eslint-disable @typescript-eslint/naming-convention */
import { createTrackingInstance } from '@rategravity/1pt-lib';
import * as Sentry from '@sentry/react';
import { trackingInstance } from './1pt-tracking-instance';

interface Action {
  type: 'Identify' | 'Event' | 'PageView';
}

interface IdentifyAction extends Action {
  userId?: string;
  traits?: Record<string, unknown>;
}

interface EventAction extends Action {
  id?: string;
  properties?: Record<string, unknown>;
}

interface Handler {
  Identify: (payload: IdentifyAction) => void;
  Event: (payload: EventAction) => void;
  PageView: () => void;
}

type TrackingInstance = ReturnType<typeof createTrackingInstance>;

export const firstPartyTrackingHandler = (instance: TrackingInstance): Handler => ({
  Identify: ({ userId, traits = {} }) => {
    if (userId) {
      instance.identify(userId, traits);
      if (process.env.SENTRY_DSN) {
        Sentry.setTag('user.userId', userId);
      }
    } else {
      instance.reset();
    }
  },
  Event: ({ id, properties = {} }) => {
    if (id) {
      instance.track(id, properties);
    }
  },
  PageView: () => {
    instance.page();
  }
});

const observer = (handler: Handler) => (action: Action) => handler[action.type](action);

export const firstPartyTrackingObserver = () =>
  observer(firstPartyTrackingHandler(trackingInstance));
