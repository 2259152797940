import { County } from '@rategravity/address-verification';
import { FOG_60 } from '@rategravity/own-up-component-library';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { AddressModel } from '../../../components/address/address-helpers';
import { useFuzzyMatchCountyService } from '../../../components/address/fuzzy-match-service';
import { addressChange } from '../../../preapproval/redux/generate';
import { Address } from '../../../preapproval/redux/state';
import { addressFormSelector, maxValuesSelector } from '../../redux/selectors';
import { JustLookingIntentForm } from './just-looking-intent-form';
import { OfferIntentForm } from './offer-intent-form';
import { PurchaseInputs } from './purchase-inputs';

const Container = styled('div')`
  border-left: 4px solid ${FOG_60};
  padding-left: 24px;
`;

export type AddressChangeDispatch = (
  address: AddressModel | undefined,
  isValidating?: boolean
) => void;

export const Form = ({ intent }: { intent: string | null }) => {
  const { maxPreapprovalAmount } = useSelector(maxValuesSelector);
  const { stateOptions, stateError, address } = useSelector(addressFormSelector);
  const dispatch = useDispatch();
  const addressChangeDispatcher: AddressChangeDispatch = (
    incomingAddress: AddressModel | undefined,
    isValidating?: boolean
  ) => {
    if (incomingAddress) {
      dispatch(
        addressChange(
          {
            ...incomingAddress,
            state: incomingAddress.state || ''
          } as Address,
          isValidating
        )
      );
    }
  };
  const [counties, setCounties] = useState<County[]>([]);
  const { genCountyList, addressFuzzyMatchCounty } = useFuzzyMatchCountyService({ setCounties });

  useEffect(() => {
    if (address.state) {
      void genCountyList(address.state);
    }
  }, [address.state, genCountyList]);

  const addressFormProps = {
    stateOptions,
    countyOptions: counties.map(({ name }) => name),
    address: address as AddressModel,
    addressChangeDispatcher,
    addressFuzzyMatchCounty,
    stateError
  };
  return (
    <Container>
      {intent === 'offer' ? (
        <OfferIntentForm {...addressFormProps} />
      ) : (
        <JustLookingIntentForm {...addressFormProps} />
      )}
      <PurchaseInputs {...{ intent, maxPreapprovalAmount }} />
    </Container>
  );
};
