import React from 'react';
import * as colors from '../../../colors';

export const PercentIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9897 2.18196C17.3034 2.45241 17.3385 2.92599 17.0681 3.23972L4.56806 17.7397C4.2976 18.0534 3.82402 18.0885 3.5103 17.8181C3.19657 17.5476 3.16149 17.074 3.43194 16.7603L15.9319 2.26031C16.2024 1.94658 16.676 1.9115 16.9897 2.18196Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 9.00001C7.433 9.00001 9 7.43301 9 5.50001C9 3.56702 7.433 2.00001 5.5 2.00001C3.567 2.00001 2 3.56702 2 5.50001C2 7.43301 3.567 9.00001 5.5 9.00001ZM5.5 7.50001C6.60457 7.50001 7.5 6.60458 7.5 5.50001C7.5 4.39544 6.60457 3.50001 5.5 3.50001C4.39543 3.50001 3.5 4.39544 3.5 5.50001C3.5 6.60458 4.39543 7.50001 5.5 7.50001Z"
      fill={colors.CHARCOAL_100}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 14.5C18 16.433 16.433 18 14.5 18C12.567 18 11 16.433 11 14.5C11 12.567 12.567 11 14.5 11C16.433 11 18 12.567 18 14.5ZM16.5 14.5C16.5 15.6046 15.6046 16.5 14.5 16.5C13.3954 16.5 12.5 15.6046 12.5 14.5C12.5 13.3954 13.3954 12.5 14.5 12.5C15.6046 12.5 16.5 13.3954 16.5 14.5Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
