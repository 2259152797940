import {
  OwnUpSmallBodyMedium,
  OwnUpUnderlineLink,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0)};
`;

export const RDCCobranding = () => (
  <Container>
    <OwnUpSmallBodyMedium variant="body1">
      <OwnUpUnderlineLink href="https://www.realtor.com" target="_blank" rel="noreferrer">
        Realtor.com®
      </OwnUpUnderlineLink>{' '}
      and the{' '}
      <OwnUpUnderlineLink href="https://www.realtor.com" target="_blank" rel="noreferrer">
        Realtor.com®
      </OwnUpUnderlineLink>{' '}
      logo are trademarks owned by Move, Inc. and used with permission.
    </OwnUpSmallBodyMedium>
  </Container>
);
