import { currency } from '@rategravity/frontend/modules/numbers';
import { OwnUpGridItem } from '@rategravity/own-up-component-library';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { PurchasePriceInput } from '../amount';
import { DownPaymentInputs } from '../downpayment';
import { LoanAmount } from '../loan-size';
import { Header } from './header';

const loanAmountHeader = 'What is your total loan amount?';
const offerLoanAmountBody = (maxPreapprovalAmount: number) =>
  `You're preapproved for up to ${currency(
    maxPreapprovalAmount,
    0
  )} but you can adjust your purchase price and down payment to leave room for negotiation with the seller.`;
const lookingLoanAmountBody = (maxPreapprovalAmount: number) =>
  `You're preapproved for up to ${currency(
    maxPreapprovalAmount,
    0
  )}. Since you're not preparing to make an offer, we've updated your purchase price and down payment to show the most you can afford. Once you're ready to make an offer, you can adjust your purchase price and down payment to leave room for negotiation with the seller.`;

const InputsWrapper = styled('div')(({ theme }) => ({
  marginBottom: '24px 0',
  [theme.breakpoints.up('lg')]: {
    marginBottom: '32px 0'
  }
}));

const InputWrapper = styled('div')`
  margin-bottom: 12px;
`;
const InnerGrid = ({ children }: { children: JSX.Element | JSX.Element[] }) => (
  <OwnUpGridItem xs={12} sm={10} md={6}>
    {children}
  </OwnUpGridItem>
);

export const PurchaseInputs = ({
  intent,
  maxPreapprovalAmount
}: {
  intent: string | null;
  maxPreapprovalAmount: number;
}) => (
  <Fragment>
    <Header
      header={loanAmountHeader}
      body={
        intent === 'offer'
          ? offerLoanAmountBody(maxPreapprovalAmount)
          : lookingLoanAmountBody(maxPreapprovalAmount)
      }
    />
    <InnerGrid>
      <InputsWrapper>
        <InputWrapper>
          <PurchasePriceInput required={true} />
        </InputWrapper>
        <DownPaymentInputs required={true} />
      </InputsWrapper>
      <LoanAmount />
    </InnerGrid>
  </Fragment>
);
