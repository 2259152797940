import { Manifest } from '../../modules/account';

export const MANIFEST_LOADED_SUCCESS_ACTION_TYPE = 'MANIFEST_LOADED_SUCCESS' as const;

export const manifestLoadedSuccess = (manifest: Manifest) => ({
  type: MANIFEST_LOADED_SUCCESS_ACTION_TYPE,
  payload: {
    manifest
  }
});

export type ManifestLoadedSuccessAction = ReturnType<typeof manifestLoadedSuccess>;

export const MANIFEST_LOADED_FAILED_ACTION_TYPE = 'MANIFEST_LOADED_FAILED' as const;

export const manifestLoadedFailed = (error: string) => ({
  type: MANIFEST_LOADED_FAILED_ACTION_TYPE,
  payload: {
    error
  }
});

export type ManifestLoadedFailedAction = ReturnType<typeof manifestLoadedFailed>;

export const OPTED_INTO_LEAD_TARGET_ACTION_TYPE = 'OPTED_INTO_LEAD_TARGET' as const;
/**
 * Action fired when an opt in has been completed. Will update the manifest in-line to indicate
 *   the lender has been opted into. This is temporary for the user's storage until the backend
 *   has caught up, which should be well before the user attempts to reload the page.
 */
export const optedIntoLeadTarget = (payload: {
  leadID: string;
  targetId: string;
  targetName: string;
}) => ({
  type: OPTED_INTO_LEAD_TARGET_ACTION_TYPE,
  payload
});
export type OptedIntoLeadTargetAction = ReturnType<typeof optedIntoLeadTarget>;
