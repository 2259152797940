import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts
} from '@rategravity/own-up-component-library-legacy';
import { colors } from '../../../../modules/colors';

export const Button = createOwnUpComponent('button', ({ disabled }: { disabled: boolean }) =>
  createOwnUpStyle({
    ...fonts.GRAPHIK_REGULAR,
    borderRadius: '31px',
    width: '312px',
    padding: '10px',
    backgroundColor: `${disabled ? colors.GREY_ALT : colors.PINE}`,
    fontSize: '15px',
    lineHeight: '32px',
    letterSpacing: '.5px',
    textAlign: 'center',
    color: colors.WHITE,
    border: `1px solid ${disabled ? colors.GREY_ALT : colors.PINE}`,
    cursor: disabled ? 'default' : 'pointer',
    boxSizing: 'border-box'
  })
);
