import { composeReducers, ofType, withDefault } from 'redux-compose';
import { UPDATE_SPECIAL_SCENARIO_ACTION_TYPE, UpdateSpecialScenarioAction } from './actions';
import { SpecialScenarioState } from './state';

const updateSpecialScenario = (
  _: SpecialScenarioState,
  { payload }: UpdateSpecialScenarioAction
): SpecialScenarioState => payload;

export const specialScenarioReducer = composeReducers(
  withDefault(null, ofType(UPDATE_SPECIAL_SCENARIO_ACTION_TYPE, updateSpecialScenario))
);
