import {
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { MyGridWrapper } from './my-grid-wrapper';
import { ResponsiveContainer, ResponsiveContainerProps } from './responsive-container';

export interface FullWidthContainerWithBackgroundProps {
  backgroundColor: string;
  fullHeight?: boolean;
  style?: React.CSSProperties;
}

export const FullWidthContainerWithBackground = styled.div<FullWidthContainerWithBackgroundProps>`
  width: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ fullHeight }) => (fullHeight ? '100vh' : 'auto')};
`;

interface GridTemplateProps extends ResponsiveContainerProps {
  backgroundColor: string;
  children: JSX.Element;
  fullHeight?: boolean;
  useNewGrid?: boolean;
}
/**
 * This is the original grid template
 */
const LegacyGridTemplate = ({
  backgroundColor,
  children,
  fullHeight = false,
  ...props
}: GridTemplateProps) => (
  <FullWidthContainerWithBackground backgroundColor={backgroundColor} fullHeight={fullHeight}>
    <OwnUpGridWrapper>
      <ResponsiveContainer {...props}>
        <OwnUpGridContainer variant="slim">
          <OwnUpGridOffset xs={0} md={1} xl={2} />
          <OwnUpGridItem xs={12} md={10} xl={8}>
            {children}
          </OwnUpGridItem>
          <OwnUpGridOffset xs={0} md={1} xl={2} />
        </OwnUpGridContainer>
      </ResponsiveContainer>
    </OwnUpGridWrapper>
  </FullWidthContainerWithBackground>
);

/**
 * This is the new grid template, which has a fixed width content for lg+ screens.
 * The fixed width is set on the GridWrapper, and includes any grid offsets.
 * For it to work properly, lg/xl screens should have no grid offsets
 */
const MyGridTemplate = ({
  backgroundColor,
  children,
  fullHeight = false,
  ...props
}: GridTemplateProps) => (
  <FullWidthContainerWithBackground backgroundColor={backgroundColor} fullHeight={fullHeight}>
    <MyGridWrapper>
      <ResponsiveContainer {...props}>
        <OwnUpGridContainer variant="slim">
          <OwnUpGridOffset xs={0} md={1} lg={0} />
          <div /> {/* empty div to prevent margin collapsing when there is no offset */}
          <OwnUpGridItem xs={12} md={10} lg={12}>
            {children}
          </OwnUpGridItem>
          <OwnUpGridOffset xs={0} md={1} lg={0} />
        </OwnUpGridContainer>
      </ResponsiveContainer>
    </MyGridWrapper>
  </FullWidthContainerWithBackground>
);
/**
 * This is a template to make it easier to set up different sections on a page that
 * use the same grid layout. Each section has a different background color, but shares
 * the same grid margins. The child content will fit in the main the section of the grid.
 *
 * Note other pages may need to copy this format and specify their own column breakpoints
 * if different pages need a different column layout.
 *
 * Also note this uses the ResponsiveContainer, which automatically adjusts the top and bottom
 * margins of the main content containers based on the breakpoints.
 */
export const GridTemplate = ({ useNewGrid = false, ...props }: GridTemplateProps) =>
  useNewGrid ? (
    <MyGridTemplate {...props}></MyGridTemplate>
  ) : (
    <LegacyGridTemplate {...props}></LegacyGridTemplate>
  );
