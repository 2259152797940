import { event } from '@rategravity/frontend/modules/user-actions';
import {
  colors,
  createOwnUpComponent,
  createOwnUpStyle,
  zIndex
} from '@rategravity/own-up-component-library-legacy';
import { CancelX } from '@rategravity/own-up-component-library-legacy/components/images/icons/svgs';
import React, { PropsWithChildren } from 'react';
import { Dialog, DialogBackdrop, DialogDisclosure, useDialogState } from 'reakit/Dialog';
import {
  TooltipArrow,
  Tooltip as TooltipContent,
  TooltipReference,
  useTooltipState
} from 'reakit/Tooltip';
import QuestionMarkCircleSVG from '../../../../assets/question-mark-circle.svg';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { useActionPipe } from '../../../../hooks/use-action-pipe';

export type TooltipContentValue = string | JSX.Element;

const TooltipIconImageStyle = createOwnUpStyle({
  height: '20px',
  verticalAlign: 'middle',
  '& path': {
    ':hover': {
      stroke: '#5B7978'
    }
  }
});

const DefaultTooltipIcon = <img src={QuestionMarkCircleSVG} style={TooltipIconImageStyle} alt="" />;

const TooltipTextbox = createOwnUpComponent('div', {
  zIndex: 110, // Accommodates the thumb on the slider.
  width: '318px',
  border: `1px solid ${colors.GREY}`,
  background: colors.WHITE,
  padding: '16px',
  borderRadius: '10px',
  fontSize: '14px',
  lineHeight: '22px',
  boxShadow: '0 2px 10px rgba(20, 63, 61, 0.05)',
  outline: 'none',
  variants: {
    mobile: {
      paddingLeft: '31px',
      '& ul': {
        margin: '-25px 0 0 0',
        padding: '0 0 0 23px'
      }
    }
  }
});

const tooltipArrowStyle = {
  fill: `${colors.WHITE}`,
  stroke: `${colors.GREY}`,
  strokeWidth: 0.5
};
export interface TooltipProps {
  content: TooltipContentValue;
  name?: string;
  TooltipIcon?: JSX.Element;
}

export const HoverIconTooltip = ({ content, TooltipIcon = DefaultTooltipIcon }: TooltipProps) => {
  const ts = useTooltipState();
  return (
    <>
      <TooltipReference {...ts}>{TooltipIcon}</TooltipReference>
      <TooltipContent {...ts} as={TooltipTextbox}>
        <TooltipArrow {...ts} style={tooltipArrowStyle} />
        {content}
      </TooltipContent>
    </>
  );
};

const FullScreenTooltipBackdrop = createOwnUpComponent(DialogBackdrop, {
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  zIndex: zIndex.ModalBackdrop,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const FullScreenTooltipCloseButton = createOwnUpComponent('button', {
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: 0,
  width: '13px',
  position: 'relative',
  right: '12px',
  top: '4px',
  float: 'left',

  '& > svg': {
    stroke: colors.PRIMARY_ALT,
    fill: colors.PRIMARY_ALT
  }
});

const FullScreenTooltipText = createOwnUpComponent('div', {
  marginLeft: '16px'
});

export const FullScreenIconTooltip = ({
  content,
  name,
  TooltipIcon = DefaultTooltipIcon
}: TooltipProps) => {
  const dialog = useDialogState();
  const dispatchAction = useActionPipe();
  const clickedToolTip = event('clickTooltip-PAS', {
    location: 'pre-approval simulator',
    name: name
  });

  return (
    <>
      <DialogDisclosure
        {...dialog}
        as="span"
        onClick={() => {
          dispatchAction(clickedToolTip);
        }}
        aria-label={name}
      >
        {TooltipIcon}
      </DialogDisclosure>
      <FullScreenTooltipBackdrop {...dialog}>
        <Dialog {...dialog} aria-label={content} as={TooltipTextbox} variant="mobile">
          <FullScreenTooltipCloseButton onClick={dialog.hide} aria-label="close">
            <CancelX color={colors.PRIMARY_ALT} height="100%" strokeWidth={1} />
          </FullScreenTooltipCloseButton>
          <FullScreenTooltipText>{content}</FullScreenTooltipText>
        </Dialog>
      </FullScreenTooltipBackdrop>
    </>
  );
};

export const IconTooltip = ({ content, name, TooltipIcon = DefaultTooltipIcon }: TooltipProps) => {
  const isMobile = useIsMobile(1024);

  return isMobile ? (
    <FullScreenIconTooltip {...{ content, name, TooltipIcon }} />
  ) : (
    <HoverIconTooltip {...{ content, TooltipIcon }} />
  );
};

export const WithTooltip = ({
  content,
  name,
  children
}: PropsWithChildren<{ content: string; name: string }>) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {children}
    <IconTooltip content={content} name={name} />
  </div>
);
