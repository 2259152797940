import { createGenerateClassName, StylesProvider } from '@material-ui/core/styles';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpHeader
} from '@rategravity/own-up-component-library-legacy';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountHomeLink } from '../../../components/account-home-link';
import { LoadingLogo } from '../../../components/loading';
import { useIsMobile } from '../../../hooks/is-mobile';
import { colors } from '../../../modules/colors';
import { Drawer } from '../../../preapproval/components/simulator/drawer';
import { Results } from '../../../preapproval/components/simulator/results';
import { RegularDarkText } from '../../../preapproval/components/simulator/typography';
import { ConnectedNumbersSummary } from '../../../preapproval/views/simulator/numbers-summary';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { preapprovalSimulatorPresetsSelector } from '../../../redux/preapproval-simulator/selector';
import { INPUTS_KEY } from '../../modules/simulator/constants';
import { updateInputsActionCreator } from '../../redux/simulator/inputs/actions';
import { ConnectedForm } from '../../views/simulator/form';

const HeaderWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    paddingTop: '32px',
    margin: '0 80px',
    textAlign: 'left',
    ...(isMobile ? { padding: '24px 24px 0', width: '310px', margin: 'auto' } : {})
  })
);

const Header = createOwnUpComponent(OwnUpHeader, ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    color: `${colors.SLATE_TWO}`,
    ...(isMobile ? { fontSize: '24px', marginBottom: '24px' } : {})
  })
);

const HeaderText = ({ isMobile }: { isMobile: boolean }) => {
  const { accountId } = useSelector(manifestAccountInfoSelector);
  return (
    <HeaderWrapper isMobile={isMobile}>
      {!!accountId ? <AccountHomeLink {...{ accountId, isMobile }} /> : null}
      <RegularDarkText>What&apos;s my home buying power?</RegularDarkText>
      <Header isMobile={isMobile} level={1}>
        Preapproval Simulator
      </Header>
    </HeaderWrapper>
  );
};

const DisclaimerWrapper = createOwnUpComponent('div', () =>
  createOwnUpStyle({
    display: 'block',
    width: '310px',
    margin: 'auto',
    paddingTop: '14px'
  })
);

const Disclaimer = ({ isMobile }: { isMobile?: boolean }) => (
  <DisclaimerWrapper isMobile={isMobile}>
    <RegularDarkText>
      In this tool, we assume a 30 year fixed loan (conventional or jumbo) for a primary residence.
      This tool currently does not take other loan types into consideration, like VA or FHA.
    </RegularDarkText>
  </DisclaimerWrapper>
);

const ContentWrapper = createOwnUpComponent('div', () =>
  createOwnUpStyle({
    maxWidth: '1280px',
    margin: 'auto',
    paddingBottom: '50px'
  })
);

const FormWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile: boolean }) =>
  createOwnUpStyle({
    ...(isMobile ? { margin: `0 24px` } : {})
  })
);

const DesktopWrapper = createOwnUpComponent('div', () =>
  createOwnUpStyle({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    padding: '0 80px'
  })
);

const ResultsWrapper = createOwnUpComponent('section', ({ isMobile }: { isMobile?: boolean }) =>
  createOwnUpStyle({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px',
    boxShadow: `2px 2px ${colors.LIGHT_GREY_TWO}`,
    padding: '40px',
    backgroundColor: colors.WHITE,
    height: 'fit-content',
    border: `1px solid ${colors.LIGHT_GREY_TWO}`,
    width: '75%',
    ...(isMobile ? { marginTop: '24px' } : { justifySelf: 'self-end' })
  })
);

// Since this page has a mix of new and old component library, there can
// MUI namespace clashes.  Add a prefix to classes created within this page
// to avoid clashes with the new component library
const generateClassName = createGenerateClassName({
  seed: 'ouMuiHack'
});

export const SimulatorPage = () => {
  const isMobile = useIsMobile(1024);
  const dispatch = useDispatch();
  const { value: preapprovalSimulatorPresetsState } = useSelector(
    preapprovalSimulatorPresetsSelector
  );

  const loadedData = useMemo(() => {
    const cachedInputs = sessionStorage.getItem(INPUTS_KEY);
    if (cachedInputs) {
      return JSON.parse(cachedInputs);
    } else if (preapprovalSimulatorPresetsState) {
      if (preapprovalSimulatorPresetsState.loading) {
        return null;
      } else if (preapprovalSimulatorPresetsState.success) {
        return preapprovalSimulatorPresetsState.data;
      }
    }
    return {};
  }, [preapprovalSimulatorPresetsState]);

  useEffect(() => {
    if (loadedData) {
      dispatch(updateInputsActionCreator(loadedData));
    }
  }, [loadedData, dispatch]);

  useEffect(() => {
    if (!isMobile) {
      // clear all body scroll locks applied in the drawer component when switching to desktop.
      // users should never really find themselves in this position, but just in case.
      clearAllBodyScrollLocks();
    }
  }, [isMobile]);

  const Form = () => (
    <FormWrapper isMobile={isMobile}>
      <ConnectedForm />
      <Disclaimer />
    </FormWrapper>
  );

  const MobileView = () => (
    <>
      <Form />
      <Drawer>
        <Results isMobile />
      </Drawer>
    </>
  );

  const DesktopView = () => (
    <DesktopWrapper>
      <Form />
      <ResultsWrapper aria-label="results based on your inputs">
        <ConnectedNumbersSummary />
        <Results />
      </ResultsWrapper>
    </DesktopWrapper>
  );

  return loadedData ? (
    <StylesProvider generateClassName={generateClassName}>
      <ContentWrapper>
        <HeaderText isMobile={isMobile} />
        {isMobile ? <MobileView /> : <DesktopView />}
      </ContentWrapper>
    </StylesProvider>
  ) : (
    <LoadingLogo />
  );
};
