import { OwnUpFillButtonPrimary } from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import { manifestAccountInfoSelector } from '../../../redux/manifest';
import { TextSkeleton } from '../../components/text-skeleton';
import { errorSelector } from '../../redux/selectors';

export const ErrorPage = () => {
  const { advisor, borrower } = useSelector(errorSelector);
  const { accountId } = useSelector(manifestAccountInfoSelector);
  return (
    <TextSkeleton
      title="Sorry! We have encountered an error and cannot continue."
      body="Please contact your advisor and we will resolve this as quickly as possible."
      ctas={[
        <a href={`https://consult.ownup.com/schedule/general/${advisor}?NAME=${borrower}`} key="1">
          <OwnUpFillButtonPrimary>Schedule a call</OwnUpFillButtonPrimary>
        </a>
      ]}
      accountId={accountId}
    />
  );
};
