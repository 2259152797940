import React from 'react';
import * as colors from '../../../colors';

export const ChevronLeftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7276 3.22764C14.0161 3.52488 14.009 3.9997 13.7117 4.28819L7.82685 10L13.7117 15.7118C14.009 16.0003 14.0161 16.4751 13.7276 16.7724C13.4391 17.0696 12.9643 17.0767 12.667 16.7882L6.22764 10.5382C6.08212 10.3969 6 10.2028 6 10C6 9.79721 6.08212 9.60306 6.22764 9.46181L12.667 3.21181C12.9643 2.92333 13.4391 2.93041 13.7276 3.22764Z"
      fill={colors.CHARCOAL_100}
    />
  </svg>
);
