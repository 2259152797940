import {
  IconLink,
  PINE_100,
  StyledIconLinkProps,
  WHITE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled, { css } from 'styled-components';

// To be consistent across browsers because safari
// does not support focus-visible
const focusVisibleSelectorMixin = `[data-focus-visible-added]`;

const StyledSideNavLink = styled(IconLink)<{ isActive?: boolean }>`
  color: ${WHITE_100};

  :hover {
    background-color: ${PINE_100};
  }

  &${focusVisibleSelectorMixin}::after {
    border: 2px solid ${WHITE_100};
  }

  p {
    ${({ isActive }) =>
      isActive &&
      css`
        font-weight: 700;
      `}
  }
`;

type SideNavLinkProps = Omit<StyledIconLinkProps, 'iconPlacement' | 'size' | 'icon'> & {
  isActive?: boolean;
};

/**
 * This component is based off the component library's IconLink.
 *
 * There is no way to disable the icon, so we pass an empty element as the icon,
 * with some custom styling defined above.
 */
export const SideNavLink = ({ children, isActive, onClick, ...props }: SideNavLinkProps) => {
  // Need to define a link for it to receive a tab stop.  But we don't want clicking on the link
  // to reload the page, so prevent the default behavior when the link is clicked
  const onClickWrapper = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onClick?.(e);
  };
  return (
    <StyledSideNavLink
      icon={<React.Fragment />}
      link="#"
      onClick={onClickWrapper}
      isActive={isActive}
      {...props}
    >
      {children}
    </StyledSideNavLink>
  );
};
