import React from 'react';
import { useSelector } from 'react-redux';
import { PreapprovalPieChart } from '../../components/simulator/pie-chart';
import { monthlyPaymentSelector } from '../../redux/simulator/monthly-payment/selectors';

export const ConnectedPieChart = () => {
  const monthlyPayment = useSelector(monthlyPaymentSelector);

  return <PreapprovalPieChart {...monthlyPayment} />;
};
