import { combineReducers } from 'redux';
import { inputsReducer } from './inputs/reducers';
import { monthlyPaymentReducer } from './monthly-payment/reducers';
import { sliderConstraintsReducer } from './slider-constraints/reducers';
import { specialScenarioReducer } from './special-scenario/reducers';

export const simulatorReducer = combineReducers({
  inputs: inputsReducer,
  monthlyPayment: monthlyPaymentReducer,
  sliderConstraints: sliderConstraintsReducer,
  specialScenario: specialScenarioReducer
});
