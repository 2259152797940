import { currency } from '@rategravity/frontend/modules/numbers';
import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useIsMobile } from '../../../../hooks/is-mobile';
import { IconTooltip } from '../tooltip';
import { NumbersSummaryProps } from '../types';
import { MediumPrice, RegularDarkText } from '../typography';

export const NumbersSummaryContainer = createOwnUpComponent(
  'div',
  ({ isMobile }: { isMobile?: boolean }) =>
    createOwnUpStyle({
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      margin: '0 auto 16px',
      ...(isMobile
        ? { width: '310px', columnGap: '20px' }
        : {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: 0
          })
    })
);

export const PriceContainer = createOwnUpComponent(
  'div',
  createOwnUpStyle({
    display: 'flex',
    flexDirection: 'column',
    variants: {
      desktopMonthlyPayment: {
        textAlign: 'right'
      }
    }
  })
);

const TooltipWrapper = createOwnUpComponent('div', ({ isMobile }: { isMobile: boolean }) =>
  createOwnUpStyle({
    ...(isMobile
      ? {
          margin: '5px 0 0 5px'
        }
      : { margin: '5px 0 0 10px' })
  })
);

const tooltipContent = {
  homePrice:
    'The price of the home for preapproval. The 3 most important factors that impact this number are loan-to-value (LTV) ratio, debt-to-income (DTI) ratio, and credit score.',
  monthlyPayment:
    'What you can expect to pay per month for a property of this price. This includes principal & interest plus fees, taxes, and insurance. Drag the slider to to increase or decrease this amount and see how it impacts your likelihood of preapproval.'
};

export const NumbersSummary = React.memo(
  ({ homePrice, monthlyPayment, error = false }: NumbersSummaryProps) => {
    const isMobile = useIsMobile(1024);
    const renderMonthlyPayment = monthlyPayment ? currency(monthlyPayment, 0) : currency(0, 0);

    return (
      <NumbersSummaryContainer isMobile={isMobile}>
        <PriceContainer>
          <MediumPrice>{error ? '-' : currency(homePrice || 0, 0)}</MediumPrice>
          <div style={{ display: 'flex' }}>
            <RegularDarkText style={{ marginTop: '5px' }}>Home price</RegularDarkText>
            <TooltipWrapper isMobile={isMobile}>
              <IconTooltip content={tooltipContent['homePrice']} name="home-price" />
            </TooltipWrapper>
          </div>
        </PriceContainer>
        <PriceContainer variant={isMobile ? '' : 'desktopMonthlyPayment'}>
          <MediumPrice>{error ? '-' : renderMonthlyPayment}</MediumPrice>
          <div style={{ display: 'flex' }}>
            <RegularDarkText style={{ marginTop: '5px' }}>Monthly payment</RegularDarkText>
            <TooltipWrapper isMobile={isMobile}>
              <IconTooltip content={tooltipContent['monthlyPayment']} name="monthly-payment" />
            </TooltipWrapper>
          </div>
        </PriceContainer>
      </NumbersSummaryContainer>
    );
  }
);
