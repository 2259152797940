import { createSelector } from 'reselect';
import { ApplicationState } from '../../../../redux/state';

export const specialScenarioSelector = ({
  preapprovals: {
    simulator: { specialScenario }
  }
}: ApplicationState) => specialScenario;

export const errorScenarioSelector = createSelector(specialScenarioSelector, (specialScenario) => {
  // slider is disabled in low credit, high dti and lack of proof of income scenarios
  return specialScenario !== null && [0, 2, 3].includes(specialScenario);
});
