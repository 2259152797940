import { OwnUpFillButtonPrimary } from '@rategravity/own-up-component-library';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { generate } from '../../redux/generate';

const ButtonWrapper = styled('div')(({ theme }) => ({
  marginTop: '32px',
  [theme.breakpoints.up('md')]: {
    marginTop: '48px'
  }
}));

export const GenerateButton = () => {
  const dispatch = useDispatch();
  return (
    <ButtonWrapper>
      <OwnUpFillButtonPrimary
        onClick={() => {
          dispatch(generate());
        }}
      >
        Generate preapproval
      </OwnUpFillButtonPrimary>
    </ButtonWrapper>
  );
};
