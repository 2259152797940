import * as t from 'type-shift';

export interface InputsState {
  homePrice: number | null;
  annualIncome: number | null;
  downPayment: number | null;
  monthlyDebt: number | null;
  approximateCreditScore: number | null;
  annualInterestRate: number | null;
  annualPropertyTaxRate: number | null;
  monthlyFee: number | null;
  monthlyHomeownersInsurance: number | null;
  jumboLoanLimit: number | undefined;
}

export type SimulatorPresets = Partial<InputsState> | null;

export const inputsStateConverter = t.strict<InputsState>({
  homePrice: t.number.or(t.null),
  annualIncome: t.number.or(t.null),
  downPayment: t.number.or(t.null),
  monthlyDebt: t.number.or(t.null),
  approximateCreditScore: t.number.or(t.null),
  annualInterestRate: t.number.or(t.null),
  annualPropertyTaxRate: t.number.or(t.null),
  monthlyFee: t.number.or(t.null),
  monthlyHomeownersInsurance: t.number.or(t.null),
  jumboLoanLimit: t.number
});

export const DEFAULT_INPUTS_STATE: InputsState = {
  homePrice: 400_000,
  annualIncome: 80_000,
  downPayment: 50_000,
  monthlyDebt: 300,
  approximateCreditScore: 720,
  annualInterestRate: 5,
  annualPropertyTaxRate: 0.875,
  monthlyFee: 0,
  monthlyHomeownersInsurance: 200,
  jumboLoanLimit: undefined
};
