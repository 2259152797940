import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useIsMobile } from '../../../../../hooks/is-mobile';
import { UpdateInputsAction } from '../../../../redux/simulator/inputs/actions';
import { InputsState } from '../../../../redux/simulator/inputs/state';
import { InputLabel, InputWrapper, InternalNumberInput } from '../../inputs/components';

const VerticalLineStyle = createOwnUpStyle({
  position: 'relative',
  width: '.5px',
  height: '32px',
  top: '14px',
  right: '-10px',
  borderLeft: '.5px solid rgb(219, 225, 225)'
});

const VerticalLine = createOwnUpComponent('div', VerticalLineStyle);

interface BaseInputProps {
  value: number | undefined;
}

interface NumberInputProps extends BaseInputProps {
  updateDownPayment: (payload: Partial<InputsState>) => UpdateInputsAction;
  id?: string;
}

const NumberInput = ({ value, updateDownPayment, id }: NumberInputProps) => {
  return (
    <>
      <InputLabel aria-hidden>$</InputLabel>
      <InternalNumberInput
        id={id}
        onChange={(input: number | undefined) => {
          if (input !== undefined) {
            updateDownPayment({ downPayment: input });
          }
        }}
        name="dollars"
        value={value}
        positive
      />
    </>
  );
};

/**
 * Re-render the input for down payment IF percentage and dollar value get sufficiently out of sync.
 * @param input User-entered value for down payment percentage
 * @param anchor Home price
 * @param currValue Dollar value for down payment
 * @returns boolean
 */
const rerenderInput = (input: number, anchor: number, currValue: number | undefined) => {
  const currentPercentage = ((currValue || 0) / anchor) * 100;
  return Math.round(currentPercentage) !== input;
};

interface PercentInputProps extends BaseInputProps {
  anchor: number;
  percent: number | undefined;
  updateDownPayment: (payload: Partial<InputsState>) => UpdateInputsAction;
}

const PercentInput = ({ anchor, value, percent, updateDownPayment }: PercentInputProps) => (
  <>
    <InternalNumberInput
      variant="percent"
      onChange={(input: number | undefined) => {
        if (input !== undefined && rerenderInput(input, anchor, value)) {
          updateDownPayment({ downPayment: anchor * (input / 100) });
        }
      }}
      name="percent"
      aria-label="Down payment as a percentage of the home price"
      value={percent}
      maxIntegers={3}
      positive
      area-labelledby="Down Payment Percent"
    />
    <InputLabel variant="percent" aria-hidden>
      %
    </InputLabel>
  </>
);

export const DownPaymentInput = ({
  anchor,
  updateDownPayment,
  value,
  percent,
  id = 'downPayment'
}: NumberInputProps & PercentInputProps) => {
  const isMobile = useIsMobile(1024);
  return (
    <InputWrapper isMobile={isMobile}>
      <NumberInput id={id} value={value} updateDownPayment={updateDownPayment} />
      <VerticalLine></VerticalLine>
      <PercentInput
        anchor={anchor}
        value={value}
        percent={percent}
        updateDownPayment={updateDownPayment}
      />
    </InputWrapper>
  );
};
