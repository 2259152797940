import { composeReducers, ofType, withDefault } from 'redux-compose';
import {
  UPDATE_SLIDER_CONSTRAINTS_ACTION_TYPE,
  UPDATE_SLIDER_VALUE_ACTION_TYPE,
  UpdateSliderConstraintsAction,
  UpdateSliderValueAction
} from './actions';
import { DEFAULT_SLIDER_CONSTRAINTS_STATE, SliderConstraintsState } from './state';

const updateSliderConstraints = (
  state: SliderConstraintsState,
  { payload }: UpdateSliderConstraintsAction
): SliderConstraintsState => ({
  ...state,
  ...payload
});

const updateSliderValue = (
  state: SliderConstraintsState,
  { payload: { sliderValue } }: UpdateSliderValueAction
): SliderConstraintsState => ({
  ...state,
  sliderValue
});

export const sliderConstraintsReducer = composeReducers(
  withDefault<SliderConstraintsState>(DEFAULT_SLIDER_CONSTRAINTS_STATE),
  ofType(UPDATE_SLIDER_CONSTRAINTS_ACTION_TYPE, updateSliderConstraints),
  ofType(UPDATE_SLIDER_VALUE_ACTION_TYPE, updateSliderValue)
);
