import React from 'react';
import { useSelector } from 'react-redux';
import { useConsultLink } from '../../../hooks/use-consult-link';
import { leadSelector, leadTargetsSelector, TARGET_OWN_UP_ID } from '../../../redux/manifest';
import { NextStep } from '../../components/simulator/next-step';
import { specialScenarioSelector } from '../../redux/simulator/special-scenario/selectors';

export const ConnectedNextStep = () => {
  const lead = useSelector(leadSelector).value;
  const leadTargets = useSelector(leadTargetsSelector).value;

  const disabled = useSelector(specialScenarioSelector) != null;

  const ycbmLink = useConsultLink();
  // display nothing if the user has a completed lead that does not include a consult pitch
  const consultReady =
    lead === undefined ||
    (leadTargets || []).some(({ targetName }) => targetName === TARGET_OWN_UP_ID);

  return consultReady ? <NextStep ycbmLink={ycbmLink} disabled={disabled} /> : <React.Fragment />;
};
