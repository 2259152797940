import * as t from 'type-shift';

export const lenderReviewConverter = t.strict<LenderReview>({
  profileURL: t.string,
  rating: t.number,
  reviewURL: t.string,
  reviews: t.array(t.unknown),
  totalReviews: t.number
});

export interface LenderReview {
  profileURL: string;
  rating: number;
  reviewURL: string;
  reviews: Array<unknown>;
  totalReviews: number;
}

const ouLenderReviewConverter = t.strict<OULenderReview>({
  reviewDate: t.number,
  lenderReview: t.string,
  lenderRating: t.number,
  reviewer: t.optional(t.string)
});

export const ouLenderReviewDataConverter = t.strict<OULenderReviewData>({
  averageScore: t.number,
  totalReviews: t.number,
  reviews: t.array(ouLenderReviewConverter)
});

export interface OULenderReview {
  reviewDate: number;
  lenderReview: string;
  lenderRating: number;
  reviewer?: string;
}

export interface OULenderReviewData {
  averageScore: number;
  totalReviews: number;
  reviews: OULenderReview[];
}

export interface LenderReviewLoadingStatus {
  loadingStatus: 'pending' | 'failed' | 'lenderId_not_found';
}

export interface Lender {
  lenderReview: LenderReview | LenderReviewLoadingStatus;
  ouLenderReviewData: OULenderReviewData | LenderReviewLoadingStatus;
  lenderRepresentation?: LenderRepresentation;
}

export const isSuccessLenderReview = (
  lenderReview: Lender['lenderReview']
): lenderReview is LenderReview => (lenderReview as LenderReview).rating !== undefined;

export interface LenderRepresentation {
  id: string;
  name: string;
  fullName: string;
  url: string;
  nmls: string;
  logo: string;
  licensedStates?: string[];
  yearFounded?: number;
  fundedAmountLastYear?: string;
  logoAsset: {
    base: string;
    square: string;
  };
}

export const lenderRepresentationsConverter = t.array(
  t.shape<LenderRepresentation>({
    id: t.string,
    name: t.string,
    fullName: t.string,
    url: t.string,
    nmls: t.string,
    logo: t.string,
    licensedStates: t.optional(t.array(t.string)),
    yearFounded: t.optional(t.number),
    fundedAmountLastYear: t.optional(t.string),
    logoAsset: t.strict({
      base: t.string,
      square: t.string
    })
  })
);
