import { formatCurrency } from '@rategravity/core-lib/rates';
import { fonts } from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import { useDisclosureState } from 'reakit/Disclosure';
import { getKeys } from '../../../modules/simulator/util';
import { Accordion } from '../accordion';
import { IconTooltip } from '../tooltip';
import { MonthlyPayment } from '../types';
import { CellDetailsIndex } from './types';

export const Legend = ({
  cellDetails,
  data
}: {
  cellDetails: CellDetailsIndex;
  data: MonthlyPayment;
}) => {
  const controller = useDisclosureState({ visible: false });

  return (
    <div>
      <Accordion
        title="Monthly payment breakdown"
        controller={controller}
        action="expandMonthlyBreakdown-PAS"
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr auto auto',
            gap: '16px',
            alignItems: 'center',
            marginTop: '16px'
          }}
        >
          {getKeys(cellDetails).map((key, i) => {
            const { name, fill, tooltip } = cellDetails[key];
            return (
              <React.Fragment key={`cellDetail-${i}`}>
                <svg
                  version="1.1"
                  baseProfile="full"
                  width="14"
                  height="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="100%" height="100%" fill={fill} rx={2} />
                </svg>
                <span style={{ display: 'flex', alignContent: 'center' }}>{name}</span>
                <span
                  style={{
                    ...fonts.GRAPHIK_MEDIUM,
                    display: 'flex',
                    justifyContent: 'end',
                    alignContent: 'center'
                  }}
                >
                  {typeof data[key] !== undefined ? formatCurrency(data[key] as number) : '-'}
                </span>
                {tooltip ? <IconTooltip content={tooltip} name={name} /> : null}
              </React.Fragment>
            );
          })}
        </div>
      </Accordion>
    </div>
  );
};
