import { Actions } from '@rategravity/frontend/modules/user-actions';
import { getEnhancedConversionData } from './enhanced-conversion-data';

/**
 * Ferry events in to Google Tag Manager/Google Analytics if it is present
 *   on the page with any available enhanced conversion data attached. The
 *   Google Analytics tooling is installed via GTM and is not a part of the
 *   code base, but if it was successfully added to the page this function
 *   will forward events to it.
 */
export const sendToGoogle = (action: Actions) => {
  if (action.type === 'Event') {
    const enhancedConversionData = getEnhancedConversionData();
    // If the dataLayer has not been initialized yet, initialize it as an
    //   empty array. GTM will drain it when it loads.
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      // Inject any enhanced conversion data first
      ...enhancedConversionData,
      ...action.properties,
      event: action.id
    });
  }
};

/**
 * Observer that ferries events to google.
 */
export const googleObserver = () => sendToGoogle;
