import {
  appendDeclaration,
  buildPIIDeclaration,
  Decrypted,
  EncryptedField,
  encryptedFieldConverter
} from '@rategravity/encryption-lib';
import * as t from 'type-shift';
import {
  EncryptedUnifiedLeadPayload,
  unifiedLeadPayloadConverter,
  unifiedLeadPayloadPIIDeclaration
} from './unified-lead-payload';

export const incomeTypes = [
  'Salary',
  'Hourly',
  'Bonus',
  'Commission',
  'Pension',
  'SelfEmployed',
  'NoIncome',
  'Other'
] as const;
export type IncomeType = (typeof incomeTypes)[number];
export const incomeTypeConverter = t.oneOf<IncomeType>([...incomeTypes]);

export const timelineTypes = ['0-3Months', '3-6Months', '6+Months'] as const;
export type TimelineTypes = (typeof timelineTypes)[number];
export const timelineTypeConverter = t.oneOf<TimelineTypes>([...timelineTypes]);

export const employmentStatus = ['Employed', 'SelfEmployed', 'Retired', 'Unemployed'] as const;
export type EmploymentStatus = (typeof employmentStatus)[number];
export const employmentStatusConverter = t.oneOf<EmploymentStatus>([...employmentStatus]);

export const questionnaireTypes = [
  'purchase',
  'preapproval',
  'refinance',
  'accepted-offer'
] as const;
export type QuestionnaireType = (typeof questionnaireTypes)[number];
export const questionnaireTypeConverter = t.oneOf<QuestionnaireType>([...questionnaireTypes]);

// Same as unifiedLead creditRating with addition of 'Very Poor'
export const creditRating = ['Excellent', 'Good', 'Fair', 'Poor', 'Very Poor'] as const;
export type CreditRating = (typeof creditRating)[number];
export const creditRatingConverter = t.oneOf<CreditRating>([...creditRating]);

export const loanType = ['Conventional', 'FHA', 'HELOC', 'Reverse', 'USDA', 'VA'] as const;
export type LoanType = (typeof loanType)[number];
export const loanTypeConverter = t.oneOf<LoanType>([...loanType]);

export const currentProductTypes = [
  'Fixed30',
  'Fixed20',
  'Fixed15',
  'Fixed10',
  'ARM10',
  'ARM7',
  'ARM5',
  'Other'
] as const;
export type CurrentProductType = (typeof currentProductTypes)[number];
export const currentProductTypeConverter = t.oneOf<CurrentProductType>([...currentProductTypes]);

/** A tier 1 lead from a consult  */
export const PREAPPROVAL_LEAD_TYPE = 'preapproval-lead';
/** A tier 2 lead from a consult */
export const PREAPPROVAL_TIER_TWO_LEAD_TYPE = 'preapproval-lead-tier-2';
/** Ingested web lead from the questionnaire or a partner channel */
export const WEB_LEAD_TYPE = 'web-lead';
/** Lead from a no show consult or deemed unqualified */
export const DIRECT_WEB_LEAD_TYPE = 'direct-web-lead';

export const leadTypes = [
  PREAPPROVAL_LEAD_TYPE,
  PREAPPROVAL_TIER_TWO_LEAD_TYPE,
  WEB_LEAD_TYPE,
  DIRECT_WEB_LEAD_TYPE
] as const;
export type LeadType = (typeof leadTypes)[number];
export const leadTypeConverter = t.oneOf<LeadType>([...leadTypes]);

/**
 * Standardized representation of lead in Own Up's system. These could be
 *   sourced from anywhere, including our questionnaire, the RDC pipeline,
 *   etc. All leads are converted to a single schema so each lead can be
 *   evaluated based on the same criteria.
 */
export interface EncryptedInternalLeadPayload
  extends Omit<EncryptedUnifiedLeadPayload, 'creditRating'> {
  /** Source for this lead (e.g., Realtor.com) */
  source: string;
  /** Id for this lead, from the source */
  sourceId: string;
  /** anonymous id for the lead when originating from Own Up */
  anonymousId?: string;
  /** Source channel for the lead (e.g., questionnaire) */
  sourceChannel?: string;
  /** Amount in dollars we paid for this lead (if known) */
  leadPricePaid?: number;
  /**
   * Type of questionnaire this was sourced from--if applicable
   *
   * @deprecated - lead type should be inferred from the fields on the
   *   lead rather than questionnaire type.
   */
  questionnaireType?: QuestionnaireType;
  /**
   * Bankruptcy status. Set to `true` if the shopper has had a bankruptcy in the past
   *   two years.
   */
  bankruptcy?: boolean;
  /** Date Lead Received */
  dateLeadReceived?: number;
  /**
   * Down payment amount
   *
   * @deprecated - this is automatically calculated from other loan info
   */
  downPayment?: number;
  /**
   * Employment status
   *
   * @deprecated - Should use IncomeType instead -- it's more detailed
   */
  employmentStatus?: EmploymentStatus;
  /** Lead exclusive to Own Up or shared */
  exclusive?: boolean;
  /** If there is a coborrower involved in the scenario */
  hasCoborrower?: boolean;
  /** Coborrower's email */
  coborrowerEmail?: string | EncryptedField;
  /** Shopper is working with a real estate agent */
  hasAgent?: boolean;
  /** Epoch timestamp when questionnaire was the started */
  questionnaireStartTime?: number;
  /** Epoch timestamp when questionnaire was the completed */
  questionnaireCompletionTime?: number;
  /**
   * Same IncomeType enumeration from onboarding-stack
   */
  incomeType?: IncomeType;
  /** Number of months lead is planning to purchase */
  purchaseTimeline?: number;
  /** Epoch timestamp of the close date for a purchase -- if applicable */
  closeDate?: number;
  /**
   * State the shopper is shopping in
   *
   * @deprecated - `propertyState` is used instead
   */
  state?: string;
  /**
   * PurchaseTimeline enumeration from onboarding-stack
   *
   * @deprecated - `purchaseTimeline` is used instead
   */
  timeline?: TimelineTypes;
  /** Overrides unified scheme to add Very Poor tier */
  creditRating: CreditRating;
  /** AH accountId associated with this lead  */
  accountId?: string;
  /** EMAIL of the advisor assigned ot this lead (if applicable) */
  advisor?: string;
  /** HS ContactId associated with this lead */
  hubspotContactId?: string;
  /** HS DealId associated with this lead  */
  hubspotDealId?: string;
  /** The lead's total income per year. */
  grossIncome?: number;
  /** Type of loan lead is looking for */
  loanType?: LoanType;
  /** Partner Campaign */
  campaign?: string;
  /** ID of partner campaign */
  campaignId?: string;
  /** Has an accepted offer */
  hasAcceptedOffer?: boolean;
  /** Additional comments, warning these may be shared with our partners */
  comments?: string | EncryptedField;
  /** Type of current loan (fixed/arm and term) */
  currentProductType?: CurrentProductType;
  /** Type of lead */
  leadType?: LeadType;
}

/**
 * {@see EncryptedInternalLeadPayload} with all encrypted fields decrypted.
 */
export type InternalLeadPayload = Decrypted<EncryptedInternalLeadPayload>;

const employmentStatusToIncomeTypeConverter = t
  .forPath([t.ParentPath, 'employmentStatus'], employmentStatusConverter)
  .pipe((status): IncomeType => {
    switch (status) {
      case 'Employed':
        return 'Other';
      case 'SelfEmployed':
        return 'SelfEmployed';
      case 'Retired':
        return 'Pension';
      case 'Unemployed':
        return 'NoIncome';
    }
  });

const incomeTypeToEmploymentStatusConverter = t
  .forPath([t.ParentPath, 'incomeType'], incomeTypeConverter)
  .pipe((income): EmploymentStatus => {
    switch (income) {
      case 'Pension':
        return 'Retired';
      case 'NoIncome':
        return 'Unemployed';
      case 'SelfEmployed':
        return 'SelfEmployed';
      default:
        return 'Employed';
    }
  });

export const internalLeadPayloadConverter = t.strict<EncryptedInternalLeadPayload>({
  ...unifiedLeadPayloadConverter.converters,
  source: t.string,
  sourceId: t.string,
  anonymousId: t.optional(t.string),
  leadPricePaid: t.optional(t.number),
  // Override the unifiedLead converter for creditRating
  creditRating: creditRatingConverter,

  // Optional properties
  sourceChannel: t.optional(t.string),
  questionnaireType: t.optional(questionnaireTypeConverter),
  bankruptcy: t.optional(t.boolean),
  dateLeadReceived: t.optional(t.number),
  downPayment: t.optional(t.number),
  employmentStatus: employmentStatusConverter
    .or(incomeTypeToEmploymentStatusConverter)
    .or(t.undefined),
  exclusive: t.optional(t.boolean),
  hasAgent: t.optional(t.boolean),
  hasCoborrower: t.optional(t.boolean),
  coborrowerEmail: t.optional(t.string.or(encryptedFieldConverter)),
  incomeType: incomeTypeConverter.or(employmentStatusToIncomeTypeConverter).or(t.undefined),
  purchaseTimeline: t.optional(t.number),
  closeDate: t.optional(t.number),
  state: t.optional(t.string),
  timeline: t.optional(timelineTypeConverter),
  questionnaireStartTime: t.optional(t.number),
  questionnaireCompletionTime: t.optional(t.number),
  accountId: t.optional(t.string),
  advisor: t.optional(t.string),
  hubspotContactId: t.optional(t.string),
  hubspotDealId: t.optional(t.string),
  grossIncome: t.optional(t.number),
  loanType: t.optional(loanTypeConverter),
  campaign: t.optional(t.string),
  campaignId: t.optional(t.string),
  hasAcceptedOffer: t.optional(t.boolean),
  comments: t.optional(t.string.or(encryptedFieldConverter)),
  currentProductType: t.optional(currentProductTypeConverter),
  leadType: t.optional(leadTypeConverter)
});

/**
 * PII declaration for the {@link InternalLeadPayload}
 */
export const internalLeadPayloadPIIDeclaration = appendDeclaration(
  unifiedLeadPayloadPIIDeclaration,
  buildPIIDeclaration(['comments', 'coborrowerEmail'])
);
