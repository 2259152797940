import { OwnUpTextInput } from '@rategravity/own-up-component-library';
import React, { useState } from 'react';
import { FullAddressProps } from '../../../../components/address/address-helpers';
import { AddressFuzzyMatchCountyType } from '../../../../components/address/fuzzy-match-service';
import { StateDropdown } from './state-county';
import { useAddressAutocomplete } from './use-address-autocomplete';
import { FullWidthGridItem, GridContainer, HalfWidthGridItem } from './util';

// Address component that includes street name(address), unit #, city, state and zip.
export const AddressGrid = ({
  addressModel,
  states,
  onCityChange,
  onStateChange,
  onAddressChange,
  onUnitChange,
  onZipChange,
  onAutocompleteChange,
  addressFuzzyMatchCounty,
  stateError
}: Required<
  Pick<
    FullAddressProps,
    | 'addressModel'
    | 'states'
    | 'onCityChange'
    | 'onStateChange'
    | 'onAddressChange'
    | 'onUnitChange'
    | 'onZipChange'
    | 'onAutocompleteChange'
  >
> & {
  addressFuzzyMatchCounty: AddressFuzzyMatchCountyType;
  stateError: string | null;
}) => {
  const { materialRef } = useAddressAutocomplete({ onAutocompleteChange, addressFuzzyMatchCounty });
  const [shrink, setShrink] = useState(!!addressModel.address);

  return (
    <GridContainer>
      <HalfWidthGridItem>
        <OwnUpTextInput
          label={'Street address'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onAddressChange(e.target.value);
          }}
          value={addressModel.address || undefined}
          InputLabelProps={{ shrink }}
          onFocus={() => setShrink(true)}
          onBlur={() => setShrink(false)}
          placeholder=""
          inputRef={materialRef}
        />
      </HalfWidthGridItem>
      <HalfWidthGridItem>
        <OwnUpTextInput
          label={'Unit #'}
          value={addressModel.unit || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onUnitChange(e.target.value);
          }}
        />
      </HalfWidthGridItem>
      <FullWidthGridItem>
        <OwnUpTextInput
          label={'City'}
          value={addressModel.city || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onCityChange(e.target.value);
          }}
        />
      </FullWidthGridItem>
      <HalfWidthGridItem>
        <StateDropdown
          stateOptions={states}
          address={addressModel}
          error={stateError || undefined}
          onChange={(e) => {
            onStateChange(e.target.value as string);
          }}
        />
      </HalfWidthGridItem>
      <HalfWidthGridItem>
        <OwnUpTextInput
          label={'Zip'}
          value={addressModel.zip || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onZipChange(e.target.value as any);
          }}
        />
      </HalfWidthGridItem>
    </GridContainer>
  );
};
