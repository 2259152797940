import React from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownProps } from '../../../preapproval/components/simulator/inputs';
import { inputsSelector } from '../../redux/simulator/inputs/selectors';

export const CreditScoreDropdown = ({ options, onChange, labelId }: DropdownProps) => {
  const { approximateCreditScore } = useSelector(inputsSelector);
  return (
    <Dropdown
      options={options}
      value={approximateCreditScore}
      onChange={onChange}
      labelId={labelId}
    />
  );
};
