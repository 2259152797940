import { affordabilityDetails } from '../../../preapproval/data';

export interface GetAffordabilityDetailsArgs {
  specialScenario: number | null;
  sliderMin: number;
  sliderValue: number;
  maxAffordable: number;
  maxStretch: number;
  dti: number;
  downPaymentPercent: number;
}

export const getAffordabilityDetails = ({
  specialScenario,
  sliderMin,
  sliderValue,
  maxAffordable,
  maxStretch,
  dti,
  downPaymentPercent
}: GetAffordabilityDetailsArgs) => {
  const codes = [
    { code: 'Low Credit' },
    { code: 'Small Loan Size' },
    { code: 'Aggressive', dti },
    { code: 'Proof of Income' },
    { code: 'Low Down Payment' }
  ];

  if (specialScenario !== null) {
    return affordabilityDetails(codes[specialScenario]);
  } else if (sliderMin < sliderValue && sliderValue < maxAffordable) {
    return affordabilityDetails({
      code: 'Affordable',
      dti,
      downPaymentPercent: downPaymentPercent
    });
  } else if (maxAffordable < sliderValue && sliderValue < maxStretch) {
    return affordabilityDetails({ code: 'Stretching', dti });
  } else {
    return affordabilityDetails({ code: 'Aggressive', dti });
  }
};
