import { Advisor, useAdvisors } from '@rategravity/own-up-component-library-legacy';
import { parse } from 'querystring';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { advisorDataSelector } from '../../redux/advisor-data/selector';
import { AdvisorReviews } from '../../redux/advisor-data/state';
import {
  latestExperienceSelector,
  manifestAccountInfoSelector
} from '../../redux/manifest/selector';
import { getAdvisorIdByEmail } from '../../redux/selector';

export const preferredAdvisorSelector = createSelector(
  manifestAccountInfoSelector,
  latestExperienceSelector,
  (manifest, experience) => {
    let advisorString: string | undefined;
    const urlAdvisor = window.location.search
      ? parse(window.location.search.substring(1)).advisor // Trim the leading ?
      : undefined;
    if (urlAdvisor) {
      if (Array.isArray(urlAdvisor)) {
        advisorString = urlAdvisor[0];
      } else {
        return urlAdvisor;
      }
    }
    if (!advisorString) {
      advisorString = manifest.advisorEmail || experience.value?.advisor;
    }
    return advisorString;
  }
);

type PreferredAdvisor = Advisor & {
  id: string;
} & AdvisorReviews;

/**
 * Hook to get the full advisor object for the preferred advisor, falling
 *   back to the optionally provided advisor id. Can also work with emails.
 *
 * Priority order is url > pitch > consult > manifest
 *
 * Also returns their review stats (if available)
 *
 * @param fallback - use this advisor if no other is found. Note that this
 *   fallback only comes into play if there is no advisor provided by the
 *   data and will not override a _bad_ advisor provided.
 */
export const usePreferredAdvisor = (fallback?: string) => {
  const preferredId = useSelector(preferredAdvisorSelector) || fallback;
  const advisors = useAdvisors();
  const { reviews } = useSelector(advisorDataSelector);
  return useMemo(() => {
    if (preferredId) {
      let found: Partial<PreferredAdvisor> | undefined;
      if (preferredId.includes('@')) {
        found = getAdvisorIdByEmail(preferredId, advisors).value;
      } else {
        found = advisors[preferredId];
        if (found) {
          found.id = preferredId;
        }
      }
      if (found) {
        return { ...found, ...reviews[found.id!] } as PreferredAdvisor;
      }
    }
    return undefined;
  }, [reviews, preferredId, advisors]);
};
