import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpText
} from '@rategravity/own-up-component-library-legacy';
import { colors } from '../../../../modules/colors';

export const RegularDarkText = createOwnUpComponent(
  OwnUpText,
  createOwnUpStyle({
    fontSize: '14px',
    color: colors.SLATE_TWO,
    letterSpacing: '.1px'
  })
);

export const MediumGreenText = createOwnUpComponent(
  OwnUpText,
  createOwnUpStyle({
    ...fonts.GRAPHIK_MEDIUM,
    fontSize: '14px',
    color: colors.PINE,
    letterSpacing: '.1px'
  })
);

export const MediumAzureText = createOwnUpComponent(
  OwnUpText,
  createOwnUpStyle({
    ...fonts.GRAPHIK_MEDIUM,
    fontSize: '14px',
    color: colors.AZURE,
    letterSpacing: '.1px'
  })
);

export const MediumPrice = createOwnUpComponent(
  OwnUpText,
  createOwnUpStyle({
    ...fonts.GRAPHIK_MEDIUM,
    fontSize: '36px',
    lineHeight: '32px',
    letterSpacing: '.1px',
    color: colors.SLATE_TWO,
    variants: {
      mediumAndDown: {
        fontSize: '24px'
      }
    }
  })
);

const InputLabelTextStyle = createOwnUpStyle({
  ...fonts.GRAPHIK_MEDIUM,
  fontSize: '14px',
  letterSpacing: '0.1px',
  lineHeight: '24px',
  textAlign: 'left',
  color: `${colors.SLATE_TWO}`,
  variants: {
    withTooltip: {
      paddingRight: '12px'
    }
  }
});

export const InputLabelText = createOwnUpComponent('label', InputLabelTextStyle);
