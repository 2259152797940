import { PropsWithTheme } from '@rategravity/own-up-component-library';
import styled, { css } from 'styled-components';

export interface ResponsiveContainerProps {
  ignoreResponsivePadding?: boolean;
  ignoreBottomPadding?: boolean;
  ignoreTopPadding?: boolean;
}
/**
 * This container is used to standardize the top and bottom margins for a page
 * section at different breakpoints.
 *
 * @ignoreResponsivePadding can be set to true to not use the margins.  This is
 * useful if a page needs to specify its own margins for a shared component
 * that uses this container.
 *
 * @ignoreBottomPadding can be set to true to not use bottom margins.  In other
 * words, only apply the top margin.
 *
 * @ignoreTopPadding can be set to true to not use top margins.  In other
 * words, only apply the bottom margin.
 *
 * Also note that the GridTemplate uses this container.  So if a shared component
 * that also uses this container is placed in the GridTemplate, the component should
 * set ignoreResponsivePadding to true so the padding is not doubled.
 */
export const ResponsiveContainer = styled.div<PropsWithTheme<ResponsiveContainerProps>>`
  width: 100%;
  display: inline-block;
  ${({ ignoreResponsivePadding }) =>
    !ignoreResponsivePadding &&
    css`
      margin-top: 80px;
      margin-bottom: 96px;
      ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('md')} {
        margin-top: 64px;
        margin-bottom: 80px;
      }
      ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xs')} {
        margin-top: 48px;
        margin-bottom: 48px;
      }
      ${({ ignoreBottomPadding }: PropsWithTheme<{ ignoreBottomPadding?: boolean }>) =>
        ignoreBottomPadding &&
        css`
          & {
            margin-bottom: 0;
          }
        `}
      ${({ ignoreTopPadding }: PropsWithTheme<{ ignoreTopPadding?: boolean }>) =>
        ignoreTopPadding &&
        css`
          & {
            margin-top: 0;
          }
        `}
    `}
`;
