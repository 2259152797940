import { composeReducers, ofType, withDefault } from 'redux-compose';
import { UPDATE_MONTHLY_PAYMENT_ACTION_TYPE, UpdateMonthlyPaymentAction } from './actions';
import { DEFAULT_MONTHLY_PAYMENT_STATE, MonthlyPaymentState } from './state';

const updateMonthlyPayment = (
  state: MonthlyPaymentState,
  { payload }: UpdateMonthlyPaymentAction
): MonthlyPaymentState => ({
  ...state,
  ...payload
});

export const monthlyPaymentReducer = composeReducers(
  withDefault(
    DEFAULT_MONTHLY_PAYMENT_STATE,
    ofType(UPDATE_MONTHLY_PAYMENT_ACTION_TYPE, updateMonthlyPayment)
  )
);
