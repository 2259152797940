import { none, of, Optional, some } from '@rategravity/core-lib/optional';
import { createSelector } from 'reselect';
import { cobrandingMap } from '../../components/cobranding';
import { Consult, Lead, LeadTarget, Manifest } from '../../modules/account/type';
import { SHOW_CHAT_BOT } from '../../modules/split';
import { mapScenarioToQuestionnaireType } from '../../modules/standardize-scenario';
import { ApplicationState } from '../state';

export const optionalManifestDataSelector = createSelector(
  ({ manifest }: ApplicationState): Manifest | undefined =>
    !manifest?.loading && manifest?.success ? manifest?.data : undefined,
  of
);

export const manifestAccountInfoSelector = createSelector(optionalManifestDataSelector, (data) =>
  data
    .map(({ preapprovals, publishedRates, consult, ...accountInfo }) => accountInfo)
    .orElse(() => ({
      name: '',
      email: '',
      hubspotContactId: '',
      advisorEmail: undefined,
      accountId: '',
      simulatorPresets: null,
      showPreapprovalSimulator: false,
      provisional: false,
      leadTargets: []
    }))
);

export const isProvisionalAccountSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) => optionalManifest.map((manifest) => manifest.provisional)
);

export const publishedRatesSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) => optionalManifest.map((manifest) => manifest.publishedRates)
);

export const preapprovalsSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) => optionalManifest.map((manifest) => manifest.preapprovals)
);

/**
 * Returns the questionnaire, but only if it's incomplete.
 */
export const incompleteQuestionnaireSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) =>
    optionalManifest
      .map((manifest) => manifest.incompleteQuestionnaire)
      .filter((x) => !x?.evaluation)
);

export const showPreapprovalSimulatorSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) =>
    optionalManifest.map((manifest) =>
      'showPreapprovalSimulator' in manifest ? manifest.showPreapprovalSimulator : false
    )
);

export const consultSelector = createSelector(optionalManifestDataSelector, (optionalManifest) =>
  optionalManifest.map((manifest) => manifest.consult)
);

export const leadSelector = createSelector(optionalManifestDataSelector, (optionalManifest) =>
  optionalManifest.map((manifest) => manifest.lead)
);

/**
 * Attributes that can be sent to Split for more specific targeting.
 */
export const leadSplitAttributesSelector = createSelector(leadSelector, (optionalLead) =>
  optionalLead.map((lead) => ({
    leadID: lead?.leadID ?? 'undefined',
    source: lead?.source ?? 'undefined',
    hasAcceptedOffer: lead?.hasAcceptedOffer ?? 'undefined',
    loanPurpose: lead?.loanPurpose ?? 'undefined',
    purchaseTimeline: lead?.purchaseTimeline ?? 'undefined'
  }))
);

/**
 * Get the lead locale from the manifest.
 * This is the state the shopper is looking to buy in.
 * Potentially expand this in the future to include city.
 */
export const leadLocaleSelector = createSelector(leadSelector, (optionalLead) =>
  optionalLead.map((lead) => lead?.propertyState).orElse(() => undefined)
);

/**
 * Get the lead targets from the manifest.
 */
export const leadTargetsSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) => optionalManifest.map((manifest) => manifest.leadTargets)
);

/**
 * Selector that looks at the lead source to determine if we have cobranding.
 * Returns the source if cobranding should be used, otherwise undefined.
 */
export const cobrandingSelector = createSelector(leadSelector, (optionalLead) =>
  optionalLead
    .map((lead) => {
      if (!lead || !cobrandingMap[lead.source]) {
        return undefined;
      }
      return lead.source;
    })
    .orElse(() => undefined)
);

/**
 * Returns the latest leadId (if any).
 */
export const latestDataIngestionIdSelector = createSelector(leadSelector, (optionalDataPoint) =>
  optionalDataPoint
    .map((dataPoint): string | undefined => {
      if (dataPoint) {
        return dataPoint.leadID;
      }
      return undefined;
    })
    .orElse(() => undefined)
);

/**
 * Returns the latest "completed" data point from the manifest. This determines
 *   if the user has most recently been represented by a lead or consult.
 *
 * Consult time is based on its end date.
 */
export const latestExperienceSelector = createSelector(
  consultSelector,
  leadSelector,
  (consult, lead) => {
    const ingestionTimestamp = lead
      .map((l) => {
        if (l) {
          return l.dateLeadReceived!;
        }
        return -1;
      })
      .orElse(() => -1);
    if ((consult.value?.endDate || 0) > ingestionTimestamp) {
      return consult as Optional<Consult | Lead>;
    }
    return lead as Optional<Consult | Lead>;
  }
);

/**
 * Target data for the various pitch pages.
 */
export interface Pitch extends Lead {
  /**
   * Targets for this lead. Can be Own Up, lenders, or other
   *   third party services like Anywhere.
   */
  leadTargets: LeadTarget[];

  /** Scenario the shopper is dealing with */
  scenario: string | undefined;
}

/**
 * Figure out some basic pitch data based on the latest complete data point.
 */
export const pitchScenarioSelector = createSelector(
  leadSelector,
  leadTargetsSelector,
  (optionalDataPoint, optionalLeadTargets) =>
    optionalDataPoint.map((lead): Pitch | undefined => {
      if (lead) {
        return {
          ...lead,
          leadTargets: optionalLeadTargets.value || [],
          scenario: mapScenarioToQuestionnaireType(lead.questionnaireType || lead.loanPurpose)
        };
      }
      return undefined;
    })
);

export const TARGET_OWN_UP_ID = 'ownUp' as const;
export const TARGET_ANYWHERE_ID = 'anywhere' as const;

/**
 * Returns true if the current pitch experience has Own Up, no matter what other
 *   lead targets are present.
 *   Note that this will return true if it's Own Up and Anywhere, so you'll need to
 *   check for that separately.
 */
export const hasConsultPitchSelector = createSelector(pitchScenarioSelector, (pitchData) =>
  pitchData
    .map((x) => x?.leadTargets.some((y) => y.targetName === TARGET_OWN_UP_ID))
    .orElse(() => false)
);
/**
 * Returns true if the lead includes the chat bot experiment group
 */
export const hasChatBotExperienceSelector = createSelector(pitchScenarioSelector, (pitchData) =>
  pitchData.map((x) => x?.experimentGroups.includes(SHOW_CHAT_BOT)).orElse(() => false)
);

/**
 * Determine the lender direct lenders from the latest complete data point.
 *   Powered by a lead.
 */
export const lenderDirectLenderIdsSelector = createSelector(
  pitchScenarioSelector,
  (optionalPitch) =>
    optionalPitch
      .map((dataPoint) => {
        const matched = dataPoint?.leadTargets.reduce((acc, cur) => {
          if (cur.targetName !== TARGET_OWN_UP_ID && cur.targetName !== TARGET_ANYWHERE_ID) {
            acc.push(cur.targetName);
          }
          return acc;
        }, [] as string[]);
        return matched || [];
      })
      .orElse(() => [])
);

export const customerNameSelector = createSelector(manifestAccountInfoSelector, ({ name }) => name);

export const manifestAccountLoadingSelector = ({ manifest: { loading } }: ApplicationState) =>
  loading;

export const welcomeTextSelector = createSelector(publishedRatesSelector, (rates) =>
  rates
    .flatMap((quotes) =>
      quotes.length > 0 ? some('Your personalized quotes are ready') : none<string>()
    )
    .orElse(() => 'Your home financing hub')
);

/**
 * Return the Anywhere Opt In Status from the manifest.
 *
 * If undefined, no Anywhere stuff has been offered.
 */
export const anywhereOptInStatusSelector = createSelector(
  optionalManifestDataSelector,
  (optionalManifest) => optionalManifest.map((manifest) => manifest.anywhereOptInStatus)
);
