import { all, Optional } from '@rategravity/core-lib/optional';
import {
  ComputeBreakEvenInputs,
  computeFHAMip,
  ComputeMI,
  computeRegularMI,
  computeTotalCost
} from '@rategravity/core-lib/rates';
import { createSelector } from 'reselect';
import { resolveFeeValue } from '../../modules/fee-helpers';
import { getLoanTerms } from '../../modules/loan-type';
import { LoadedRate, Offer } from '../state';
import { currentPathSelector } from './path';
import { offerByIdSelector, rateQuoteSelector } from './rate-quote';

const offerAIdSelector = createSelector(
  currentPathSelector,
  (path) => path.match(/\/compare\/([^/]+)/)?.[1]
);

export const offerASelector = createSelector(
  rateQuoteSelector,
  offerAIdSelector,
  (rateQuote, offerId) => offerByIdSelector(rateQuote, offerId)
);

/**
 * Upfront costs are any costs that the borrower incurs to take on the loan
 * that are not covered by the principle of the loan itself. For instance an FHA
 * loan includes an upfront mortgage payment, however that upfront cost is included
 * in the value of the loan and not paid by the borrower.
 * We specifically care about fees that are paid directly to the lender rather than
 * costs that the lender simply passing along to the borrower.
 */
const computeUpFrontCosts = (offer: Optional<Offer>): Optional<number> =>
  offer.map(
    ({ closingFinanced, allFees }) =>
      allFees
        .filter(({ group }) => group === 'LenderFee')
        .reduce((sum, { value }) => sum + resolveFeeValue(value), 0) - closingFinanced
  );

export const offerAUpfrontCosts = createSelector(offerASelector, computeUpFrontCosts);

const createFixedLengthMI =
  (numberOfMonths: number): ComputeMI =>
  (inputs, amortization) =>
    amortization.map((period, month) => ({
      ...period,
      mortgageInsurance: month < numberOfMonths ? inputs.mortgageInsurance : 0
    }));

const computeCostInputs = (
  rateQuote: Optional<LoadedRate>,
  offer: Optional<Offer>,
  upFrontCosts: Optional<number>
): Optional<ComputeBreakEvenInputs> =>
  all([rateQuote, offer, upFrontCosts]).map(([rq, o, totalFees]): ComputeBreakEvenInputs => {
    const { loanTerm, fixedTerm } = getLoanTerms(o.loanType);
    let computeMI = computeRegularMI;
    if (o.monthlyMI.monthsOfPayment !== undefined) {
      computeMI = createFixedLengthMI(o.monthlyMI.monthsOfPayment);
    } else if (o.upFrontInsuranceFee?.label === 'Upfront MIP') {
      computeMI = computeFHAMip;
    }
    return {
      totalFees,
      initialRate: o.rate,
      presentValue:
        (rq.purchasePrice ?? 0) -
        (o.downPayment ?? 0) +
        ((rq.existingLoanSize ?? 0) +
          o.rolledIn +
          (o.cashOutValue ?? 0) +
          o.closingFinanced +
          (o.upFrontInsuranceFee?.value || 0)),
      loanTerm,
      fixedTerm,
      propertyValue: (rq.purchasePrice ?? 0) + (rq.propertyValue ?? 0),
      mortgageInsurance: o.monthlyMI.value,
      upFrontMip: o.upFrontInsuranceFee?.value ?? 0,
      computeMI
    };
  });

export const offerACostInputs = createSelector(
  rateQuoteSelector,
  offerASelector,
  offerAUpfrontCosts,
  computeCostInputs
);

const computeMonthlyPayment = (offer: Optional<Offer>): Optional<number> =>
  offer.map(({ pAndI, monthlyMI }) => pAndI + monthlyMI.value);

export const offerAMonthlyPayment = createSelector(offerASelector, computeMonthlyPayment);

const computeLifetimeCosts = (offer: Optional<ComputeBreakEvenInputs>): Optional<number> =>
  offer.map(({ computeMI, ...inputs }) => computeTotalCost(inputs, computeMI));

export const offerALifetimeCosts = createSelector(offerACostInputs, computeLifetimeCosts);

const offerBIdSelector = createSelector(
  currentPathSelector,
  (path) => path.match(/\/compare\/.+?\/([^/]+)/)?.[1]
);

export const offerBSelector = createSelector(
  rateQuoteSelector,
  offerBIdSelector,
  (rateQuote, offerId) => offerByIdSelector(rateQuote, offerId)
);

export const offerBUpfrontCosts = createSelector(offerBSelector, computeUpFrontCosts);

export const offerBCostInputs = createSelector(
  rateQuoteSelector,
  offerBSelector,
  offerBUpfrontCosts,
  computeCostInputs
);

export const offerBMonthlyPayment = createSelector(offerBSelector, computeMonthlyPayment);

export const offerBLifetimeCosts = createSelector(offerBCostInputs, computeLifetimeCosts);
