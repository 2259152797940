import { Fee, FeeValue } from '../state';

/**
 * Maps the fee type to the description of the fee
 * Keep these in alphabetical order as it makes it easier to scan.
 */
const getBaseFeeDescription = (type: string) => {
  switch (type) {
    case 'appraisal':
      return 'Cost for an appraiser hired by the lender to assess the value of your property.';
    case 'appraisalReview':
      return 'Fee charged by the lender to review the results of your appraisal.';
    case 'attorney':
      return 'Cost of the attorney to complete a title search and close your financing.';
    case 'attorneyDocReview':
      return 'Cost of an attorney to review your documents, mandated by your state.';
    case 'closingProtectionLetter':
      return 'The cost to insure the lender against certain kinds of misconduct by the closing agent.';
    case 'condoInsurance':
      return 'Fee for the HO6 Walls-In insurance policy.';
    case 'condoQuestionnaire':
      return "Estimate of a fee sometimes assessed by condo associations to respond to questions from the lender's condo questionnaire.";
    case 'condoReview':
      return 'Fee assessed by your lender to review documents associated with your condo association.';
    case 'constructionReview':
      return 'Fee on construction loans to order an incremental inspection on your property.';
    case 'creditRefreshFee':
      return 'Cost to refresh borrower debts prior to closing to verify updated credit balances.';
    case 'creditReport':
      return 'Cost for a tri-merge credit report from each of the three credit reporting agencies.';
    case 'dataValidation':
      return 'Fee charged by the lender to conduct a compliance review on your loan.';
    case 'dfiPolicy':
      return 'Closing cost fee for issuance of a title policy.';
    case 'documentDelivery':
      return 'Fee charged generally by the title company or attorney for the delivery of documents to your lender.';
    case 'documentPreparation':
      return 'Fee charged by the lender to process documents associated with your loan.';
    case 'documentTax':
      return 'Excise tax imposed on certain documents executed, delivered, or recorded.';
    case 'eClosing':
      return 'Lender fee associated with a closing that is partially remote and done digitally.';
    case 'eRecording':
      return 'Fee associated with electronically recording your property’s title documents.';
    case 'environmentalEndorsement':
      return 'Title insurance coverage beyond your policy to include any environmental protection liens filed in the public records which have priority over the lien of the insured mortgage.';
    case 'escrowWaiver':
      return 'Fee charged by the lender to allow the borrower to pay taxes and insurance independent of their mortgage payment.';
    case 'expressMail':
      return 'Fee to compensate relevant parties for expedited/tracked mail.';
    case 'firstTimeHomebuyerCredit':
      return 'Credit given by the lender for transactions where all borrowers are first time home buyers.';
    case 'floodDetermination':
      return 'Fee to determine if your property is in a flood zone.';
    case 'fraudReview':
      return 'The cost to check for aliases, conduct social security verification, and verify other owned properties and past foreclosures.';
    case 'governmentRecording':
      return 'Fee associated with the recording of your transaction in relevant government records.';
    case 'hazardTracking':
      return 'Fee to a third party to monitor that you have continuous hazard insurance coverage.';
    case 'homeEquityEndorsement':
      return 'Title insurance coverage beyond your policy that your state requires on any home equity loan.';
    case 'homeOwnershipCounseling':
      return 'Fee for a required home ownership course.';
    case 'insuranceEscrow':
      return "Lenders typically hold a 2-3 month balance of homeowner's insurance.";
    case 'intangibleTax':
      return 'Nonrecurring tax on your mortgage only imposed in certain US states.';
    case 'lenderCredit':
      return 'Money paid back to you at closing in return for a higher interest rate.';
    case 'lendersTitle':
      return 'Insurance that protects your lender against problems associated with the title to your property such as someone with a legal claim against the home.';
    case 'landBank':
      return 'Fee paid to the local municipality upon the purchase of real estate to help preserve the area.';
    case 'mersFee':
      return 'The cost to register your loan in the Mortgage Electronic Registration System ("MERS").';
    case 'mineralDevelopmentEndorsement':
      return 'Title insurance coverage beyond your policy to include violations of restrictions, encroachments over easements, building lines or property lines, and damage to existing and future improvements by reason of mineral development.';
    case 'mortgageTax':
      return 'Nonrecurring tax on your mortgage.';
    case 'municipalLienAssessment':
      return 'Cost to complete assess taxes owed on a property.';
    case 'notaryFee':
      return 'The cost to process and notarize documents for your closing.';
    case 'occupancyInspection':
      return "Fee charged by the lender to validate the purpose of your home, typically whether it's a primary residence or not.";
    case 'otherClosing':
      return 'Other costs associated with closing.';
    case 'otherProcessing':
      return 'Other costs associated with processing your loan.';
    case 'ownersTitle':
      return 'Optional insurance that protects you against problems associated with the title to your property such as someone with a legal claim against the home.';
    case 'points':
      return 'Money paid by you at closing in return for a lower interest rate.';
    case 'prepaidInsurance':
      return "Lenders require borrowers to prepay the first 12 months of homeowner's insurance.";
    case 'prepaidInterest':
      return 'Buyers are required to prepay interest for the time between their close date and the end of the month.';
    case 'prepaidTaxes':
      return 'Lenders often require borrowers to prepay the first 2-4 months of property taxes.';
    case 'processing':
      return 'Fee charged by the lender to process, underwrite, and close your loan.';
    case 'pudEndorsement':
      return 'Title insurance coverage beyond your policy to include the common areas of your homeowners association.';
    case 'reappraisal':
      return 'Some purchases require a follow up visit from the appraiser for items noted during their first visit.';
    case 'residentialEndorsement':
      return 'Title insurance coverage beyond your policy for properties purchased for residential use.';
    case 'sb2':
      return 'Tax charged by the state of California for each document recorded on real estate transactions.';
    case 'settlementAgent':
      return 'Cost of a third party that helps facilitate the transaction and make sure the mortgage is recorded properly.';
    case 'subordinationFee':
      return 'Fee typically charged by the servicer of your HELOC or 2nd mortgage to approve your new loan.';
    case 'surveyCoverageEndorsement':
      return 'Title insurance coverage beyond your policy to include items surfaced on a land survey.';
    case 'taxCertificate':
      return 'Third party opinion to certify that all taxes have been paid on the property.';
    case 'taxDeletion':
      return "Title endorsement that revises the lender's title policy to cover previously owed taxes.";
    case 'taxesEscrow':
      return 'Lenders typically hold 2-3 months of property taxes in escrow.';
    case 'taxesNotYetDue':
      return 'Title insurance coverage beyond your policy to protect against taxes for the current year that are not yet due and payable.';
    case 'taxService':
      return 'Fee to alert the lender of any delinquent property taxes.';
    case 'titleCommitmentBinder':
      return 'Fee charged by the title company to document that the property title is free and clear of defects.';
    case 'titleEnforcementFund':
      return 'Fee charged by the state to provide supplemental funding for department operations that are related to title insurance.';
    case 'titleGuaranty':
      return 'Fee title companies are required to collect and remit to the state.';
    case 'titleSearch':
      return 'Fee associated with a close examination of all public records that affect the title to the real estate you are purchasing.';
    case 'titleSurvey':
      return 'Cost of a survey conducted by a professional land surveyor to be used by title insurance companies for insuring the title to your property.';
    case 'transferTax':
      return 'Tax assessed by a municipality on the transfer of property title from one party to another.';
    case 'trustReview':
      return 'Fee charged by the lender to review relevant trust documents.';
    case 'variableRateEndorsement':
      return 'Title insurance coverage beyond your policy that your state requires for all variable rate mortgages.';
    case 'violatedRestrictionsEndorsement':
      return 'Title insurance coverage beyond your policy to protect against any violated restrictions on your new home.';
    case 'wireTransfer':
      return 'Fee associated with sending a wire transfer payment.';
    default:
      return '';
  }
};

const appendModifiers = (
  description: string,
  modifiers: string[],
  value: FeeValue,
  isLicensedState: boolean
) => {
  let finalDescription = description;
  if (modifiers.includes('payer')) {
    finalDescription =
      finalDescription + ' You can negotiate with your Seller to determine who pays this fee.';
  }
  if (modifiers.includes('pages')) {
    finalDescription =
      finalDescription +
      ' The actual cost of this fee depends on the number of pages required for your transaction, therefore this is an estimate.';
  }
  if (modifiers.includes('variableTax')) {
    finalDescription =
      finalDescription + ' This tax varies by municipality, therefore this is an estimate.';
  }
  if (!isLicensedState && value === 'Unknown') {
    finalDescription +=
      " Since Own Up is not licensed to provide quotes in your state, we can't provide an accurate estimate for this fee. However, this fee will be the same across lenders.";
  }
  return finalDescription;
};

export const getFeeDescription = (
  { type, modifiers, value }: Pick<Fee, 'type' | 'modifiers' | 'value'>,
  isLicensedState: boolean
) => appendModifiers(getBaseFeeDescription(type), modifiers, value, isLicensedState);
