import { createSelector } from 'reselect';
import { getLenderHighlights } from '../../../modules/get-lender-highlights';
import {
  isSuccessLenderReview,
  LenderReview,
  LenderReviewLoadingStatus
} from '../../../modules/lenders/type';
import { allLendersSelector } from '../../../redux/lenders/selector';
import { Offer } from '../state';
import { offerSelector, rateQuoteSelector } from './rate-quote';
import { propertyStateSelector } from './scenario';

interface Review {
  rating: LenderReview['rating'];
  totalReviews: LenderReview['totalReviews'];
  profileURL: LenderReview['profileURL'];
}

interface ReviewLoadingStatus {
  loadingStatus: LenderReviewLoadingStatus['loadingStatus'];
}

export type LenderBioReview = Review | ReviewLoadingStatus;

export const isCompleteLenderBioReview = (
  lenderBioReview: LenderBioReview
): lenderBioReview is Review => (lenderBioReview as Review).rating !== undefined;

export interface LenderBio {
  lenderReview?: LenderBioReview;
  highlights: string[];
}

export interface LenderData {
  lenderInfo: { logo: string; lenderName: string; nmls: string };
  lenderBio: LenderBio;
}

export const lenderDataSelector = createSelector(
  offerSelector,
  propertyStateSelector,
  allLendersSelector,
  (optionalOffer, state, allLenders): LenderData =>
    optionalOffer
      .map(({ lenderName, nmls, lender: offerLender, squareLogo }) => {
        let lenderReview: LenderBioReview | undefined;
        if (offerLender && offerLender.lenderId) {
          const lender = allLenders[offerLender.lenderId];
          if (lender === undefined) {
            lenderReview = {
              loadingStatus: 'pending'
            };
          } else if (isSuccessLenderReview(lender.lenderReview)) {
            lenderReview = {
              rating: lender.lenderReview.rating,
              totalReviews: lender.lenderReview.totalReviews,
              profileURL: lender.lenderReview.profileURL
            };
          } else {
            lenderReview = {
              loadingStatus: lender.lenderReview.loadingStatus
            };
          }
        }
        return {
          lenderInfo: {
            logo: squareLogo,
            lenderName,
            nmls
          },
          lenderBio: {
            ...(lenderReview && { lenderReview }),
            highlights: !!offerLender
              ? getLenderHighlights({ ...offerLender, propertyState: state.value })
              : []
          }
        };
      })
      .orElse(() => ({
        lenderInfo: { logo: '', lenderName: '', nmls: '' },
        lenderBio: {
          highlights: []
        }
      }))
);

export const lenderIdsSelector = createSelector(rateQuoteSelector, (rq) =>
  rq
    .map(({ offerPermutations }) =>
      Array.from(
        offerPermutations.reduce((rqLenderIds, { offers }) => {
          const permutationLenderIds = offers.reduce((acc: string[], { lender }: Offer) => {
            if (lender && lender.lenderId) {
              acc.push(lender.lenderId);
            }
            return acc;
          }, [] as string[]);

          permutationLenderIds.forEach((lenderId) => rqLenderIds.add(lenderId));
          return rqLenderIds;
        }, new Set<string>())
      )
    )
    .orElse(() => [])
);
