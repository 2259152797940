interface LoggerProps {
  name: string;
  debug: boolean;
}

export const loggerProvider =
  ({ name, debug }: LoggerProps) =>
  (data: string) => {
    if (debug) {
      console.log(name);
      console.log(data);
    }
  };

export type Logger = ReturnType<typeof loggerProvider>;
