import {
  array as arrayConverter,
  Converter,
  ConverterFunction,
  unknown as unknownConverter
} from 'type-shift';

const unknownArray = arrayConverter(unknownConverter);

export const array = <T>(converter: ConverterFunction<T>): Converter<T[]> =>
  unknownArray.pipe((input: unknown[], path, entity) => {
    const arr: T[] = [];
    input.forEach((i, p) => {
      try {
        arr.push(converter(i, [...path, p], entity));
      } catch (err) {
        console.error(err);
      }
    });
    return arr;
  });
