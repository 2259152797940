import { currency } from '@rategravity/frontend/modules/numbers';
import {
  BERRY_100,
  MOSS_100,
  OwnUpDetail,
  OwnUpExtraSmallHeadlineBook,
  SLATE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { loanAmountSelector } from '../../redux/selectors';

const LoanAmountWrapper = styled('div')`
  background: ${MOSS_100};
  border-radius: 8px;
  padding: 20px;
`;

const ErrorWrapper = styled(OwnUpDetail)`
  padding-top: 12px;
  color: ${BERRY_100};
`;

const HeaderWrapper = styled(OwnUpDetail)`
  padding-bottom: 8px;
  color: ${SLATE_100};
`;

export const LoanAmount = () => {
  const { loanAmount, error } = useSelector(loanAmountSelector);
  return (
    <>
      <LoanAmountWrapper>
        <HeaderWrapper>Loan amount</HeaderWrapper>
        <OwnUpExtraSmallHeadlineBook variant="h2">
          {currency(loanAmount, 0)}
        </OwnUpExtraSmallHeadlineBook>
      </LoanAmountWrapper>
      {!!error && <ErrorWrapper>{error}</ErrorWrapper>}
    </>
  );
};
