import { Actions } from '@rategravity/frontend/modules/user-actions';
import { createContext } from 'react';
import { Subject } from 'rxjs';

const context = createContext<Subject<Actions>>(new Subject());

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ActionPipe = context.Provider;

export { context };
