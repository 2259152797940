/**
 * Functions had to be extracted from the analytics library because we needed
 * the functionality but it was not exported.
 *
 * Taken from: https://github.com/DavidWells/analytics/blob/master/packages/analytics-core/src/modules/page.js#L54
 */

const hashRegex = /#.*$/;

const canonicalUrl = (): string | null => {
  const tags = document.getElementsByTagName('link');
  for (let i = 0, tag; (tag = tags[i]); i++) {
    if (tag.getAttribute('rel') === 'canonical') {
      return tag.getAttribute('href');
    }
  }
  return null;
};

const urlPath = (url: string): string => {
  const regex = /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/g;
  const matches = regex.exec(url);
  const pathMatch = matches && matches[3] ? matches[3].split('?')[0].replace(hashRegex, '') : '';
  return '/' + pathMatch;
};

const currentUrl = (search: string): string => {
  const canonical = canonicalUrl();
  if (!canonical) {
    return window.location.href.replace(hashRegex, '');
  }
  return canonical.match(/\?/) ? canonical : canonical + search;
};

export interface PageData extends Record<string, unknown> {
  hash: string;
  height: number;
  path: string;
  search: string;
  title: string;
  url: string;
  width: number;
  referrer?: string;
}

export const getPageData = (): PageData => {
  const { title, referrer } = document;
  const { location, innerWidth, innerHeight } = window;
  const { hash, search } = location;
  const url = currentUrl(search);
  const page: PageData = {
    title: title,
    url: url,
    path: urlPath(url),
    hash: hash,
    search: search,
    width: innerWidth,
    height: innerHeight
  };
  if (referrer && referrer !== '') {
    page.referrer = referrer;
  }
  return page;
};

export interface Context {
  page: PageData;
  userAgent: string;
}

export const getContext = (): Context => ({
  page: getPageData(),
  userAgent: window.navigator.userAgent
});
