export interface SliderConstraintsState {
  sliderMin: number | null;
  sliderMax: number | null;
  sliderGradient: {
    maxAffordable: number | null;
    maxStretch: number | null;
  };
  sliderValue: number | null;
}

export const DEFAULT_SLIDER_CONSTRAINTS_STATE = {
  sliderMin: null,
  sliderMax: null,
  sliderGradient: {
    maxAffordable: null,
    maxStretch: null
  },
  sliderValue: null
};
