import { parseName } from '@rategravity/core-lib/parse-name';
import { currency } from '@rategravity/frontend/modules/numbers';
import { createSelector } from 'reselect';
import { camelCaseToSentence } from '../../../modules/format-words';
import { rateQuoteSelector } from './rate-quote';

const cfpbDisclaimer =
  "Own Up encourages everyone to shop for their mortgage. To learn more about the benefits of shopping, check out the CFPB's Know Before You Owe Initiative.";

const LICENSED_STATES = [
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'FL',
  'GA',
  'IL',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'NC',
  'NH',
  'OH',
  'PA',
  'RI',
  'SC',
  'TN',
  'TX',
  'WI'
];

export const customDisclaimersSelector = createSelector(rateQuoteSelector, (rates): string[] =>
  rates
    .filter(
      ({ propertyState, isLicensedState }) =>
        !isLicensedState && !!propertyState && !LICENSED_STATES.includes(propertyState)
    )
    .map(() => [
      'Disclaimer: The rates are provided for informational and educational purposes only. Own Up is not licensed in your state and cannot assist you with your transaction. Please contact lenders directly to provide a firm rate quote. As always, please note that rates shown are subject to market fluctuations and are not meant to be considered a loan estimate. When you submit a mortgage application with a third party lender, a loan estimate will be sent to you at that time.',
      cfpbDisclaimer
    ])
    .orElse(() => [
      'Rates are subject to market fluctuations.',
      'This is not a Loan Estimate. If you submit an application with one of our third party lenders, a Loan Estimate will be sent with the initial disclosures.',
      cfpbDisclaimer
    ])
);

export const advisorSelector = createSelector(rateQuoteSelector, (rates) =>
  rates.map(({ advisor }) => advisor)
);

export const propertyStateSelector = createSelector(rateQuoteSelector, (rates) =>
  rates.map((rateQuote) => rateQuote.propertyState)
);

export const noteSelector = createSelector(rateQuoteSelector, (rates) =>
  rates.map(
    ({ primaryBorrower }) =>
      `Hi ${
        parseName(primaryBorrower).first
      }! Here are today's best rates from our lenders based on what you told us.`
  )
);

const formatDownValue = (value: number | string, format?: string) => {
  if (format === 'currency' && typeof value === 'number') {
    return currency(value, 0);
  } else if (typeof value === 'number') {
    return value.toString();
  } else {
    return camelCaseToSentence(value);
  }
};

export const scenarioSelector = createSelector(rateQuoteSelector, (rates) =>
  rates.map(
    ({ address, purchasePrice, existingLoanSize, propertyType, consistentPermutationFields }) => {
      const down = consistentPermutationFields.find((v) => v.title === 'Down payment');
      return [
        {
          title: 'Address',
          value: address?.street || ''
        },
        {
          title: 'Purchase price',
          value: !!purchasePrice && purchasePrice > 0 ? currency(purchasePrice, 0) : ''
        },
        ...(!!down
          ? [
              {
                title: down.title,
                value: formatDownValue(down.value, down.format)
              }
            ]
          : []),
        {
          title: 'Loan amount',
          value: !!existingLoanSize && existingLoanSize > 0 ? currency(existingLoanSize, 0) : ''
        },
        {
          title: 'Property type',
          value: camelCaseToSentence(propertyType)
        },
        ...consistentPermutationFields
          .filter((v) => v.title !== 'Down payment')
          .map(({ title, value, format }) => ({
            title,
            value: formatDownValue(value, format)
          }))
      ].filter(({ value }) => value !== '');
    }
  )
);

export const updatedTimeSelector = createSelector(rateQuoteSelector, (rates) =>
  rates.map(({ updatedTime }) => new Date(updatedTime))
);
