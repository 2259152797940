import { of, Optional } from '@rategravity/core-lib/optional';
import { Advisor, Advisors } from '@rategravity/own-up-component-library-legacy';
import { createSelector } from 'reselect';
import { optionalManifestDataSelector } from '../manifest/selector';
import { ApplicationState } from '../state';

/**
 * Given a lookup table of advisors finds the entry with the given email
 * @param advisorEmail email for the advisor
 * @param advisors lookup table for advisors.
 */
export const getAdvisorIdByEmail = (
  advisorEmail: string,
  advisors: Advisors
): Optional<Advisor & { id: string }> => {
  return of(
    Object.entries(advisors).find(
      ([_, { email, alt_email: altEmail }]) => email === advisorEmail || altEmail === advisorEmail
    )
  ).map(([id, data]) => ({ ...data, id }));
};

/**
 * Given a lookup table of advisors find the entry using
 * the email of the advisor from the manifest
 */
export const advisorByEmailSelector = createSelector(
  optionalManifestDataSelector,
  (_: ApplicationState, advisors: Advisors) => advisors,
  (manifest, advisors): Optional<Advisor & { id: string }> =>
    manifest
      .flatMap(({ advisorEmail }) => of(advisorEmail))
      .flatMap((advisorEmail) => getAdvisorIdByEmail(advisorEmail, advisors))
);
